import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import { Form } from "react-router-dom";

function ConfirmationDialog({
  open,
  onClose,
  onConfirm,
  message,
  userId,
  buttonType,
}) {
  return (
    <Dialog
      BackdropProps={{ style: { backgroundColor: "#00000045" } }}
      open={open}
      onClose={onClose}
      sx={{
        "& .MuiBackdrop-root": {
          backgroundColor: "#00000045",
        },
      }}
    >
      <DialogTitle>Confirm Action</DialogTitle>
      <DialogContent>{message}</DialogContent>
      <DialogActions>
        <Button
          color="grey"
          sx={{
            fontSize: "16px",
            display: "flex",
            fontWeight: "600",
            padding: "12px 20px",
            lineHeight: "24px",
            ":hover": {
              bgcolor: "grey.grey600",
              color: "white",
            },
          }}
          onClick={onClose}
        >
          Cancel
        </Button>
        <Form method="POST" replace>
          <input type="hidden" name="id" value={userId} />
          <Button
            type={buttonType}
            onClick={onConfirm}
            sx={[
              {
                maxWidth: "fit-content",
                mx: { md: 1 },
                color: "white",
                bgcolor: "primary.primary600",
                fontSize: "16px",
                display: "flex",
                fontWeight: "600",
                padding: "12px 20px",
                lineHeight: "24px",
              },
              {
                "&:hover": {
                  bgcolor: "primary.primary700",
                },
              },
            ]}
          >
            Confirm
          </Button>
        </Form>
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmationDialog;
