import { createContext, useState } from "react";
import useApi from "../hooks/use-api";

const SidebarContext = createContext({
  isOpen: true,
  currentSidebarMenuId: 1,
  sidebarItems: [],
  handleDrawer: () => {},
  setIsOpen: () => {},
  setCurrentSidebarMenuId: () => {},
  sidebarMenus: (matchesPath) => {},
  handleActiveSidebar: (id) => {},
});

export const SidebarContextProvider = (props) => {
  const [isOpen, setIsOpen] = useState(true);
  const [sidebarItems, setSidebarItems] = useState([]);
  const [currentSidebarMenuId, setCurrentSidebarMenuId] = useState(1);

  // ? ***************************************************** Handle active side bar link ***************************************************** */
  const handleActiveSidebar = (id) => {
    sidebarItems.map((sidebarItem) =>
      sidebarItem.id === id
        ? (sidebarItem.isActive = true)
        : (sidebarItem.isActive = false)
    );
  };

  // ? **************************************************** Get all the sidebar items ****************************************************** */
  const sidebarMenus = async (matchesPath) => {
    try {
      const result = await useApi.getRequest("ids", "sidebar-menus", true);
      if (result.status) {
        const activeSidebar = result.data.filter((o1) => {
          return matchesPath.some((o2) => o2.pathname === o1.link);
        });
        const menus = result.data.map((menu) => ({
          id: menu.id,
          name: menu.name,
          icon: menu.icon,
          link: menu.link,
          isActive: activeSidebar[activeSidebar.length - 1].link === menu.link,
        }));
        return setSidebarItems(menus);
      }

      throw new Error(result.message);
    } catch (error) {
      console.log(error.message);
    }
  };

  // ? ****************************************************** Handle drawer state ****************************************************** */
  const handleDrawer = () => {
    setIsOpen(!isOpen);
  };

  // ? ****************************************************** Context Value ****************************************************** */
  const contextValue = {
    isOpen: isOpen,
    currentSidebarMenuId: currentSidebarMenuId,
    sidebarItems: sidebarItems,
    handleDrawer: handleDrawer,
    setIsOpen: setIsOpen,
    setCurrentSidebarMenuId: setCurrentSidebarMenuId,
    sidebarMenus: sidebarMenus,
    handleActiveSidebar: handleActiveSidebar,
  };

  return (
    <SidebarContext.Provider value={contextValue}>
      {props.children}
    </SidebarContext.Provider>
  );
};

export default SidebarContext;
