import * as React from "react";
import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
} from "@mui/x-data-grid";
import Columns from "../../store/Columns";
import { Stack } from "@mui/material";
import "../../assets/css/style.css";
import Toast from "../../utils/Toast";
import useApi from "../../hooks/use-api";
import { useParams } from "react-router-dom";

export default function DataTable(props) {
  const params = useParams();

  const [isAccessed, setIsAccessed] = React.useState(
    params.type === "contacts"
      ? props.rows.filter((row) => row.is_open === "TRUE").map((row) => row.id)
      : props.rows.map((row) => row.id)
  );

  const getRowClassName = (params) => {
    if (isAccessed.includes(params.row.id)) {
      return "";
    }
    return "my-row-class";
  };

  const onCellClickHandler = async (cellData) => {
    try {
      if (
        cellData.field === "stage" ||
        cellData.field === "full_name" ||
        cellData.field === "referral_number"
      ) {
        if (params.type === "contacts" && cellData.row.is_open === "FALSE") {
          const result = await useApi.updateRequest(
            "ids",
            `contacts/${cellData.id}`,
            true,
            { is_open: "TRUE" },
            "REACT_APP_CONTACTS_API_KEY"
          );
          if (!result.status) {
            throw new Error(result.message);
          }
        }
        setIsAccessed(isAccessed.concat(cellData.id));
        props.setIsModal(true);
        return props.setModalData(cellData);
      }
    } catch (error) {
      Toast.fire({ icon: "error", title: error.message });
    }
  };

  function CustomToolbar() {
    const exportOptions = {
      sx: {
        color: "white",
        fontWeight: "bold",
        py: 0.7,
        px: 1,
        my: 0.5,
        bgcolor: "#0086C9",
        borderRadius: 4,
        borderColor: "#E0E0E0",
        border: "1px solid",
        ":hover": { color: "#0086C9", bgcolor: "#E0F2FE" },
      },
    };
    const otherOptions = {
      sx: {
        color: "#475467",
        fontWeight: "bold",
        py: 0.7,
        px: 1,
        my: 0.5,
        mx: 0.5,
        bgcolor: "#F9FAFB",
        borderRadius: 1,

        ":hover": { color: "#344054", bgcolor: "#EAECF0" },
      },
    };

    return (
      <GridToolbarContainer
        sx={{
          borderBottom: 1,
          borderColor: "#E0E0E0",
          textAlign: "end",
          display: "flex",
          mx: 1,

          justifyContent: "space-between",
          color: "black",
        }}
      >
        <Stack display={"flex"} flexDirection={"row"}>
          <GridToolbarColumnsButton {...otherOptions} />
          <GridToolbarDensitySelector {...otherOptions} />
        </Stack>
        <GridToolbarExport {...exportOptions} />
      </GridToolbarContainer>
    );
  }

  return (
    <div style={{ width: "100%" }}>
      <DataGrid
        autoHeight
        slots={{
          toolbar: CustomToolbar,
        }}
        rows={props.rows}
        sx={{
          cursor: "pointer",
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: "#F2F4F7",
            color: "black",
          },
        }}
        columns={[...Columns[props.column.replace("-", "_")]]}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 15 },
          },
        }}
        className="css-u1bn0g-MuiDataGrid-root MuiDataGrid-cell MuiDataGrid-menuIcon"
        pageSizeOptions={[5, 15]}
        onCellClick={(params) => onCellClickHandler(params)}
        getRowClassName={getRowClassName}
        checkboxSelection
        disableRowSelectionOnClick
      />
    </div>
  );
}

DataTable.defaultProps = {
  otherColumns: [],
};
