import React from "react";
import CaseStudyForm from "../../components/case-studies/CaseStudyForm";
import { useLoaderData } from "react-router-dom";
import useApi from "../../hooks/use-api";

const EditCaseStudy = () => {
  const { caseStudy } = useLoaderData();

  return <CaseStudyForm caseStudy={caseStudy} isEdit />;
};

export default EditCaseStudy;

export const action = async ({ request, params }) => {
  try {
    const id = params.id;
    const data = await request.formData();
    const formData = {
      title: data.get("title"),
      subtitle: data.get("subtitle"),
      about: data.get("about"),
      description: data.get("description"),
      challenges: data.get("challenges"),
      solutions: data.get("solutions"),
      value_creation: data.get("value_creation"),
      position: data.get("position"),
      sub_service_id: data.get("sub_service_id"),
    };

    const result = await useApi.updateRequest(
      "ehs",
      `case-studies/${id}`,
      true,
      formData
    );

    return result;
  } catch (error) {
    console.log(error.message);
  }
};
