const csrRegistrationsCards = [
  {
    title: "Blood Donations",
    link: "blood-donations",
    icon: "PersonAdd",
    helperText: "Some Helper text here. Lorem Ipsem is best. The best I say.",
  },

  {
    title: "Volunteers",
    link: "volunteers",
    icon: "Group",
    helperText: "Some Helper text here. Lorem Ipsem is best. The best I say.",
  },
];

export default csrRegistrationsCards;
