const cards = [
  {
    title: "Web to Lead",
    link: "leads",
    icon: "Group",
    helperText: "Some Helper text here. Lorem Ipsem is best. The best I say.",
  },
  {
    title: "Exit Intent",
    link: "exit-intent",
    icon: "Group",
    helperText: "Some Helper text here. Lorem Ipsem is best. The best I say.",
  },
];

export default cards;
