const teamManageData = [
  {
    title: "Create User",
    link: "create-user",
    icon: "PersonAdd",
    helperText: "Some Helper text here. Lorem Ipsem is best. The best I say.",
  },

  {
    title: "View Users",
    link: "users",
    icon: "Group",
    helperText: "Some Helper text here. Lorem Ipsem is best. The best I say.",
  },
];

export default teamManageData;
