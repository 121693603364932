import { Grid, Stack, Typography } from "@mui/material";
import React from "react";
import { Link, defer } from "react-router-dom";
import teamManageData from "../../store/teamManageData";
import Dashboard from "../../components/cards/Dashboard";
import useApi from "../../hooks/use-api";

function TeamManagement() {
  const user = JSON.parse(localStorage.getItem("user"));

  return (
    <>
      <Stack my={2}>
        <Typography sx={{ typography: "displaySmSemi" }}>
          Welcome, {user?.first_name}
        </Typography>
        <Typography color={"grey.grey600"}>
          Seamlessly track and manage all the data in one place.
        </Typography>
      </Stack>
      <Stack
        display={"flex"}
        // justifyContent={"center"}
        // alignItems={"center"}
        bgcolor={"white"}
      >
        <Stack
          display={"flex"}
          justifyContent={"center"}
          spacing={5}
          alignItems={"center"}
        ></Stack>
        <Stack
          display={"flex"}
          my={10}
          // justifyContent={"center"}
          // alignItems={"center"}
        >
          <Grid
            maxWidth={"xl"}
            container
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              columnGap: 5,
              rowGap: 5,
            }}
          >
            {teamManageData.map((item, index) => {
              return (
                <Grid item md={5} key={index}>
                  <Link
                    to={`/team-management/${item.link}`}
                    style={{ textDecoration: "none", color: "black" }}
                    key={index}
                  >
                    <Dashboard
                      title={item.title}
                      icon={item.icon}
                      helperText={item.helperText}
                    />
                  </Link>
                </Grid>
              );
            })}
          </Grid>
        </Stack>
      </Stack>
    </>
  );
}

export default TeamManagement;

// ? ***************************************************************** Load roles ***************************************************************** */
const loadRoles = async () => {
  try {
    const result = await useApi.getRequest("ids", "roles", true);
    if (result.status) {
      return result.data;
    }

    throw new Error(result.message);
  } catch (error) {
    console.log(error.message);
  }
};

// ? ***************************************************************** Load Departments ***************************************************************** */
const loadDepartments = async () => {
  try {
    const result = await useApi.getRequest("ids", "departments", true);
    if (result.status) {
      return result.data;
    }

    throw new Error(result.message);
  } catch (error) {
    console.log(error.message);
  }
};

// ? ***************************************************************** Permissions ***************************************************************** */
const loadPermissionsTypes = async () => {
  try {
    const result = await useApi.getRequest("ids", "permission-types", true);
    if (result.status) {
      return result.data;
    }

    throw new Error(result.message);
  } catch (error) {
    console.log(error.message);
  }
};

// ? ************************************************************** Users ************************************************************** */
const loadUsers = async () => {
  try {
    const result = await useApi.getRequest("ids", "users", true);
    if (result.status) {
      return result;
    }

    throw new Error(result.message);
  } catch (error) {
    console.log(error.message);
  }
};

// ? ************************************************************** All loaders data ************************************************************** */
export const loader = () => {
  return defer({
    roles: loadRoles(),
    departments: loadDepartments(),
    permissionTypes: loadPermissionsTypes(),
    users: loadUsers(),
  });
};
