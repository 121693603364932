import React, { Suspense, useContext } from "react";
import useApi from "../../hooks/use-api";
import Toast from "../../utils/Toast";
import Form from "./Form";
import { Stack } from "@mui/material";

import SidebarContext from "../../store/sidebar-context";
import { Await, useRouteLoaderData } from "react-router-dom";
import HeaderLayout from "../../components/layouts/HeaderLayout";

function CreateUser() {
  const sidebarContext = useContext(SidebarContext);
  const { roles, departments, permissionTypes } =
    useRouteLoaderData("team-management");

  return (
    <Stack maxWidth={sidebarContext.isOpen ? "78vw" : "90vw"}>
      <HeaderLayout title="Team Management" type="Create User" />
      <Suspense fallback={<p>Loading...</p>}>
        <Await
          resolve={Promise.all([roles, departments, permissionTypes]).then(
            (values) => values
          )}
        >
          {(values) => {
            const [roles, departments, permissionTypes] = values;
            return (
              <Form
                buttonName="Create User"
                method="POST"
                roles={roles}
                departments={departments}
                permissionTypes={permissionTypes}
              />
            );
          }}
        </Await>
      </Suspense>
    </Stack>
  );
}

export default CreateUser;

// ? ************************************************************** Submit the form ************************************************************** */
export const action = async ({ request }) => {
  try {
    const data = await request.formData();
    const formData = {
      first_name: data.get("first_name"),
      last_name: data.get("last_name"),
      email: data.get("email"),
      role_id: data.get("role_id"),
      department_id: data.get("department_id"),
      password: data.get("password"),
      permission_id: data.get("permission_id"),
    };

    const result = await useApi.postRequest(
      "ids",
      "users",
      true,
      formData,
      "REACT_APP_USERS_API_KEY"
    );
    if (result.status) {
      return result;
    }

    throw new Error(result.message);
  } catch (error) {
    Toast.fire({ icon: "error", title: error.message });
  }
};
