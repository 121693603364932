import { Stack, Typography } from "@mui/material";
import { convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
// import { convertToRaw } from "draft-js";
// import draftToHtml from "draftjs-to-html";
import React from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const toolbarOptions = {
  options: [
    "inline",
    "blockType",
    "fontSize",
    "fontFamily",
    "list",
    "textAlign",
    // "colorPicker",
    // "link",
    // "embedded",
    // "emoji",
    // "remove",
    "image",
    "history",
  ],
  inline: {
    options: [
      "bold",
      "italic",
      "underline",
      "strikethrough",
      "monospace",
      "superscript",
      "subscript",
    ],
  },
  blockType: {
    options: ["Normal", "H1", "H2", "Blockquote", "Code"],
  },
  fontSize: {
    options: [14, 16, 24, 28, 36],
    className: "DraftEditor-root",
  },
  fontFamily: {
    options: ["Inter"],
  },
  list: {
    options: ["unordered", "ordered"],
  },
  textAlign: {
    // options: ["left", "center", "right", "justify"],
    options: ["left", "right"],
  },
};

const TextEditor = (props) => {
  // ? ************************************************* On focus editor ************************************************* */
  const onFocusEditorHandler = () => {
    props.setEditorState((prevState) => ({
      ...prevState,
      [props.htmlTextName]: { ...prevState[props.htmlTextName], focus: true },
    }));
  };
  // ? ************************************************* On Blur editor ************************************************* */
  const onBlurEditorHandler = () => {
    if (props.error) {
      return props.setEditorState((prevState) => ({
        ...prevState,
        [props.htmlTextName]: {
          ...prevState[props.htmlTextName],
          focus: "error",
        },
      }));
    }

    props.setEditorState((prevState) => ({
      ...prevState,
      [props.htmlTextName]: {
        ...prevState[props.htmlTextName],
        focus: false,
      },
    }));
  };
  // ? ************************************************* On change editor state ************************************************* */
  const onEditorStateChange = (editorState) => {
    props.setEditorState((prevState) => ({
      ...prevState,
      [props.htmlTextName]: {
        ...prevState[props.htmlTextName],
        editorState: editorState,
      },
    }));
    const rawText = convertToRaw(
      props.editorState[props.htmlTextName].editorState.getCurrentContent()
    );
    const htmlText = draftToHtml(
      convertToRaw(
        props.editorState[props.htmlTextName].editorState.getCurrentContent()
      )
    );

    props.setFieldValue(props.rawTextName, rawText.blocks[0].text);
    props.setFieldValue(props.htmlTextName, htmlText);
  };

  return (
    <Stack maxWidth={"800px"}>
      <div
        className={
          props.editorState[props.htmlTextName].focus === true
            ? "text-editor-wrapper"
            : props.editorState[props.htmlTextName].focus === "error" ||
              (props.error && props.touched)
            ? "text-editor-wrapper-error"
            : ""
        }
        tabIndex="1"
        style={{
          maxWidth: "1024px",
          minHeight: "300px",
          padding: "6px",
          borderRadius: "5px",
          border: "1px solid #C4C4C4",
          ":focus": { border: "2px solid #0086C9" },
        }}
      >
        <Editor
          editorClassName={"inter-font"}
          wrapperClassName={"text-editor-css"}
          stripPastedStyles={true}
          onFocus={onFocusEditorHandler}
          onBlur={onBlurEditorHandler}
          editorState={props.editorState[props.htmlTextName].editorState}
          name={props.rawTextName}
          toolbar={toolbarOptions}
          onEditorStateChange={onEditorStateChange}
        />
      </div>
      {(props.editorState[props.htmlTextName].focus === "error" ||
        (props.error && props.touched)) && (
        <Typography
          sx={{
            fontFamily: "Inter",
            fontStyle: "normal",
            fontWeight: 400,
            mt: "3px",
            mx: "14px",
            fontSize: "14px",
            lineHeight: "20px",
          }}
          color="#d32f2f"
        >
          {props.error}
        </Typography>
      )}
    </Stack>
  );
};

export default TextEditor;
