import React, { Suspense, useContext } from "react";
import { Await, useRouteLoaderData } from "react-router-dom";
import { Divider, Stack } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import Columns from "../../store/Columns";
import SidebarContext from "../../store/sidebar-context";
import HeaderLayout from "../../components/layouts/HeaderLayout";

const List = () => {
  const { openings } = useRouteLoaderData("list-loader");
  const sidebarContext = useContext(SidebarContext);

  return (
    <Stack maxWidth={sidebarContext.isOpen ? "78vw" : "90vw"}>
      <HeaderLayout title="Post a Job" type="Job List" />
      <Divider sx={{ my: 4 }} />
      <Suspense fallback={<p>Loading...</p>}>
        <Await resolve={openings}>
          {(loadedOpenings) => (
            <Stack my={5} mx={2}>
              <div style={{ width: "100%", height: 400, overflowX: "auto" }}>
                <DataGrid
                  autoHeight
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: 5,
                      },
                    },
                  }}
                  pageSizeOptions={[5]}
                  checkboxSelection
                  disableRowSelectionOnClick
                  rows={loadedOpenings}
                  columns={Columns["jobList"]}
                  className="css-u1bn0g-MuiDataGrid-root MuiDataGrid-cell MuiDataGrid-menuIcon"
                  sx={{
                    cursor: "pointer",
                    "& .MuiDataGrid-columnHeaders": {
                      backgroundColor: "#F2F4F7",
                      color: "black",
                    },
                  }}
                />
              </div>
            </Stack>
          )}
        </Await>
      </Suspense>
    </Stack>
  );
};

export default List;
