import { Divider, Stack } from "@mui/material";
import React, { Suspense, useContext } from "react";
import { Await, useLoaderData } from "react-router-dom";
import SidebarContext from "../../store/sidebar-context";
import HeaderLayout from "../../components/layouts/HeaderLayout";
import { DataGrid } from "@mui/x-data-grid";
import Columns from "../../store/Columns";

const CertificateTemplateList = () => {
  const sidebarContext = useContext(SidebarContext);
  const { certificateTemplates } = useLoaderData();

  return (
    <Stack maxWidth={sidebarContext.isOpen ? "78vw" : "90vw"}>
      <HeaderLayout title="Certificates" type="Certificate Template List" />
      <Divider sx={{ my: 4 }} />
      <Suspense fallback={<p>Loading...</p>}>
        <Await resolve={certificateTemplates}>
          {(loadedCertificateTemplates) => (
            <Stack my={5} mx={2}>
              <div style={{ width: "100%", height: 400, overflowX: "auto" }}>
                <DataGrid
                  autoHeight
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: 5,
                      },
                    },
                  }}
                  pageSizeOptions={[5]}
                  checkboxSelection
                  disableRowSelectionOnClick
                  rows={loadedCertificateTemplates}
                  columns={Columns["certificateTemplates"]}
                  className="css-u1bn0g-MuiDataGrid-root MuiDataGrid-cell MuiDataGrid-menuIcon"
                  sx={{
                    cursor: "pointer",
                    "& .MuiDataGrid-columnHeaders": {
                      backgroundColor: "#F2F4F7",
                      color: "black",
                    },
                  }}
                />
              </div>
            </Stack>
          )}
        </Await>
      </Suspense>
    </Stack>
  );
};

export default CertificateTemplateList;
