import React from "react";
import Button from "../../components/ui/FormButton";
import AuthStyle from "../../assets/css/Auth.module.css";
import { useFormik } from "formik";
import Divider from "@mui/material/Divider";
import { Stack, Typography } from "@mui/material";
import {
  Form,
  Link,
  useActionData,
  useNavigate,
  useSubmit,
} from "react-router-dom";
import TextFiled from "../../components/ui/TextField";
import { ForgetPass } from "../../schema";
import useApi from "../../hooks/use-api";
import Toast from "../../utils/Toast";

const ForgotPassword = () => {
  const submit = useSubmit();
  const actionData = useActionData();
  const navigate = useNavigate();

  const onSubmitApplicationHandler = (event) => {
    event.preventDefault();
    handleSubmit();
  };
  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: {
        email: "",
      },
      validationSchema: ForgetPass,
      onSubmit: async (values) => {
        submit(values, { method: "post" });
      },
    });
  // console.log(actionData);
  if (actionData?.status) {
    Toast.fire({ icon: "success", title: actionData?.message });
    navigate("/auth/verify-otp", { state: { email: values.email } });
  }

  // if (actionData?.status === false) {
  //   Toast.fire({ icon: "error", title: actionData?.message });
  // }
  // useEffect(() => {
  // }, [actionData, navigate, values]);

  // const isFocused = values.email.length > 0 ? true : false;
  return (
    <>
      <Stack className={AuthStyle.form_content}>
        <Stack sx={{ width: { xs: "100%", sm: "400px", md: "380px" } }}>
          <Stack my={2}>
            <Typography typography={"displayMdSemi"}>
              Forgot Password
            </Typography>
            <Stack py={2}>
              <Typography typography={"textMdSemi"}>Almost there!</Typography>
              <Typography typography={"textLgReg"}>
                Enter your account details to begin
              </Typography>
            </Stack>

            <Divider sx={{ mb: "20px" }} />
          </Stack>
          <Form method="post" onSubmit={onSubmitApplicationHandler}>
            <Stack>
              <TextFiled
                isError={touched.email && errors.email ? true : false}
                label="Email"
                id="email"
                type="text"
                name="email"
                className={AuthStyle.default}
                isColor={`${touched.email && !errors.email ? "success" : ""}`}
                // isFocused={isFocused}
                value={values.email}
                handleBlur={handleBlur}
                handleChange={handleChange}
              />
              <Stack py={"0.5rem"}>
                {errors.email && touched.email ? (
                  <Typography sx={{ color: "red", typography: "textXsReg" }}>
                    {errors.email}
                  </Typography>
                ) : null}
              </Stack>
            </Stack>
            <Button title="Submit" BtnVariant="contained" type="submit" />
          </Form>

          <Link to="/auth/login">
            {/* <Typography>Back to login</Typography> */}
            <Button title="Back to login" BtnVariant="text" />
          </Link>
        </Stack>
      </Stack>
    </>
  );
};

export default ForgotPassword;

export const action = async ({ request }) => {
  try {
    const data = await request.formData();
    const formData = {
      email: data.get("email"),
      checkField: data.get("checkField"),
    };
    const result = await useApi.postRequest(
      "ids",
      "auth/forget-password",
      false,
      formData
    );
    if (result.status) {
      return result;
    }

    throw new Error(result.message);
  } catch (error) {
    return Toast.fire({ icon: "error", title: error.message });
  }
};
