import {
  Avatar,
  Divider,
  Skeleton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { Suspense } from "react";
import UploadFile from "../../components/File-upload";
import useApi from "../../hooks/use-api";
import { Await, defer, useLoaderData } from "react-router-dom";

const PersonalInformation = () => {
  const { personalInfo } = useLoaderData();

  return (
    <Suspense fallback={<Skeleton variant="rounded" height={500} />}>
      <Await resolve={personalInfo}>
        {(loadedPersonalInfo) => (
          <>
            <Stack display={"flex"} flexDirection={"row"} alignItems={"center"}>
              <Avatar
                src={`${process.env.REACT_APP_IDS_BACKEND_URL}profile/${loadedPersonalInfo.image}`}
                sx={{
                  width: 150,
                  height: 150,
                  mr: 1,
                }}
              >
                PC
              </Avatar>
              <Stack>
                <Typography sx={{ typography: "displaySmSemi" }}>
                  {loadedPersonalInfo.full_name}
                </Typography>
                <Typography
                  sx={{ typography: "textMdReg", color: "grey.grey600" }}
                >
                  {loadedPersonalInfo.email}
                </Typography>
              </Stack>
            </Stack>
            <Divider sx={{ my: 2 }} />
            <Stack display={"flex"} my={4} flexDirection={"row"}>
              <Stack flex={0.5} mr={4}>
                <Typography sx={{ typography: "textSmSemi" }}>
                  Invite team members
                </Typography>
                <Typography sx={{ typography: "textSmReg" }}>
                  Get your projects up and running faster by inviting your team
                  to collaborate.
                </Typography>
              </Stack>

              <Stack
                flex={1.5}
                sx={{
                  p: 2,
                  borderRadius: 1,
                  border: "1px solid ",
                  borderColor: "grey.grey200",
                  boxShadow:
                    "0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10)",
                }}
                spacing={4}
              >
                <Stack direction={"row"} spacing={2}>
                  <TextField
                    fullWidth
                    size="small"
                    type="test"
                    label="First Name"
                    name="first_name"
                    sx={{
                      width: "100%",
                      ".MuiFormHelperText-root": {
                        typography: "textSmReg",
                        color: "#d3302f",
                      },
                    }}
                    value={loadedPersonalInfo.first_name}
                    placeholder="Name"
                  />
                  <TextField
                    fullWidth
                    size="small"
                    type="test"
                    label="Last Name"
                    name="Last_name"
                    sx={{
                      width: "100%",
                      ".MuiFormHelperText-root": {
                        typography: "textSmReg",
                        color: "#d3302f",
                      },
                    }}
                    value={loadedPersonalInfo.last_name}
                    placeholder="Name"
                  />
                </Stack>
                <TextField
                  fullWidth
                  size="small"
                  type="test"
                  label="Email Address"
                  name="email"
                  sx={{
                    width: "100%",
                    ".MuiFormHelperText-root": {
                      typography: "textSmReg",
                      color: "#d3302f",
                    },
                  }}
                  value={loadedPersonalInfo.email}
                  placeholder="Name"
                />
                <Stack display="flex" direction="row" alignItems="center">
                  <Avatar
                    src={`${process.env.REACT_APP_IDS_BACKEND_URL}images/users/profiles/${loadedPersonalInfo.image}`}
                    sx={{
                      width: 100,
                      height: 100,
                      mx: 1,
                      mr: 2,
                    }}
                  >
                    PC
                  </Avatar>
                  <UploadFile
                    name="profiles"
                    folderPath="images/users/profiles"
                    values={{ profiles: "" }}
                  />
                </Stack>
              </Stack>
            </Stack>
          </>
        )}
      </Await>
    </Suspense>
  );
};

export default PersonalInformation;

// ? ******************************************************** Personal Information loader ******************************************************** */
const personalInfoLoader = async (id) => {
  try {
    const result = await useApi.getRequest("ids", `users/${id}`, true);
    if (result.status) {
      return result.data;
    }

    throw new Error(result.message);
  } catch (error) {
    console.log(error.message);
  }
};

// ? ******************************************************** All the loaders ******************************************************** */
export const loader = () => {
  const id = JSON.parse(localStorage.getItem("user"))?.id;
  return defer({
    personalInfo: personalInfoLoader(id),
  });
};
