import React from "react";
import EventForm from "../../components/events/EventForm";
import useApi from "../../hooks/use-api";

const CreateEvent = () => {
  return <EventForm />;
};

export default CreateEvent;

export const action = async ({ request }) => {
  try {
    const data = await request.formData();
    const formData = {
      event: JSON.parse(data.get("event")),
      speakers: JSON.parse(data.get("speakers")),
      coordinators: JSON.parse(data.get("coordinators")),
    };
    const result = await useApi.postRequest("ids", "events", true, formData);

    return result;
  } catch (error) {
    console.log(error.message);
  }
};
