import React from "react";
import { Grid, Stack, Typography } from "@mui/material";
import events from "../../store/Events";
import { Link } from "react-router-dom";
import Dashboard from "../../components/cards/Dashboard";

const index = () => {
  const user = JSON.parse(localStorage.getItem("user"));

  return (
    <>
      <Stack my={2}>
        <Typography sx={{ typography: "displaySmSemi" }}>
          Welcome, {user?.first_name}
        </Typography>
        <Typography color={"grey.grey600"}>
          Seamlessly track and manage all the data in one place.
        </Typography>
      </Stack>
      <Stack
        display={"flex"}
        // justifyContent={"center"}
        // alignItems={"center"}
        bgcolor={"white"}
      >
        <Stack
          display={"flex"}
          justifyContent={"center"}
          spacing={5}
          alignItems={"center"}
        ></Stack>
        <Stack
          display={"flex"}
          my={10}
          // justifyContent={"center"}
          // alignItems={"center"}
        >
          <Grid
            maxWidth={"xl"}
            container
            // columnSpacing={20}
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              columnGap: 5,
              rowGap: 5,
            }}
          >
            {events.map((event, index) => {
              return (
                <Grid item md={5} key={index}>
                  <Link
                    to={event.link}
                    style={{ textDecoration: "none", color: "black" }}
                    key={index}
                  >
                    <Dashboard
                      title={event.title}
                      icon={event.icon}
                      helperText={event.helperText}
                    />
                  </Link>
                </Grid>
              );
            })}
          </Grid>
        </Stack>
      </Stack>
    </>
  );
};

export default index;
