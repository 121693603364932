import React, { useState } from "react";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

const PasswordField = (props) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  return (
    <>
      <FormControl sx={{ width: "100%" }} variant="outlined">
        <InputLabel
          htmlFor={props.id}
          error={props.isError}
          color={props.isColor}
          focused={props.isFocused}
        >
          {props.label}
        </InputLabel>
        <OutlinedInput
          error={props.isError}
          color={props.isColor}
          focused={props.isFocused}
          name={props.name}
          value={props.value}
          padding={6}
          mb={props.margin}
          onChange={props.handleChange}
          onBlur={props.handleBlur}
          label={props.label}
          id={props.id}
          type={showPassword ? "text" : "password"}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                edge="end"
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
        />
      </FormControl>
    </>
  );
};

export default PasswordField;
