import React from "react";
import CreateCaseStudyForm from "../../../components/ssb/case-studies/CreateCaseStudyForm";
import HeaderLayout from "../../../components/layouts/HeaderLayout";
import Toast from "../../../utils/Toast";
import useApi from "../../../hooks/use-api";

const CreateSSBCaseStudies = () => {
  return (
    <>
      <HeaderLayout title="Case Study" type="Create a Case Study" />
      <CreateCaseStudyForm />
    </>
  );
};

export default CreateSSBCaseStudies;

export const action = async ({ request }) => {
  try {
    const data = await request.formData();
    const formData = {
      title: data.get("title"),
      subtitle: data.get("subtitle"),
      description: data.get("description"),
      app_type: data.get("app_type"),
      tech_stack: data.get("tech_stack"),
      business_problem: data.get("business_problem"),
      solution: data.get("solution"),
      business_impact: data.get("business_impact"),
      link: data.get("link"),
      industry_id: data.get("industry_id"),
      service_id: data.get("service_id"),
      sub_service_id: data.get("sub_service_id"),
    };

    const result = await useApi.postRequest(
      "ssb",
      "case-studies",
      true,
      formData
    );

    if (result.status) {
      return result;
    }

    throw new Error(result.message);
  } catch (error) {
    Toast.fire({ icon: "error", title: error.message });
  }
};
