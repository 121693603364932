const cards = [
  {
    title: "Industries",
    link: "industries",
    icon: "PersonAdd",
    helperText: "Some Helper text here. Lorem Ipsem is best. The best I say.",
  },
  {
    title: "Case Studies",
    link: "case-studies",
    icon: "PersonAdd",
    helperText: "Some Helper text here. Lorem Ipsem is best. The best I say.",
  },

  {
    title: "Services",
    link: "services",
    icon: "Group",
    helperText: "Some Helper text here. Lorem Ipsem is best. The best I say.",
  },
];

export default cards;
