import { Stack, Typography } from "@mui/material";
import {
  ContentState,
  EditorState,
  convertFromHTML,
  convertToRaw,
} from "draft-js";
import React, { useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./style.css";
import draftToHtml from "draftjs-to-html";

const toolbarOptions = {
  options: [
    "inline",
    "blockType",
    "fontSize",
    "fontFamily",
    "list",
    "textAlign",
    "image",
    "history",
  ],
  inline: {
    options: [
      "bold",
      "italic",
      "underline",
      "strikethrough",
      "monospace",
      "superscript",
      "subscript",
    ],
  },
  blockType: {
    options: ["Normal", "H1", "H2", "Blockquote", "Code"],
  },
  fontSize: {
    options: [14, 16, 24, 28, 36],
    className: "DraftEditor-root",
  },
  fontFamily: {
    options: ["Inter"],
  },
  list: {
    options: ["unordered", "ordered"],
  },
  textAlign: {
    options: ["left", "right"],
  },
};

const TextEditor = ({
  name,
  title,
  setFieldValue,
  errors,
  touched,
  setFieldTouched,
  isMandatory = true,
  isEdit = false,
  savedHtml = null,
}) => {
  let INITIAL_VALUES = {
    [name]: {
      editorState: currentEditorState(),
      focus: false,
    },
  };
  const [editorState, setEditorState] = useState(INITIAL_VALUES);

  // ? ************************************************************ current editor state  ************************************************************ */
  function currentEditorState() {
    if (!isEdit) {
      return EditorState.createEmpty();
    }

    const blocksFromHTML = convertFromHTML(savedHtml);
    const state = ContentState.createFromBlockArray(
      blocksFromHTML.contentBlocks,
      blocksFromHTML.entityMap
    );
    return EditorState.createWithContent(state);
  }

  // ? ************************************************************ On Focus Handler  ************************************************************ */
  const focusHandler = () => {
    setEditorState((prevState) => ({
      ...prevState,
      [name]: { ...prevState[name], focus: true },
    }));
  };

  // ? ************************************************************ On Blur Handler  ************************************************************ */
  const blurHandler = () => {
    if (
      !editorState[name].editorState.getCurrentContent().hasText() &&
      isMandatory
    ) {
      setFieldTouched(name, true, true);
      setFieldValue(name, "");
      return setEditorState((prevState) => ({
        ...prevState,
        [name]: { ...prevState[name], focus: "error" },
      }));
    }

    setEditorState((prevState) => ({
      ...prevState,
      [name]: { ...prevState[name], focus: false },
    }));
  };

  // ? ************************************************************ On change Handler  ************************************************************ */
  const handleChange = (editorStateChange) => {
    setEditorState((prevState) => ({
      ...prevState,
      [name]: {
        ...prevState[name],
        editorState: editorStateChange,
      },
    }));

    const htmlData = draftToHtml(
      convertToRaw(editorState[name].editorState.getCurrentContent())
    );

    const valueData =
      !isMandatory &&
      !editorState[name].editorState.getCurrentContent().hasText()
        ? ""
        : htmlData;
    setFieldValue(name, valueData);
  };

  // ? ************************************************************ Render the component ************************************************************ */
  return (
    <Stack width={"100%"}>
      <Typography
        className="title"
        sx={{ typography: "textMdReg", mb: 1, color: "grey.grey600" }}
      >
        {title} {isMandatory && "*"}
      </Typography>
      <div
        className={
          editorState[name].focus === true
            ? "wrapper"
            : editorState[name].focus === "error" || (errors && touched)
            ? "wrapper-error"
            : ""
        }
        tabIndex="1"
        style={{
          maxWidth: "1024px",
          minHeight: "300px",
          padding: "6px",
          borderRadius: "5px",
          border: "1px solid #C4C4C4",
          ":focus": { border: "2px solid #0086C9" },
        }}
      >
        <Editor
          editorClassName={"inter-font"}
          wrapperClassName={"text-editor-css"}
          stripPastedStyles={true}
          onFocus={focusHandler}
          onBlur={blurHandler}
          editorState={editorState[name].editorState}
          toolbar={toolbarOptions}
          onEditorStateChange={handleChange}
        />
      </div>
      {(editorState[name].focus === "error" || (errors && touched)) && (
        <Typography
          sx={{
            fontFamily: "Inter",
            fontStyle: "normal",
            fontWeight: 400,
            mt: "3px",
            mx: "14px",
            fontSize: "14px",
            lineHeight: "20px",
          }}
          color="#d32f2f"
        >
          {errors}
        </Typography>
      )}
    </Stack>
  );
};

export default TextEditor;
