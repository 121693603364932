import React, { useEffect } from "react";
import Button from "../../components/ui/FormButton";
import AuthStyle from "../../assets/css/Auth.module.css";
import Divider from "@mui/material/Divider";
import { useFormik } from "formik";
import { Stack, Typography } from "@mui/material";
import {
  Form,
  useActionData,
  useLocation,
  useNavigate,
  useSubmit,
} from "react-router-dom";
import PasswordField from "../../components/ui/PasswordField";
import { ChangePass } from "../../schema/index";
import useApi from "../../hooks/use-api";
import Toast from "../../utils/Toast";

const ChangePassword = () => {
  const location = useLocation();
  const submit = useSubmit();
  const actionData = useActionData();
  const navigate = useNavigate();

  const onSubmitApplicationHandler = (event) => {
    event.preventDefault();
    handleSubmit();
  };

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    setFieldValue,
    handleSubmit,
  } = useFormik({
    initialValues: {
      password: "",
      email: "",
      confirm_password: "",
    },
    validationSchema: ChangePass,
    onSubmit: async (values) => {
      submit(values, { method: "post" });
    },
  });

  useEffect(() => {
    if (actionData?.status) {
      Toast.fire({ icon: "success", title: actionData?.message });
      navigate("/auth/login");
    }
  }, [actionData, navigate]);

  return (
    <>
      <Stack className={AuthStyle.form_content}>
        <Stack sx={{ width: { xs: "100%", sm: "400px", md: "380px" } }}>
          <Stack my={2}>
            <Typography typography={"displayMdSemi"}>
              Change Password
            </Typography>
            <Stack py={2}>
              <Typography typography={"textMdSemi"}>Almost there!</Typography>
              <Typography typography={"textLgReg"}>
                Enter your account details to begin
              </Typography>
            </Stack>

            <Divider sx={{ mb: "20px" }} />
          </Stack>
          <Form method="POST" onSubmit={onSubmitApplicationHandler}>
            <PasswordField
              label="Password"
              isError={touched.password && errors.password ? true : false}
              id="password"
              type="password"
              name="password"
              className={AuthStyle.default}
              isColor={`${
                touched.password && !errors.password ? "success" : ""
              }`}
              value={values.password}
              handleBlur={handleBlur}
              handleChange={(e) => {
                setFieldValue("password", e.target.value);
                setFieldValue("email", location.state.email);
              }}
            />
            <Stack py={"0.5rem"}>
              {errors.password && touched.password ? (
                <Typography sx={{ color: "red", typography: "textXsReg" }}>
                  {errors.password}
                </Typography>
              ) : null}
            </Stack>

            <PasswordField
              label="Confirm Password"
              isError={
                touched.confirm_password && errors.confirm_password
                  ? true
                  : false
              }
              id="confirm_password"
              type="password"
              name="confirm_password"
              className={AuthStyle.default}
              isColor={`${
                touched.password && !errors.password ? "success" : ""
              }`}
              value={values.confirm_password}
              handleBlur={handleBlur}
              handleChange={handleChange}
            />
            <Stack py={"0.5rem"}>
              {errors.confirm_password && touched.confirm_password ? (
                <Typography sx={{ color: "red", typography: "textXsReg" }}>
                  {errors.confirm_password}
                </Typography>
              ) : null}
            </Stack>
            <Button
              title="Confirm Password"
              BtnVariant="contained"
              type="submit"
            />
          </Form>
        </Stack>
      </Stack>
    </>
  );
};

export default ChangePassword;

export const action = async ({ request }) => {
  try {
    const data = await request.formData();
    const formData = {
      password: data.get("password"),
      email: data.get("email"),
      confirm_password: data.get("confirm_password"),
    };
    const result = await useApi.postRequest(
      "ids",
      "auth/change-password",
      false,
      formData
    );
    if (result.status) {
      return result;
    }

    throw new Error(result.message);
  } catch (error) {
    return Toast.fire({ icon: "error", message: error.message });
  }
};
