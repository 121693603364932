import React, { useEffect } from "react";
import HeaderLayout from "../../layouts/HeaderLayout";
import { Button, MenuItem, Stack, TextField } from "@mui/material";
import { useFormik } from "formik";
import { useActionData, useLoaderData, useSubmit } from "react-router-dom";
import { ehsServiceSchema } from "../../../schema";
import TextEditor from "../../TextEditor";
import Toast from "../../../utils/Toast";
import UploadFile from "../../File-upload";

const INITIAL_VALUES = {
  title: "",
  subtitle: "",
  about: "",
  description: "",
  image: "",
  mobile_image: "",
  case_study_caption: "",
  link: "",
  position: "",
  category_id: "",
};

const CreateEhsServicesForm = () => {
  const { services, categories } = useLoaderData();
  const actionData = useActionData();
  const submit = useSubmit();
  // ? *********************************************************** Form Submit handler *********************************************************** */
  const onFormSubmitHandler = (e) => {
    e.preventDefault();
    handleSubmit(e);
  };

  // ? *********************************************************** Use of Formik *********************************************************** */
  const {
    values,
    errors,
    touched,
    setFieldValue,
    setFieldTouched,
    handleBlur,
    handleChange,
    handleSubmit,
    resetForm,
  } = useFormik({
    enableReinitialize: true,
    initialValues: INITIAL_VALUES,
    validationSchema: ehsServiceSchema,
    onSubmit: (values) => {
      submit(values, { method: "POST" });
    },
  });

  useEffect(() => {
    if (actionData?.status) {
      resetForm();
      Toast.fire({ icon: "error", title: actionData?.message });
    }
  }, [resetForm, actionData]);

  return (
    <>
      <HeaderLayout title="Service" type="Create a Service" />
      <Stack mx={2} display={"flex"}>
        <Stack my={2}>
          <form onSubmit={onFormSubmitHandler}>
            <Stack spacing={4}>
              {/* Title */}
              <TextField
                sx={{
                  width: "100%",
                  ".MuiFormHelperText-root": {
                    typography: "textSmReg",
                    color: "#d3302f",
                  },
                }}
                color="secondary"
                size="medium"
                name="title"
                variant="outlined"
                label="Title *"
                onBlur={handleBlur}
                error={errors.title && Boolean(errors.title) && touched.title}
                helperText={touched.title && errors.title}
                value={values.title}
                onChange={handleChange}
              />
              {/* Support Text */}
              <TextField
                sx={{
                  width: "100%",
                  ".MuiFormHelperText-root": {
                    typography: "textSmReg",
                    color: "#d3302f",
                  },
                }}
                color="secondary"
                size="medium"
                name="subtitle"
                variant="outlined"
                label="Sub Title"
                onBlur={handleBlur}
                error={
                  errors.subtitle &&
                  Boolean(errors.subtitle) &&
                  touched.subtitle
                }
                helperText={touched.subtitle && errors.subtitle}
                value={values.subtitle}
                onChange={handleChange}
              />

              <TextField
                sx={{
                  width: "100%",
                  ".MuiFormHelperText-root": {
                    typography: "textSmReg",
                    color: "#d3302f",
                  },
                }}
                color="secondary"
                size="medium"
                name="about"
                variant="outlined"
                label="About *"
                onBlur={handleBlur}
                error={errors.about && Boolean(errors.about) && touched.about}
                helperText={touched.about && errors.about}
                value={values.about}
                onChange={handleChange}
                multiline
                rows={3}
              />

              <TextEditor
                name="description"
                title="Description"
                setFieldValue={setFieldValue}
                errors={errors.description}
                touched={touched.description}
                setFieldTouched={setFieldTouched}
                // isEdit={isEdit}
                // savedHtml={isEdit && caseStudy.description}
              />

              <UploadFile title="Image" />

              <TextField
                sx={{
                  width: "100%",
                  ".MuiFormHelperText-root": {
                    typography: "textSmReg",
                    color: "#d3302f",
                  },
                }}
                color="secondary"
                size="medium"
                name="case_study_caption"
                variant="outlined"
                label="Case Study Caption *"
                onBlur={handleBlur}
                error={
                  errors.case_study_caption &&
                  Boolean(errors.case_study_caption) &&
                  touched.case_study_caption
                }
                helperText={
                  touched.case_study_caption && errors.case_study_caption
                }
                value={values.case_study_caption}
                onChange={handleChange}
                multiline
                rows={3}
              />

              <TextField
                fullWidth
                select
                size="small"
                placeholder="Position of the Case Study"
                name="position"
                sx={{
                  width: "100%",
                  ".MuiFormHelperText-root": {
                    typography: "textSmReg",
                    color: "#d3302f",
                  },
                }}
                value={values.position}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.position && touched.position}
                helperText={touched.position && errors.position}
                label="Select Position after"
              >
                <MenuItem value="" disabled selected>
                  Select position
                </MenuItem>
                <MenuItem value="first">At the beginning</MenuItem>
                {services.map((service) => (
                  <MenuItem key={service.id} value={service.position}>
                    {service.title}
                  </MenuItem>
                ))}
              </TextField>

              <TextField
                fullWidth
                select
                size="small"
                placeholder="Select Service"
                name="position"
                sx={{
                  width: "100%",
                  ".MuiFormHelperText-root": {
                    typography: "textSmReg",
                    color: "#d3302f",
                  },
                }}
                value={values.category_id}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.category_id && touched.category_id}
                helperText={touched.category_id && errors.category_id}
                label="Select Category"
              >
                <MenuItem value="" disabled selected>
                  Select Category
                </MenuItem>
                {categories.map((category) => (
                  <MenuItem key={category.id} value={category.id}>
                    {category.title}
                  </MenuItem>
                ))}
              </TextField>
              <Button variant="contained" color="primary" type="submit">
                Submit
              </Button>
            </Stack>
          </form>
        </Stack>
      </Stack>
    </>
  );
};

export default CreateEhsServicesForm;
