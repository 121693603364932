import { Stack } from "@mui/material";
import React, { Suspense } from "react";
import { Await, Link, defer, useLoaderData } from "react-router-dom";
import classes from "../../assets/css/events/EventList.module.css";
import useApi from "../../hooks/use-api";
import EventCard from "../../components/events/EventCard";
import HeaderLayout from "../../components/layouts/HeaderLayout";

const EventList = () => {
  const { events } = useLoaderData();

  return (
    <>
      <HeaderLayout title="Event" type="Event List" />
      <Stack display={"flex"} justifyContent={"center"} alignItems={"center"}>
        <Suspense fallback={<p>Loading...</p>}>
          <Await resolve={events}>
            {(loadedEvents) =>
              loadedEvents.map(
                ({
                  id,
                  title,
                  subtitle,
                  image,
                  location,
                  start_time,
                  end_time,
                }) => (
                  <Link
                    to={`/events/${id}/edit`}
                    key={id}
                    className={classes.link}
                  >
                    <EventCard
                      title={title}
                      subtitle={subtitle}
                      image={image}
                      location={location}
                      startTime={start_time}
                      endTime={end_time}
                    />
                  </Link>
                )
              )
            }
          </Await>
        </Suspense>
        {/* <Stack
        width={"80%"}
        my={2}
        className="ease-transition"
        p={3}
        sx={[
          {
            // backdropFilter: "brightness(10%)",
            background:
              "url(https://www.shutterstock.com/shutterstock/photos/2041227701/display_1500/stock-vector-business-webinar-horizontal-banner-template-design-modern-banner-design-with-black-and-white-2041227701.jpg)",
            cursor: "pointer",
            position: "relative",
            backgroundSize: "cover",
            backgroundPosition: "center",
            "&::before": {
              content: '""',
              position: "absolute",
              top: 0,
              right: 0,
              backdropFilter: "blur(3px)",
              bottom: 0,
              borderRadius: "1rem",
              left: 0,
              backgroundColor: "rgba(0, 0, 0, 0.5)",
            },
            borderRadius: "1rem",
            border: "1px solid #EAECF0",
          },
          {
            "&:hover": {
              scale: "1.009",

              border: "1px solid var(--gray-200,#0086C9)",
              boxShadow: "0px 10px 28px -12px rgba(16, 24, 40, 0.18)",
            },
          },
        ]}
      >
        <Stack color={"white"} sx={{ backdropFilter: "brightness(100%)" }}>
          <Stack
            display={"flex"}
            justifyContent={"space-between"}
            direction={"row"}
            color={"white"}
            mb={1}
          >
            <Typography
              mb={2}
              sx={{ typography: { xs: "textLgSemi", sm: "displayXlBold" } }}
            >
              Event Title
            </Typography>
            <Typography
              sx={{
                typography: { xs: "textMdSemi" },
                color: "primary.blue700",
              }}
              display={{ xs: "none", sm: "flex" }}
              justifyContent={"center"}
            >
              Edit <ArrowOutwardIcon sx={{ mx: "2px", pb: "5px" }} />
            </Typography>
          </Stack>

          <Typography
            color={"primary.gray600"}
            maxWidth="sm"
            sx={{ typography: { xs: "textMdLeg", sm: "textLgReg" } }}
            className={CardStyle.description_class}
          >
            Description
          </Typography>

          <Stack
            direction={"row"}
            display={"flex"}
            justifyContent={"space-between"}
          >
            <Stack
              display={"flex"}
              mt={2}
              alignItems={"center"}
              justifyContent={"center"}
              spacing={2}
              direction="row"
            >
              <Typography
                sx={{
                  typography: { xs: "textXsReg", sm: "textMdReg" },
                  color: "primary.gray600",
                }}
                flexDirection={{ xs: "column", md: "row" }}
                display={"flex"}
                textAlign={"center"}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <LocationOnOutlinedIcon
                  sx={{
                    mx: { xs: "4px", md: "initial" },
                    p: "2px",
                    width: { xs: "15px", md: "20px" },
                    height: { xs: "15px", md: "20px" },
                  }}
                />{" "}
                Work Mode
              </Typography>
              <Typography
                sx={{
                  typography: { xs: "textXsReg", sm: "textMdReg" },
                  color: "primary.gray600",
                }}
                flexDirection={{ xs: "column", md: "row" }}
                display={"flex"}
                textAlign={"center"}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <AccessTimeOutlinedIcon
                  sx={{
                    mx: "4px",
                    p: "2px",
                    width: { xs: "15px", md: "20px" },
                    height: { xs: "15px", md: "20px" },
                  }}
                />{" "}
                Type
              </Typography>
              <Typography
                sx={{
                  typography: { xs: "textXsReg", sm: "textMdReg" },
                  color: "primary.gray600",
                }}
                flexDirection={{ xs: "column", md: "row" }}
                display={"flex"}
                textAlign={"center"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <WorkHistoryOutlinedIcon
                  sx={{
                    mx: "4px",
                    p: "2px",
                    width: { xs: "15px", md: "20px" },
                    height: { xs: "15px", md: "20px" },
                  }}
                />{" "}
                Experience
              </Typography>
            </Stack> */}
        {/* <Stack>
          <Typography
            sx={{
              typography: { xs: "textXsReg", sm: "textMdReg" },
              color: "primary.gray600",
            }}
              flexDirection={{ xs: "column", md: "row" }}
            display={"flex"}
            justifyContent={"center"}
          >
            #{list.id}
          </Typography>
        </Stack> */}
        {/* </Stack> */}
        {/* </Stack> */}
        {/* </Stack> */}
        {/* <Stack
        width={"80%"}
        my={2}
        className="ease-transition"
        p={3}
        sx={[
          {
            // backdropFilter: "brightness(10%)",
            background:
              "url(https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQVV6iKpFqbiUc2mMvgN5Z0WRHQklkE15sSjo_zEb9O0lVDdWbQ7SjoBSw9BkK4KqcryuI&usqp=CAU)",
            cursor: "pointer",
            position: "relative",
            backgroundSize: "cover",
            backgroundPosition: "center",
            "&::before": {
              content: '""',
              position: "absolute",
              top: 0,
              right: 0,
              bottom: 0,
              backdropFilter: "blur(3px)",
              borderRadius: "1rem",
              left: 0,
              backgroundColor: "rgba(0, 0, 0, 0.5)",
            },
            borderRadius: "1rem",
            border: "1px solid #EAECF0",
          },
          {
            "&:hover": {
              scale: "1.009",

              border: "1px solid var(--gray-200,#0086C9)",
              boxShadow: "0px 10px 28px -12px rgba(16, 24, 40, 0.18)",
            },
          },
        ]}
      >
        <Stack color={"white"} sx={{ backdropFilter: "brightness(100%)" }}>
          <Stack
            display={"flex"}
            justifyContent={"space-between"}
            direction={"row"}
            color={"white"}
            mb={1}
          >
            <Typography
              mb={2}
              sx={{ typography: { xs: "textLgSemi", sm: "displayXlBold" } }}
            >
              Event Title
            </Typography>
            <Typography
              sx={{
                typography: { xs: "textMdSemi" },
                color: "primary.blue700",
              }}
              display={{ xs: "none", sm: "flex" }}
              justifyContent={"center"}
            >
              Edit <ArrowOutwardIcon sx={{ mx: "2px", pb: "5px" }} />
            </Typography>
          </Stack>

          <Typography
            color={"primary.gray600"}
            maxWidth="sm"
            sx={{ typography: { xs: "textMdLeg", sm: "textLgReg" } }}
            className={CardStyle.description_class}
          >
            Description
          </Typography>

          <Stack
            direction={"row"}
            display={"flex"}
            justifyContent={"space-between"}
          >
            <Stack
              display={"flex"}
              mt={2}
              alignItems={"center"}
              justifyContent={"center"}
              spacing={2}
              direction="row"
            >
              <Typography
                sx={{
                  typography: { xs: "textXsReg", sm: "textMdReg" },
                  color: "primary.gray600",
                }}
                flexDirection={{ xs: "column", md: "row" }}
                display={"flex"}
                textAlign={"center"}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <LocationOnOutlinedIcon
                  sx={{
                    mx: { xs: "4px", md: "initial" },
                    p: "2px",
                    width: { xs: "15px", md: "20px" },
                    height: { xs: "15px", md: "20px" },
                  }}
                />{" "}
                Work Mode
              </Typography>
              <Typography
                sx={{
                  typography: { xs: "textXsReg", sm: "textMdReg" },
                  color: "primary.gray600",
                }}
                flexDirection={{ xs: "column", md: "row" }}
                display={"flex"}
                textAlign={"center"}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <AccessTimeOutlinedIcon
                  sx={{
                    mx: "4px",
                    p: "2px",
                    width: { xs: "15px", md: "20px" },
                    height: { xs: "15px", md: "20px" },
                  }}
                />{" "}
                Type
              </Typography>
              <Typography
                sx={{
                  typography: { xs: "textXsReg", sm: "textMdReg" },
                  color: "primary.gray600",
                }}
                flexDirection={{ xs: "column", md: "row" }}
                display={"flex"}
                textAlign={"center"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <WorkHistoryOutlinedIcon
                  sx={{
                    mx: "4px",
                    p: "2px",
                    width: { xs: "15px", md: "20px" },
                    height: { xs: "15px", md: "20px" },
                  }}
                />{" "}
                Experience
              </Typography>
            </Stack> */}
        {/* <Stack>
          <Typography
            sx={{
              typography: { xs: "textXsReg", sm: "textMdReg" },
              color: "primary.gray600",
            }}
              flexDirection={{ xs: "column", md: "row" }}
            display={"flex"}
            justifyContent={"center"}
          >
            #{list.id}
          </Typography>
        </Stack> */}
        {/* </Stack>
        </Stack>
      </Stack> */}
      </Stack>
    </>
  );
};

export default EventList;

// ? ************************************************* Show all the events ************************************************* */
const loadEvents = async () => {
  try {
    const result = await useApi.getRequest("ids", "events", true);
    if (result.status) {
      return result.data;
    }

    throw new Error(result.message);
  } catch (error) {
    console.log(error.message);
  }
};

// ? ************************************************* Loader ************************************************* */
export const loader = () => {
  return defer({
    events: loadEvents(),
  });
};
