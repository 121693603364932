import React from "react";
import EventForm from "../../components/events/EventForm";
import { useLoaderData } from "react-router-dom";

const EditEvent = () => {
  const { event } = useLoaderData();

  return <EventForm isEdit={true} data={event} />;
};

export default EditEvent;
