const cards = [
  {
    title: "Certificates",
    link: "create",
    icon: "Group",
    helperText: "Some Helper text here. Lorem Ipsem is best. The best I say.",
  },
  {
    title: "View Certificates",
    link: "view",
    icon: "Group",
    helperText: "Some Helper text here. Lorem Ipsem is best. The best I say.",
  },
  {
    title: "Create Template",
    link: "create-template",
    icon: "PersonAdd",
    helperText: "Some Helper text here. Lorem Ipsem is best. The best I say.",
  },
  {
    title: "View Templates",
    link: "view-templates",
    icon: "PersonAdd",
    helperText: "Some Helper text here. Lorem Ipsem is best. The best I say.",
  },
];

export default cards;
