import React, { useCallback, useContext, useEffect, useState } from "react";
import { IconButton, InputAdornment, Stack, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { Close } from "@mui/icons-material";
import DataTable from "./Table/DataTable";
import Modal from "./ui/Modal";
import Filters from "./ui/Filters";
import SidebarContext from "../store/sidebar-context";

let filteredRows = [];

const Listing = (props) => {
  const [rows, setRows] = useState(props.lists);
  const [searched, setSearched] = useState("");
  const [isModal, setIsModal] = useState(false);
  const [modalData, setModalData] = useState({});
  const [filterLists, setFilterLists] = useState([]);
  const [subFilterLists, setSubFilterLists] = useState([]);
  const [departments, setDepartments] = useState([]);

  // ? ******************************************************** Search from the list ******************************************************** */
  const requestSearch = (searchedVal) => {
    setSearched(searchedVal);
    const filterData = filteredRows.length > 0 ? filteredRows : props.lists;
    const filteringRows = filterData.filter((row) => {
      return (
        row.full_name?.toLowerCase().includes(searchedVal.toLowerCase()) ||
        row.type?.toLowerCase().includes(searchedVal.toLowerCase()) ||
        row.email?.toLowerCase().includes(searchedVal.toLowerCase())
      );
    });
    setRows(filteringRows);
  };

  // ? ******************************************************** Cancel the searching ******************************************************** */
  const cancelSearch = () => {
    setSearched("");
    requestSearch("");
  };

  // ? ******************************************************** filters on the top ******************************************************** */
  const filterOnTheTop = useCallback(
    (filterType) => {
      switch (filterType) {
        case "applications":
          const jobIds = [
            ...new Set(
              props.lists
                .map((list) => list.job_id)
                .filter((item) => item !== null)
            ),
          ];
          setFilterLists(
            props.jobs
              .filter((job) => jobIds.indexOf(job.id) !== -1)
              .map((job) => ({
                id: job.id,
                name: job.title,
                isActive: false,
              }))
          );
          break;
        case "contacts":
          const inquiryIds = [
            ...new Set(
              props.lists
                .map((list) => list.inquiry_id)
                .filter((item) => item !== null)
            ),
          ];
          setFilterLists(
            props.inquiries
              .filter((inquiry) => inquiryIds.indexOf(inquiry.id) !== -1)
              .map((inquiry) => ({
                id: inquiry.id,
                name: inquiry.name,
                isActive: false,
              }))
          );
          break;
        default:
          setFilterLists([]);
          break;
      }
    },
    [props.inquiries, props.jobs, props.lists]
  );

  // ? ******************************************************** Id type of the filters ******************************************************** */
  const filterIdType = () => {
    let idType;
    switch (props.type) {
      case "contacts":
        idType = "inquiry_id";
        break;
      case "applications":
        idType = "job_id";
        break;
      default:
        idType = "";
        break;
    }
    return idType;
  };

  // const onChangeFilterHandler = (id, type) => {

  // }

  // ? ******************************************************** Set active filter ******************************************************** */
  const setActiveFilterHandler = async (id, name) => {
    // on click active the current and previous filter and vice versa
    const activeFilters = filterLists.map((filterList) =>
      filterList.id === id
        ? { ...filterList, isActive: !filterList.isActive }
        : filterList
    );

    setFilterLists(activeFilters);
    // filter the table rows according to the active filters on the top
    filteredRows = props.lists.filter((row) =>
      activeFilters.some(
        (activeFilter) =>
          activeFilter.isActive && activeFilter.id === row[filterIdType()]
      )
    );

    //  set the sub filters if the type is contact others wise it's an empty array
    if (props.type === "contacts") {
      // check the length of the active filters for the sub filter list
      const isActiveFilterLength = activeFilters.filter(
        (activeFilter) => activeFilter.isActive === true
      ).length;

      const subFilters =
        filteredRows.length > 0 && isActiveFilterLength === 1
          ? props.subInquiries
              .filter((subInquiry) =>
                activeFilters.some(
                  (activeFilter) =>
                    activeFilter.isActive &&
                    activeFilter.id === subInquiry.inquiry_id
                )
              )
              .map((subInquiry) => ({
                id: subInquiry.id,
                name: subInquiry.name,
                isActive: false,
              }))
          : [];

      setSubFilterLists(subFilters);
    }

    // set the table rows at the end of the logic
    setRows(filteredRows.length > 0 ? filteredRows : props.lists);
  };

  // ? ******************************************************** Set sub active filter handler ******************************************************** */
  const setSubActiveFilterHandler = (id) => {
    // on click active the current and previous filter and vice versa
    const activeSubFilters = subFilterLists.map((subFilterList) =>
      subFilterList.id === id
        ? { ...subFilterList, isActive: !subFilterList.isActive }
        : subFilterList
    );
    setSubFilterLists(activeSubFilters);

    const filteringListIds = props.lists
      .filter((list) =>
        filterLists.some(
          (filterList) =>
            filterList.isActive && filterList.id === list.inquiry_id
        )
      )
      .map((list) => list.id);

    const filteredByInquiries = props.contactInquiries.filter(
      (row) =>
        activeSubFilters.some(
          (activeFilter) =>
            activeFilter.isActive && activeFilter.id === row.sub_inquiry_id
        ) && filteringListIds.some((id) => id === row.contact_us_id)
    );

    setDepartments(
      props.allDepartments.filter((department) =>
        filteredByInquiries.some(
          (activeFilter) => activeFilter.department_id === department.id
        )
      )
    );

    filteredRows = filteredByInquiries.map(
      (newContact) => newContact.contact_u
    );

    // filter the table rows according to the active filters on the top
    const previousFilters = props.lists.filter((row) =>
      filterLists.some(
        (activeFilter) =>
          activeFilter.isActive && activeFilter.id === row[filterIdType()]
      )
    );

    // set the table rows at the end of the logic
    setRows(
      filteredRows.length > 0
        ? filteredRows
        : previousFilters.length > 0
        ? previousFilters
        : props.lists
    );
  };

  // ? ******************************************************** on department select ******************************************************** */
  const onFilterDepartmentHandler = (id) => {
    // on click active the current and previous filter and vice versa
    const activeDepartmentFilters = departments.map((department) =>
      department.id === id
        ? { ...department, isActive: !department.isActive }
        : department
    );

    setDepartments(activeDepartmentFilters);

    const filteringListIds = props.lists
      .filter((list) =>
        filterLists.some(
          (filterList) =>
            filterList.isActive && filterList.id === list.inquiry_id
        )
      )
      .map((list) => list.id);

    const newContacts = props.contactInquiries
      .filter(
        (row) =>
          subFilterLists.some(
            (subFilterList) =>
              subFilterList.isActive && subFilterList.id === row.sub_inquiry_id
          ) &&
          activeDepartmentFilters.some(
            (activeFilter) =>
              activeFilter.isActive && activeFilter.id === row.department_id
          ) &&
          filteringListIds.some((id) => id === row.contact_us_id)
      )
      .map((newContact) => newContact.contact_u);

    // filter the table rows according to the active filters on the top
    const filteredRows = props.contactInquiries
      .filter(
        (row) =>
          subFilterLists.some(
            (activeFilter) =>
              activeFilter.isActive && activeFilter.id === row.sub_inquiry_id
          ) && filteringListIds.some((id) => id === row.contact_us_id)
      )
      .map((newContact) => newContact.contact_u);
    // set the table rows at the end of the logic
    setRows(
      newContacts.length > 0
        ? newContacts
        : filteredRows.length > 0
        ? filteredRows
        : props.lists
    );
  };

  // ? ******************************************************** state to update the filter on the top ******************************************************** */
  useEffect(() => {
    filterOnTheTop(props.type);
  }, [props.type, filterOnTheTop]);

  const sidebarContext = useContext(SidebarContext);

  // ? *********************************************** Page rendering *********************************************** */
  return (
    <>
      <Stack maxWidth={sidebarContext.isOpen ? "78vw" : "90vw"}>
        {filterLists.length > 0 && (
          <Filters
            setActiveFilterHandler={setActiveFilterHandler}
            filterLists={filterLists}
            isSubFilter={false}
          />
        )}
        {subFilterLists.length > 0 && (
          <>
            <Filters
              setActiveFilterHandler={setSubActiveFilterHandler}
              filterLists={subFilterLists}
              isSubFilter={true}
            />
          </>
        )}
        {subFilterLists.filter(
          (subFilterList) => subFilterList.isActive === true
        ).length === 1 && (
          <Filters
            setActiveFilterHandler={onFilterDepartmentHandler}
            filterLists={departments}
            isSubFilter={true}
          />
        )}
        <Stack sx={{ display: "flex", alignItems: "end" }}>
          <TextField
            sx={{ my: 1, width: "400px" }}
            id="standard-search-input"
            type="text"
            placeholder="Search"
            size="small"
            variant="outlined"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon
                    onMouseDown={(e) => e.preventDefault()}
                    sx={{
                      visibility: "value",
                      color: "grey.grey400",
                    }}
                  />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={cancelSearch}
                    onMouseDown={(e) => e.preventDefault()}
                    sx={{
                      visibility: searched ? "visible" : "hidden",
                      "&:hover": {
                        cursor: "pointer",
                        backgroundColor: "transparent",
                        color: "primary.primary600",
                      },
                    }}
                  >
                    <Close />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            value={searched}
            onChange={(e) => requestSearch(e.target.value)}
          />
        </Stack>
        {isModal && (
          <Modal
            isModal={isModal}
            setIsModal={setIsModal}
            classes={props.classes}
            data={modalData}
            type={props.type}
            listData={rows}
          />
        )}
        <DataTable
          value={searched}
          onCancelSearch={cancelSearch}
          rows={rows}
          onChange={requestSearch}
          column={props.type}
          setIsModal={setIsModal}
          setModalData={setModalData}
        />
      </Stack>
    </>
  );
};

export default Listing;
