import React, { useEffect } from "react";
import UploadFile from "../../components/File-upload";
import { Button, Divider, Stack, TextField } from "@mui/material";
import HeaderLayout from "../../components/layouts/HeaderLayout";
import { certificateTemplateSchema } from "../../schema";
import { useFormik } from "formik";
import { useActionData, useSubmit } from "react-router-dom";
import Toast from "../../utils/Toast";
import useApi from "../../hooks/use-api";

const initialValues = {
  name: "",
  alias: "",
  image: "",
};
const CreateTemplate = () => {
  const submit = useSubmit();
  const actionData = useActionData();

  const {
    values,
    errors,
    touched,
    setFieldValue,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldTouched,
    resetForm,
  } = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: certificateTemplateSchema,
    onSubmit: (values) => {
      submit(values, { method: "POST" });
    },
  });

  useEffect(() => {
    if (actionData?.status) {
      resetForm();
      Toast.fire({ icon: "success", title: actionData?.message });
    }
  }, [actionData, resetForm]);

  return (
    <>
      <HeaderLayout title="Certificates" type="Create Certificate" />
      <Divider />
      <Stack
        maxWidth={"sm"}
        my={10}
        mx={"auto"}
        sx={{ display: "flex", justifyContent: "center" }}
      >
        <form onSubmit={handleSubmit}>
          <Stack spacing={4} mb={4}>
            <TextField
              sx={{
                width: "100%",
                ".MuiFormHelperText-root": {
                  typography: "textSmReg",
                  color: "#d3302f",
                },
              }}
              color="secondary"
              size="medium"
              name="name"
              variant="outlined"
              label="Certificate Name *"
              onBlur={handleBlur}
              error={errors.name && Boolean(errors.name) && touched.name}
              helperText={touched.name && errors.name}
              value={values.name}
              onChange={handleChange}
            />
            <TextField
              sx={{
                width: "100%",
                ".MuiFormHelperText-root": {
                  typography: "textSmReg",
                  color: "#d3302f",
                },
              }}
              color="secondary"
              size="medium"
              name="alias"
              variant="outlined"
              label="Template Alias"
              onBlur={handleBlur}
              value={values.alias}
              onChange={handleChange}
            />
          </Stack>
          <UploadFile
            title={"Certificate template"}
            accept=".png"
            errorMessage={errors.image}
            errorStatus={
              errors.image !== undefined && touched.image !== undefined
                ? true
                : false
            }
            name={"image"}
            folderPath="certificates/templates"
            setFieldValue={setFieldValue}
            handleBlur={handleBlur}
            setFieldTouched={setFieldTouched}
            values={{ image: values.image }}
          />

          <Button
            type="submit"
            sx={{
              my: 4,
              bgcolor: "primary.primary600",
              color: "white",
              typography: "textLgSemi",
              px: 2,
              py: 1,
              "&:hover": {
                bgcolor: "primary.primary700",
              },
            }}
          >
            Submit
          </Button>
        </form>
      </Stack>
    </>
  );
};

export default CreateTemplate;

export const action = async ({ request }) => {
  try {
    const data = await request.formData();
    const formData = Object.fromEntries(data);
    const result = await useApi.postRequest(
      "ids",
      "certificate-templates",
      true,
      formData
    );
    if (result.status) {
      return result;
    }
    throw new Error(result.message);
  } catch (error) {
    return Toast.fire({ icon: "error", title: error.message });
  }
};
