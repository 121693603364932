import { defer } from "react-router-dom";
import useApi from "../hooks/use-api";

// ? ************************************************************ Load the cards which have the permissions to the logged in user  ************************************************************ */
const cardPermissions = async (id) => {
  try {
    const result = await useApi.getRequest(
      "ids",
      `permissions/cards/${id}`,
      true
    );
    if (result.status) {
      return result.data;
    }

    throw new Error(result.message);
  } catch (error) {
    return console.log(error.message);
  }
};

// ? ******************************************************** load the lists according to the type ******************************************************** */
const lists = async (type) => {
  try {
    const result = await useApi.getRequest("ids", type, true);
    if (!result.status) {
      throw new Error(result.message);
    }

    return result.data;
  } catch (error) {
    return console.log(error.message);
  }
};

// ? ******************************************************** load the inquiries ******************************************************** */
const inquiries = async () => {
  try {
    const result = await useApi.getRequest("ids", "inquiries", true);
    if (result.status) {
      return result.data;
    }

    throw new Error(result.message);
  } catch (error) {
    console.log(error.message);
  }
};

// ? ******************************************************** load the sub inquiries ******************************************************** */
const subInquiries = async () => {
  try {
    const result = await useApi.getRequest("ids", "sub-inquiries", true);
    if (result.status) {
      return result.data;
    }

    throw new Error(result.message);
  } catch (error) {
    console.log(error.message);
  }
};

// ? ******************************************************** load the contact inquiries ******************************************************** */
const contactInquiries = async () => {
  try {
    const result = await useApi.getRequest("ids", "contact-inquiries", true);
    if (result.status) {
      return result.data;
    }

    throw new Error(result.message);
  } catch (error) {
    console.log(error.message);
  }
};

// ? ******************************************************** load the departments ******************************************************** */
const departments = async () => {
  try {
    const result = await useApi.getRequest("ids", "departments", true);
    if (result.status) {
      return result.data;
    }

    throw new Error(result.message);
  } catch (error) {
    console.log(error.message);
  }
};

// ? ******************************************************** load the jobs ******************************************************** */
const jobs = async () => {
  try {
    const result = await useApi.getRequest("ids", "jobs", true);
    if (result.status) {
      return result.data;
    }

    throw new Error(result.message);
  } catch (error) {
    console.log(error.message);
  }
};

// ? ***************************************************************** Load roles ***************************************************************** */
const roles = async () => {
  try {
    const result = await useApi.getRequest("ids", "roles", true);
    if (result.status) {
      return result.data;
    }

    throw new Error(result.message);
  } catch (error) {
    console.log(error.message);
  }
};

// ? ***************************************************************** Permissions ***************************************************************** */
const permissionsTypes = async () => {
  try {
    const result = await useApi.getRequest("ids", "permission-types", true);
    if (result.status) {
      return result.data;
    }

    throw new Error(result.message);
  } catch (error) {
    console.log(error.message);
  }
};

// ? ************************************************************** Users ************************************************************** */
const users = async () => {
  try {
    const result = await useApi.getRequest("ids", "users", true);
    if (result.status) {
      return result;
    }

    throw new Error(result.message);
  } catch (error) {
    console.log(error.message);
  }
};

// ? ************************************************************** Users ************************************************************** */
const user = async (id) => {
  try {
    const result = await useApi.getRequest("ids", `users/${id}`, true);
    if (result.status) {
      return {
        first_name: result.data.first_name,
        last_name: result.data.last_name,
        email: result.data.email,
        role_id: result.data.role_id,
        department_id: result.data.department_id,
        permission_id: result.data.permission_id.map(String),
        password: "",
      };
    }

    throw new Error(result.message);
  } catch (error) {
    console.log(error.message);
  }
};

// ? ******************************************************** Personal Information loader ******************************************************** */
const personalInfo = async (id) => {
  try {
    const result = await useApi.getRequest("ids", `users/${id}`, true);
    if (result.status) {
      return result.data;
    }

    throw new Error(result.message);
  } catch (error) {
    console.log(error.message);
  }
};

// ? ********************************************************************* Posted jobs ********************************************************************* */
const openings = async () => {
  try {
    const result = await useApi.getRequest("ids", "openings/all", true);
    if (result.status) {
      return result.data;
    }

    throw new Error(result.message);
  } catch (error) {
    console.log(error.message);
  }
};

// ? ************************************************* Show all the events ************************************************* */
const events = async () => {
  try {
    const result = await useApi.getRequest("ids", "events", true);
    if (result.status) {
      return result.data;
    }

    throw new Error(result.message);
  } catch (error) {
    console.log(error.message);
  }
};

// ? ******************************************************* Load the current event ******************************************************* */
const event = async (id) => {
  try {
    const result = await useApi.getRequest("ids", `events/${id}`, true);
    if (result.status) {
      return result.data;
    }

    throw new Error(result.message);
  } catch (error) {
    console.log(error.message);
  }
};

// ? ******************************************************* Load the Certificates templates******************************************************* */
// all the certificate templates
const certificateTemplates = async () => {
  try {
    const result = await useApi.getRequest(
      "ids",
      `certificate-templates`,
      true
    );
    if (result.status) {
      return result.data;
    }

    throw new Error(result.message);
  } catch (error) {
    console.log(error.message);
  }
};

// get the single certificate template
const certificateTemplate = async (id) => {
  try {
    const result = await useApi.getRequest(
      "ids",
      `certificate-templates/${id}`,
      true
    );
    if (result.status) {
      return result.data;
    }

    throw new Error(result.message);
  } catch (error) {
    console.log(error.message);
  }
};

// ? ******************************************************* Load the Certificates ******************************************************* */
const certificates = async () => {
  try {
    const result = await useApi.getRequest("ids", `certificates`, true);
    if (result.status) {
      return result.data;
    }

    throw new Error(result.message);
  } catch (error) {
    console.log(error.message);
  }
};

// get the single certificate
const certificate = async (id) => {
  try {
    const result = await useApi.getRequest("ids", `certificates/${id}`, true);
    if (result.status) {
      return result.data;
    }

    throw new Error(result.message);
  } catch (error) {
    console.log(error.message);
  }
};

// ? ******************************************************* Load the all the case studies ******************************************************* */
const caseStudies = async () => {
  try {
    const result = await useApi.getRequest("ehs", "case-studies", true);
    if (result.status) {
      return result.data;
    }

    throw new Error(result.message);
  } catch (error) {
    console.log(error.message);
  }
};

// ? ******************************************************* Load the single case study ******************************************************* */
const caseStudy = async (id) => {
  try {
    const result = await useApi.getRequest("ehs", `case-studies/${id}`, true);
    if (result.status) {
      return result.data;
    }

    throw new Error(result.message);
  } catch (error) {
    console.log(error.message);
  }
};

// ? ******************************************************* Load the all the sub services ******************************************************* */
const subServices = async () => {
  try {
    const result = await useApi.getRequest("ehs", "sub-services", true);
    if (result.status) {
      return result.data;
    }

    throw new Error(result.message);
  } catch (error) {
    console.log(error.message);
  }
};

// ? ******************************************************* Industries ******************************************************* */
// get all the industries
const industries = async () => {
  try {
    const result = await useApi.getRequest("ssb", "industries", true);
    if (result.status) {
      return result.data;
    }

    throw new Error(result.message);
  } catch (error) {
    console.log(error.message);
  }
};

// ? ******************************************************* SSB services ******************************************************* */
// get all the services
const services = async () => {
  try {
    const result = await useApi.getRequest("ssb", "services", true);
    if (result.status) {
      return result.data;
    }

    throw new Error(result.message);
  } catch (error) {
    console.log(error.message);
  }
};

// ? ************************************************************ SSB case studies  ************************************************************ */
const ssbCaseStudies = async () => {
  try {
    const result = await useApi.getRequest("ssb", "case-studies", true);
    if (result.status) {
      return result.data;
    }

    throw new Error(result.message);
  } catch (error) {
    console.log(error.message);
  }
};

// ? ************************************************************ EHS Loaders  ************************************************************ */
// get all the services
const ehsServices = async () => {
  try {
    const result = await useApi.getRequest("ehs", "services", true);
    if (result.status) {
      return result.data;
    }

    throw new Error(result.message);
  } catch (error) {
    console.log(error.message);
  }
};

// get all the categories
const ehsCategories = async () => {
  try {
    const result = await useApi.getRequest("ehs", "categories", true);
    if (result.status) {
      return result.data;
    }

    throw new Error(result.message);
  } catch (error) {
    console.log(error.message);
  }
};

// ? ************************************************************ Healthcare  ************************************************************ */
// leads loader
const healthcareLeads = async () => {
  try {
    const result = await useApi.getRequest("hcs", "leads", true);
    if (result.status) {
      return result.data;
    }

    throw new Error(result.message);
  } catch (error) {
    console.log(error.message);
  }
};
// guides loader
const healthcareGuides = async () => {
  try {
    const result = await useApi.getRequest("hcs", "guides", true);
    if (result.status) {
      return result.data;
    }

    throw new Error(result.message);
  } catch (error) {
    console.log(error.message);
  }
};
// ? ************************************************************ Loaders  ************************************************************ */
// home loader
export const homeLoader = () => {
  return defer({
    cards: cardPermissions(1),
  });
};

// lists loader
export const listLoader = ({ request, params }) => {
  const listType = params.type;
  return defer({
    lists: lists(listType),
    inquiries: inquiries(),
    subInquiries: subInquiries(),
    contactInquiries: contactInquiries(),
    allDepartments: departments(),
    jobs: jobs(),
    type: params.type.toLowerCase(),
  });
};

// team management loader
export const teamManagementLoader = () => {
  return defer({
    roles: roles(),
    departments: departments(),
    permissionTypes: permissionsTypes(),
    users: users(),
  });
};

// edit user loader
export const editUserLoader = ({ params }) => {
  const id = params.id;
  return defer({
    user: user(id),
  });
};

// personal info loader
export const personalInfoLoader = () => {
  const id = JSON.parse(localStorage.getItem("user"))?.id;
  return defer({
    personalInfo: personalInfo(id),
  });
};

// job post loader
export const jobPostLoader = () => {
  return defer({
    jobs: jobs(),
    cards: cardPermissions(4),
  });
};

// post job loader
export const postJobLoader = async () => {
  return defer({
    jobs: await jobs(),
  });
};

// job list loader
export const jobListLoader = () => {
  return defer({
    openings: openings(),
  });
};

// Events loader
export const eventsLoader = () => {
  return defer({
    events: events(),
  });
};

// Event loader
export const editEventLoader = async ({ request, params }) => {
  const id = params.id;
  return defer({
    event: await event(id),
  });
};

// create case study loader for the positions
export const createCaseStudyLoader = async () => {
  return defer({
    caseStudies: await caseStudies(),
    subServices: await subServices(),
  });
};

// case study list loader
export const caseStudyListLoader = () => {
  return defer({
    caseStudies: caseStudies(),
  });
};

// Edit case study loader
export const editCaseStudyLoader = async ({ params }) => {
  const id = params.id;
  return defer({
    caseStudies: await caseStudies(),
    subServices: await subServices(),
    caseStudy: await caseStudy(id),
  });
};

// Create ssb case study
export const createSSBCaseStudyLoader = async () => {
  return defer({
    industries: await industries(),
    services: await services(),
    subServices: await subServices(),
  });
};

// show ssb case studies list
export const ssbCaseStudyListLoader = () => {
  return defer({
    caseStudies: ssbCaseStudies(),
  });
};

// ehs create services loader
export const ehsCreateServicesLoader = async () => {
  return defer({
    services: await ehsServices(),
    categories: await ehsCategories(),
  });
};

// ids certificate templates
export const certificateTemplatesLoader = async () => {
  return defer({
    certificateTemplates: certificateTemplates(),
  });
};

// ids certificate templates edit
export const editCertificateTemplatesLoader = async ({ params }) => {
  const id = params.id;
  return defer({
    certificateTemplate: await certificateTemplate(id),
  });
};

// ids certificate templates
export const createCertificateLoader = async () => {
  return defer({
    certificateTemplates: await certificateTemplates(),
  });
};

// ids certificates
export const certificatesLoader = async () => {
  return defer({
    certificates: certificates(),
  });
};

// ids edit certificate loader
export const editCertificateLoader = async ({ params }) => {
  const id = params.id;
  return defer({
    certificate: await certificate(id),
    certificateTemplates: await certificateTemplates(),
  });
};
// hcs leads loader
export const healthcareLeadsLoader = () => {
  return defer({
    leads: healthcareLeads(),
  });
};
// hcs guides loader
export const healthcareGuidesLoader = () => {
  return defer({
    guides: healthcareGuides(),
  });
};
