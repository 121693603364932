import React from "react";
import { Button } from "@mui/material";

const FormButton = (props) => {
  return (
    <Button
      variant={props.BtnVariant}
      sx={{ width: "100%", padding: "1rem", marginBottom: "1.2rem" }}
      type={props.type}
    >
      {props.title}
    </Button>
  );
};

export default FormButton;
