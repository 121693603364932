import { createBrowserRouter } from "react-router-dom";
import RootLayout from "../components/layouts/Layout";
import Error from "../pages/Error";
import CheckAuthLoader from "../utils/Auth";
import Home from "../pages/home";
import Lists from "../pages/home/Lists";
import ListItem from "../pages/home/ListItem";
import TeamManagement from "../pages/team-management";
import CreateUser, {
  action as createUserAction,
} from "../pages/team-management/CreateUser";
import UserList, {
  action as userAction,
} from "../pages/team-management/UserList";
import EditUser, {
  action as editUserAction,
} from "../pages/team-management/EditUser";
import { action as logoutAction } from "../pages/Logout";
import AuthLayout from "../pages/authentication/Layout";
import Login, { action as loginAction } from "../pages/authentication/Login";
import ForgotPassword, {
  action as ForgotPasswordAction,
} from "../pages/authentication/ForgotPassword";
import VerifyOtp, {
  action as VerifyOtpAction,
} from "../pages/authentication/VerifyOtp";
import ChangePassword, {
  action as ChangePasswordAction,
} from "../pages/authentication/ChangePassword";
import PersonalInformation from "../pages/personal-information";
import JobPost from "../pages/post-job";
import CreateJob, {
  action as createJobAction,
} from "../pages/post-job/CreateJob";
import JobList from "../pages/post-job/JobList";
import EditJob from "../pages/post-job/EditJob";
import Events from "../pages/events";
import CreateEvent, {
  action as createEventAction,
} from "../pages/events/CreateEvent";
import EventList from "../pages/events/EventList";
import HeaderLayout from "../components/layouts/HeaderLayout";
import EventItem from "../pages/events/EventItem";
import EditEvent from "../pages/events/EditEvent";
import {
  caseStudyListLoader,
  certificatesLoader,
  certificateTemplatesLoader,
  createCaseStudyLoader,
  createCertificateLoader,
  createSSBCaseStudyLoader,
  editCaseStudyLoader,
  editCertificateLoader,
  editCertificateTemplatesLoader,
  editEventLoader,
  editUserLoader,
  ehsCreateServicesLoader,
  eventsLoader,
  healthcareGuidesLoader,
  healthcareLeadsLoader,
  homeLoader,
  jobListLoader,
  jobPostLoader,
  listLoader,
  personalInfoLoader,
  postJobLoader,
  ssbCaseStudyListLoader,
  teamManagementLoader,
} from "../utils/Loaders";
import EHS from "../pages/ehs";
import SSB from "../pages/ssb";
import CaseStudies from "../pages/case-studies";
import CreateCaseStudy, {
  action as createCaseStudyAction,
} from "../pages/case-studies/CreateCaseStudy";
import CaseStudyList from "../pages/case-studies/CaseStudyList";
import EditCaseStudy, {
  action as editCaseStudyAction,
} from "../pages/case-studies/EditCaseStudy";
import Industries from "../pages/ssb/industries";
import CreateIndustry, {
  action as createIndustryAction,
} from "../pages/ssb/industries/CreateIndustry";
import CreateSSBCaseStudies, {
  action as CreateSSBCaseStudiesAction,
} from "../pages/ssb/case-studies/CreateSSBCaseStudies";
import SSBCaseStudyList from "../pages/ssb/case-studies/SSBCaseStudyList";
import EhsServices from "../pages/ehs/services";
import CreateEhsServices from "../pages/ehs/services/CreateEhsServices";
import Certificates from "../pages/certificates";
import CreateTemplate, {
  action as createTemplateAction,
} from "../pages/certificates/CreateTemplate";
import CertificateTemplateList from "../pages/certificates/CertificateTemplateList";
import CreateCertificate, {
  action as createCertificateAction,
} from "../pages/certificates/CreateCertificate";
import CertificateList, {
  action as certificateAction,
} from "../pages/certificates/CertificateList";
import EditCertificate, {
  action as editCertificateAction,
} from "../pages/certificates/EditCertificate";
import HealthCare from "../pages/health-care";
import HealthCareLead from "../pages/health-care/HealthCareLead";
import HealthCareExitIntent from "../pages/health-care/HealthCareExitIntent";
import EditTemplate, {
  action as editCertificateTemplateAction,
} from "../pages/certificates/EditTemplate";

const routes = createBrowserRouter([
  {
    path: "/",
    element: <RootLayout />,
    errorElement: <Error />,
    loader: CheckAuthLoader,
    children: [
      {
        index: true,
        element: <Home />,
        id: "home",
        loader: homeLoader,
      },
      {
        path: "lists",
        children: [
          {
            path: ":type",
            element: <Lists />,
            loader: listLoader,
            children: [
              {
                path: ":id",
                element: <ListItem />,
              },
            ],
          },
        ],
      },
      {
        path: "team-management",
        id: "team-management",
        loader: teamManagementLoader,
        children: [
          {
            index: true,
            element: <TeamManagement />,
          },
          {
            path: "create-user",
            element: <CreateUser />,
            action: createUserAction,
          },
          {
            path: "users",
            element: <HeaderLayout title="Team Management" type="Edit User" />,
            children: [
              {
                index: true,
                element: <UserList />,
                action: userAction,
              },
              {
                path: ":id/edit",
                element: <EditUser />,
                loader: editUserLoader,
                action: editUserAction,
              },
            ],
          },
        ],
      },
      {
        path: "personal-information",
        element: <PersonalInformation />,
        loader: personalInfoLoader,
      },
      {
        path: "post-job",
        children: [
          {
            index: true,
            element: <JobPost />,
            loader: jobPostLoader,
          },
          {
            path: "create",
            element: <CreateJob />,
            loader: postJobLoader,
            action: createJobAction,
          },
          {
            path: "list",
            id: "list-loader",
            loader: jobListLoader,
            children: [
              {
                index: true,
                element: <JobList />,
              },
              {
                path: ":id",
                element: <EditJob />,
              },
            ],
          },
        ],
      },
      {
        path: "events",
        children: [
          {
            index: true,
            element: <Events />,
          },
          {
            path: "create",
            element: <CreateEvent />,
            action: createEventAction,
          },
          {
            path: "list",
            element: <EventList />,
            loader: eventsLoader,
          },
          {
            path: ":id",
            children: [
              {
                index: true,
                element: <EventItem />,
              },
              {
                path: "edit",
                element: <EditEvent />,
                loader: editEventLoader,
              },
            ],
          },
        ],
      },
      {
        path: "ehs",
        children: [
          {
            index: true,
            element: <EHS />,
          },
          {
            path: "case-studies",
            children: [
              {
                index: true,
                element: <CaseStudies />,
              },
              {
                path: "create",
                element: <CreateCaseStudy />,
                loader: createCaseStudyLoader,
                action: createCaseStudyAction,
              },
              {
                path: "list",
                element: <CaseStudyList />,
                loader: caseStudyListLoader,
              },
              {
                path: ":id",
                children: [
                  {
                    path: "edit",
                    element: <EditCaseStudy />,
                    loader: editCaseStudyLoader,
                    action: editCaseStudyAction,
                  },
                ],
              },
            ],
          },
          {
            path: "services",
            children: [
              {
                index: true,
                element: <EhsServices />,
              },
              {
                path: "create",
                element: <CreateEhsServices />,
                loader: ehsCreateServicesLoader,
              },
            ],
          },
        ],
      },
      {
        path: "ssb",
        children: [
          {
            index: true,
            element: <SSB />,
          },
          {
            path: "industries",
            children: [
              {
                index: true,
                element: <Industries />,
              },
              {
                path: "create",
                element: <CreateIndustry />,
                action: createIndustryAction,
              },
            ],
          },
          {
            path: "case-studies",
            children: [
              {
                index: true,
                element: <CaseStudies website="SSB" />,
              },
              {
                path: "create",
                element: <CreateSSBCaseStudies />,
                loader: createSSBCaseStudyLoader,
                action: CreateSSBCaseStudiesAction,
              },
              {
                path: "list",
                element: <SSBCaseStudyList />,
                loader: ssbCaseStudyListLoader,
              },
              {
                path: ":id",
                children: [
                  {
                    path: "edit",
                    element: <SSBCaseStudyList />,
                    loader: ssbCaseStudyListLoader,
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: "certificates",
        children: [
          {
            index: true,
            element: <Certificates />,
          },
          {
            path: "create",
            element: <CreateCertificate />,
            loader: createCertificateLoader,
            action: createCertificateAction,
          },
          {
            path: "view",
            element: <CertificateList />,
            loader: certificatesLoader,
            action: certificateAction,
          },
          {
            path: ":id",
            children: [
              {
                path: "edit",
                element: <EditCertificate />,
                loader: editCertificateLoader,
                action: editCertificateAction,
              },
            ],
          },
          {
            path: "create-template",
            element: <CreateTemplate />,
            action: createTemplateAction,
          },
          {
            path: "view-templates",
            element: <CertificateTemplateList />,
            loader: certificateTemplatesLoader,
          },
          {
            path: ":id",
            children: [
              {
                path: "edit-template",
                element: <EditTemplate />,
                loader: editCertificateTemplatesLoader,
                action: editCertificateTemplateAction,
              },
            ],
          },
        ],
      },
      {
        path: "health-care",
        children: [
          {
            index: true,
            element: <HealthCare />,
          },
          {
            path: "leads",
            element: <HealthCareLead />,
            loader: healthcareLeadsLoader,
          },
          {
            path: "exit-intent",
            element: <HealthCareExitIntent />,
            loader: healthcareGuidesLoader,
          },
        ],
      },
      {
        path: "logout",
        action: logoutAction,
      },
    ],
  },
  {
    path: "/auth",
    element: <AuthLayout />,
    errorElement: <Error />,
    // loader: CheckAuthLoader,
    children: [
      {
        path: "login",
        element: <Login />,
        action: loginAction,
      },
      {
        path: "forgot-password",
        element: <ForgotPassword />,
        action: ForgotPasswordAction,
      },
      {
        path: "verify-otp",
        element: <VerifyOtp />,
        action: VerifyOtpAction,
      },
      {
        path: "change-password",
        element: <ChangePassword />,
        action: ChangePasswordAction,
      },
    ],
  },
]);

export default routes;
