import React from "react";
import { Box, IconButton } from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import Toast from "../../utils/Toast";

const DownloadButton = ({ fileUrl, fileName }) => {
  const handleDownload = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(fileUrl);
      if (!response.ok) {
        throw new Error("File does not exist. Try again");
      }

      const file = await response.blob();
      const url = window.URL.createObjectURL(file);
      const link = document.createElement("a");
      link.href = url;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      Toast.fire({ icon: "error", title: error.message });
      //   console.log(error.message);
    }
  };

  return (
    <Box
      component={"a"}
      sx={{
        typography: "textMdReg",
        display: "block",
        gap: 3,
        px: 2,
        bgcolor: "grey.grey100",
        py: 1,
        borderRadius: 1,
        color: "grey.grey600",
        "&:hover": { color: "grey.grey800" },
        maxWidth: "max-content",
      }}
      //   href={`${process.env.REACT_APP_IDS_BACKEND_URL}applications/${key}/${docs}`}
      href={fileUrl}
      target="_blank"
      download={fileName}
      onClick={handleDownload}
    >
      {fileName}

      <IconButton
        sx={{
          ml: 1,
          "&:hover": {
            bgcolor: "grey.grey300",
          },
        }}
        onClick={handleDownload}
        // download={`${process.env.REACT_APP_IDS_BACKEND_URL}applications/${key}/${docs}`}
      >
        {" "}
        <FileDownloadIcon />{" "}
      </IconButton>
    </Box>
  );
};

export default DownloadButton;
