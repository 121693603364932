import { IconButton, Stack, SwipeableDrawer, Typography } from "@mui/material";
import React from "react";
import MailIcon from "@mui/icons-material/Mail";
import CallIcon from "@mui/icons-material/Call";
import PersonPinCircleIcon from "@mui/icons-material/PersonPinCircle";
import classes from "../../assets/css/Modal.module.css";
import { Close } from "@mui/icons-material";
import New from "../Modals/application/hr-status-modal/New";
import PersonalInfo from "../Modals/application/PersonalInfo";
import ContactInfo from "../Modals/contact/ContactInfo";
import ReferralInfo from "../Modals/application/ReferralInfo";

const Modal = (props) => {
  const data = props.data.row;

  const renderModal = (value) => {
    switch (value) {
      case "New":
        return <New />;
      case "Shortlisted":
        return <div>Hello World </div>;
      default:
        return;
    }
  };

  let currentModal;

  if (props.data.field === "full_name" && props.type === "applications") {
    currentModal = <PersonalInfo data={data} />;
  }

  if (props.data.field === "referral_number" && props.type === "applications") {
    currentModal = <ReferralInfo data={data} />;
  }

  if (props.data.field === "stage") {
    currentModal = renderModal(props.data.value);
  }

  if (props.data.field === "full_name" && props.type === "contacts") {
    currentModal = <ContactInfo data={data} />;
  }

  return (
    <SwipeableDrawer
      PaperProps={{
        sx: {
          // backgroundColor: "primary.main",
          width: "83.5%",
          display: "flex",
          borderTopLeftRadius: 20,
          pb: 2,
          flexDirection: "row",
        },
      }}
      open={props.isModal}
      onOpen={() => props.setIsModal(true)}
      onClose={() => props.setIsModal(false)}
      anchor="right"
    >
      <Stack
        className={classes.scrollable}
        spacing={1.5}
        sx={{
          backgroundColor: "white",
          color: "primary.primary900",
          width: "100%",
          overflowY: "scroll",
        }}
      >
        <Stack
          p={2}
          px={10}
          position={"sticky"}
          zIndex={1}
          bgcolor={"grey.grey100"}
          top={0}
        >
          <Stack
            display={"flex"}
            flexDirection={"row"}
            justifyContent={"space-between"}
          >
            <Typography sx={{ typography: "displaySmSemi", mb: 1 }}>
              {data.name || `${data.first_name} ${data.last_name}`}
            </Typography>
            <IconButton
              onClick={() => {
                props.setIsModal(false);
              }}
              sx={{
                mb: 2,
                backgroundColor: "error.error700",
                maxWidth: "max-content",
                "&:hover": { bgcolor: "error.error900" },
              }}
            >
              <Close sx={{ color: "error.error50" }} />
            </IconButton>
          </Stack>

          <Typography
            sx={{
              typography: "textMdSemi",
              display: "flex",
              flexDirection: "row",
            }}
          >
            <CallIcon sx={{ mr: 1 }} /> {data.phone || data.number}
          </Typography>
          {data.current_location || data.country ? (
            <Typography
              sx={{
                typography: "textMdSemi",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <PersonPinCircleIcon sx={{ mr: 1 }} />{" "}
              {data.current_location || data.country}
            </Typography>
          ) : null}
          <Typography
            sx={{
              typography: "textMdSemi",
              display: "flex",
              flexDirection: "row",
            }}
          >
            <MailIcon sx={{ mr: 1 }} /> {data.email}
          </Typography>
          {/* <Divider /> */}
        </Stack>
        {/* Diff Modals depending upon the status value or field value */}
        {currentModal}
      </Stack>
    </SwipeableDrawer>
  );
};

export default Modal;
