import { Stack, Typography } from "@mui/material";
import React from "react";
import {
  AccessTimeOutlined,
  ArrowOutward,
  LocationOnOutlined,
  WorkHistoryOutlined,
} from "@mui/icons-material";
import classes from "../../assets/css/EventCard.module.css";

const EventCard = ({
  image,
  title,
  subtitle,
  location,
  startTime,
  endTime,
}) => {
  return (
    <Stack
      width={"100%"}
      my={2}
      className="ease-transition"
      p={3}
      sx={[
        {
          background: `url(${process.env.REACT_APP_IDS_BACKEND_URL}images/events/banners/${image})`,
          cursor: "pointer",
          position: "relative",
          backgroundSize: "cover",
          backgroundPosition: "center",
          "&::before": {
            content: '""',
            position: "absolute",
            top: 0,
            right: 0,
            backdropFilter: "blur(3px)",
            bottom: 0,
            borderRadius: "1rem",
            left: 0,
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          },
          borderRadius: "1rem",
          border: "1px solid #EAECF0",
        },
        {
          "&:hover": {
            scale: "1.009",

            border: "1px solid var(--gray-200,#0086C9)",
            boxShadow: "0px 10px 28px -12px rgba(16, 24, 40, 0.18)",
          },
        },
      ]}
    >
      <Stack color={"white"} sx={{ backdropFilter: "brightness(100%)" }}>
        <Stack
          display={"flex"}
          justifyContent={"space-between"}
          direction={"row"}
          color={"white"}
          mb={1}
        >
          <Typography
            mb={2}
            maxWidth={"sm"}
            sx={{ typography: { xs: "textLgSemi", sm: "displayMdSemi" } }}
          >
            {title}
          </Typography>
          <Typography
            sx={{
              typography: { xs: "textMdSemi" },
              color: "primary.blue700",
            }}
            display={{ xs: "none", sm: "flex" }}
            justifyContent={"center"}
          >
            Edit <ArrowOutward sx={{ mx: "2px", pb: "5px" }} />
          </Typography>
        </Stack>

        <Typography
          color={"primary.gray600"}
          maxWidth="sm"
          sx={{ typography: { xs: "textMdLeg", sm: "textLgReg" } }}
          className={classes.description}
        >
          {subtitle}
        </Typography>

        <Stack
          direction={"row"}
          display={"flex"}
          justifyContent={"space-between"}
        >
          <Stack
            display={"flex"}
            mt={2}
            alignItems={"center"}
            justifyContent={"center"}
            spacing={2}
            direction="row"
          >
            <Typography
              sx={{
                typography: { xs: "textXsReg", sm: "textMdReg" },
                color: "primary.gray600",
              }}
              flexDirection={{ xs: "column", md: "row" }}
              display={"flex"}
              textAlign={"center"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <LocationOnOutlined
                sx={{
                  mx: { xs: "4px", md: "initial" },
                  p: "2px",
                  width: { xs: "15px", md: "20px" },
                  height: { xs: "15px", md: "20px" },
                }}
              />{" "}
              {location}
            </Typography>
            <Typography
              sx={{
                typography: { xs: "textXsReg", sm: "textMdReg" },
                color: "primary.gray600",
              }}
              flexDirection={{ xs: "column", md: "row" }}
              display={"flex"}
              textAlign={"center"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <AccessTimeOutlined
                sx={{
                  mx: "4px",
                  p: "2px",
                  width: { xs: "15px", md: "20px" },
                  height: { xs: "15px", md: "20px" },
                }}
              />
              {startTime}
            </Typography>
            <Typography
              sx={{
                typography: { xs: "textXsReg", sm: "textMdReg" },
                color: "primary.gray600",
              }}
              flexDirection={{ xs: "column", md: "row" }}
              display={"flex"}
              textAlign={"center"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <WorkHistoryOutlined
                sx={{
                  mx: "4px",
                  p: "2px",
                  width: { xs: "15px", md: "20px" },
                  height: { xs: "15px", md: "20px" },
                }}
              />
              {endTime}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default EventCard;
