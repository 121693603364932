import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import React from "react";

function Preview({ title, open, setPreviewOpen, fileSrc }) {
  return (
    <>
      <Button
        sx={{
          bgcolor: "primary.primary600",
          color: "white",
          mr: 2,
          ":hover": { color: "black" },
        }}
        onClick={() => setPreviewOpen(true)}
      >
        Preview
      </Button>
      <Dialog
        fullWidth={true}
        maxWidth="md"
        open={open}
        onClose={() => setPreviewOpen(false)}
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <Box
            component={"img"}
            width="100%"
            height="100%"
            sx={{
              objectFit: "contain",
              objectPosition: "center",
            }}
            src={fileSrc}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setPreviewOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default Preview;
