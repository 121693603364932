import React from "react";
import TextField from "@mui/material/TextField";
import { Stack } from "@mui/material";

const TextFiled = (props) => {
  return (
    <>
      <Stack>
        <TextField
          error={props.isError}
          color={props.isColor}
          focused={props.isFocused}
          id={props.id}
          type={props.type}
          name={props.name}
          value={props.value}
          width={"100%"}
          label={props.label}
          padding={6}
          mb={props.margin}
          onChange={props.handleChange}
          onBlur={props.handleBlur}
          fullWidth
          variant="outlined"
          autoComplete="off"
          // InputProps={{
          //   sx: {
          //     padding: "4px",
          //     "&.Mui-focused": {
          //       backgroundColor: "primary.white",
          //       "& .MuiOutlinedInput-notchedOutline": {
          //         border: "1px solid",
          //         borderColor: "primary.primary700",
          //       },
          //       "& .MuiFilledInput-root": {
          //         color: "red", // change this to the desired label color
          //       },
          //     },
          //   },
          // }}
        />
        {/* <Typography pt={2}>helo</Typography> */}
      </Stack>
    </>
  );
};

export default TextFiled;
