import {
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { CreateUserSchema, EditUserSchema } from "../../schema";
import { useFormik } from "formik";
import {
  useActionData,
  useLocation,
  useNavigate,
  useSubmit,
} from "react-router-dom";
import Toast from "../../utils/Toast";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const initialValues = {
  first_name: "",
  last_name: "",
  email: "",
  role_id: "",
  department_id: "",
  password: "",
  permission_id: [],
};

const Form = (props) => {
  const [newPassword, setNewPassword] = useState(false);
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const submit = useSubmit();
  const actionData = useActionData();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const checkedRef = useRef();
  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    resetForm,
  } = useFormik({
    initialValues: props.user || initialValues,
    validationSchema: props.edit ? EditUserSchema : CreateUserSchema,
    // ? ****************************** Submit form inside the formik **************************** */
    onSubmit: async (values) => {
      submit(values, { method: props.method });
    },
  });

  // ? ************************************************************** Add the checkbox value ************************************************************** */
  const handleCheckboxChange = (e) => {
    const { checked, value } = e.target;

    if (checked) {
      return values.permission_id.push(value.toString());
    }

    return (values.permission_id = values.permission_id.filter(
      (id) => id !== value.toString()
    ));
  };

  useEffect(() => {
    if (actionData?.status) {
      Toast.fire({ icon: "success", title: actionData.message });
      resetForm();
      checkedRef.current.checked = false;
      navigate(pathname);
    }
  }, [actionData, navigate, resetForm, pathname]);

  return (
    <form onSubmit={handleSubmit}>
      <Stack display={"flex"} my={4} flexDirection={"row"}>
        <Stack flex={0.5} mr={4}>
          <Typography sx={{ typography: "textSmSemi" }}>
            Invite team members
          </Typography>
          <Typography sx={{ typography: "textSmReg" }}>
            Get your projects up and running faster by inviting your team to
            collaborate.
          </Typography>
        </Stack>

        <Stack flex={1.5} spacing={2}>
          <TextField
            fullWidth
            size="small"
            type="test"
            label="First Name"
            name="first_name"
            sx={{
              width: "100%",
              ".MuiFormHelperText-root": {
                typography: "textSmReg",
                color: "#d3302f",
              },
            }}
            value={values.first_name}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.first_name && touched.first_name}
            helperText={touched.first_name && errors.first_name}
            placeholder="Name"
          />
          <TextField
            fullWidth
            size="small"
            type="test"
            label="Last Name"
            name="last_name"
            sx={{
              width: "100%",
              ".MuiFormHelperText-root": {
                typography: "textSmReg",
                color: "#d3302f",
              },
            }}
            value={values.last_name}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder="Name"
          />
          <TextField
            fullWidth
            size="small"
            type="email"
            label="Email"
            name="email"
            sx={{
              width: "100%",
              ".MuiFormHelperText-root": {
                typography: "textSmReg",
                color: "#d3302f",
              },
            }}
            value={values.email}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.email && touched.email}
            helperText={touched.email && errors.email}
            placeholder="mail@idsil.com"
          />
          <TextField
            fullWidth
            select
            size="small"
            placeholder="Role"
            name="role_id"
            sx={{
              width: "100%",
              ".MuiFormHelperText-root": {
                typography: "textSmReg",
                color: "#d3302f",
              },
            }}
            value={values.role_id}
            onChange={(e) => {
              console.log(e.target.value);
              handleChange(e);
              if (e.target.value === 1) {
                return (values.permission_id = ["24"]);
              }
              values.permission_id = [];
            }}
            onBlur={handleBlur}
            error={errors.role_id && touched.role_id}
            helperText={touched.role_id && errors.role_id}
            label="Select Role"
          >
            <MenuItem value="" disabled>
              Select Role
            </MenuItem>

            {props.roles.map((role) => (
              <MenuItem key={role.id} value={role.id}>
                {role.type}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            fullWidth
            select
            size="small"
            placeholder="Department"
            name="department_id"
            sx={{
              width: "100%",
              ".MuiFormHelperText-root": {
                typography: "textSmReg",
                color: "#d3302f",
              },
            }}
            value={values.department_id}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.department_id && touched.department_id}
            helperText={touched.department_id && errors.department_id}
            label="Select Department"
          >
            <MenuItem value="" disabled selected>
              Select department
            </MenuItem>
            {props.departments.map((department) => (
              <MenuItem key={department.id} value={department.id}>
                {department.name}
              </MenuItem>
            ))}
          </TextField>

          {props.edit ? (
            <>
              <Button
                sx={[
                  {
                    maxWidth: "fit-content",
                    mx: { md: 1 },
                    color: "white",
                    bgcolor: "primary.primary600",
                    display: "flex",
                    fontWeight: "600",
                    padding: "12px 20px",
                    lineHeight: "24px",
                    typography: "textSmReg",
                  },
                  {
                    "&:hover": {
                      bgcolor: "primary.primary700",
                    },
                  },
                ]}
                onClick={() => setNewPassword(true)}
              >
                Set new Password
              </Button>
              {newPassword && (
                <TextField
                  fullWidth
                  name="password"
                  sx={{
                    width: "100%",
                    ".MuiFormHelperText-root": {
                      typography: "textSmReg",
                      color: "#d3302f",
                    },
                  }}
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.password && touched.password}
                  helperText={touched.password && errors.password}
                  size="small"
                  label="Password"
                  type={showPassword ? "text" : "password"}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  placeholder="create password"
                />
              )}
            </>
          ) : (
            <TextField
              fullWidth
              name="password"
              sx={{
                width: "100%",
                ".MuiFormHelperText-root": {
                  typography: "textSmReg",
                  color: "#d3302f",
                },
              }}
              value={values.password}
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.password && touched.password}
              helperText={touched.password && errors.password}
              size="small"
              label="Password"
              type={showPassword ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              placeholder="create password"
            />
          )}
        </Stack>
      </Stack>
      <Divider />
      <Stack my={4}>
        <Stack my={2}>
          <Typography sx={{ typography: "displayXsSemi" }}>
            User rights
          </Typography>
          <Typography color={"grey.grey600"} maxWidth={"40%"} mb={2}>
            We may still send you important notifications about your account
            outside of your notification settings.
          </Typography>
        </Stack>

        {props.permissionTypes.map((permissionType) => {
          return (
            <Stack key={permissionType.id}>
              <Stack
                display={"flex"}
                my={2}
                flexDirection={"column"}
                key={permissionType.id}
              >
                <Stack my={2} mr={6}>
                  <Typography sx={{ typography: "textLgSemi" }}>
                    {permissionType.name} List
                  </Typography>
                  <Typography color={"grey.grey600"} mb={2}>
                    {permissionType.description}
                  </Typography>
                </Stack>

                <Stack>
                  <FormGroup>
                    <Grid container>
                      {permissionType.permissions.map((permission) => {
                        return (
                          <Grid item xs={6} key={permission.id}>
                            <FormControlLabel
                              sx={{ maxWidth: "fit-content" }}
                              control={
                                <Checkbox
                                  name={permission.name}
                                  value={permission.id}
                                  onChange={handleCheckboxChange}
                                  onBlur={handleBlur}
                                  sx={{
                                    "&.Mui-checked": {
                                      color: "primary.primary600",
                                    },
                                  }}
                                  ref={checkedRef}
                                  defaultChecked={
                                    values.permission_id.includes(
                                      permission.id.toString()
                                    ) || values.permission_id.includes("24")
                                  }
                                />
                              }
                              label={permission.name}
                            />
                          </Grid>
                        );
                      })}
                    </Grid>
                  </FormGroup>
                </Stack>
              </Stack>
              <Divider />
            </Stack>
          );
        })}
      </Stack>
      <Stack
        display="flex"
        flexDirection={"row"}
        mb={2}
        justifyContent="flex-end"
      >
        <Button
          type="submit"
          onClick={() => {
            const err = Object.keys(errors);
            if (err.length) {
              const input = document.querySelector(`input[name=${err[0]}]`);
              return input.scrollIntoView({
                behavior: "smooth",
                block: "center",
                inline: "start",
              });
            }
          }}
          sx={[
            {
              maxWidth: "fit-content",
              mx: { md: 1 },
              color: "white",
              bgcolor: "primary.primary600",
              fontSize: "16px",
              display: "flex",
              fontWeight: "600",
              padding: "12px 20px",
              lineHeight: "24px",
            },
            {
              "&:hover": {
                bgcolor: "primary.primary700",
              },
            },
          ]}
        >
          {props.buttonName}
        </Button>
      </Stack>
    </form>
  );
};

export default Form;
