import { Button, Stack, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import TextEditor from "../../TextEditor";
import { useActionData, useSubmit } from "react-router-dom";
import { useFormik } from "formik";
import { industrySchema } from "../../../schema";
import Toast from "../../../utils/Toast";

const INITIAL_VALUES = {
  title: "",
  subtitle: "",
  overview: "",
  points: [
    {
      point: "",
    },
  ],
  icon: "test",
  image: "test",
};

const CreateIndustryForm = ({ industry = {}, isEdit = false }) => {
  const [initialValues, setInitialValues] = useState(INITIAL_VALUES);
  const submit = useSubmit();
  const actionData = useActionData();

  // ? *********************************************************** Form Submit handler *********************************************************** */
  const onFormSubmitHandler = (e) => {
    e.preventDefault();
    handleSubmit(e);
  };

  // ? *********************************************************** Use of Formik *********************************************************** */
  const {
    values,
    errors,
    touched,
    setFieldValue,
    setFieldTouched,
    handleBlur,
    handleChange,
    handleSubmit,
    resetForm,
  } = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: industrySchema,
    onSubmit: (values) => {
      values.points = values.points.map((point) => point.point);
      submit(values, { method: "POST" });
    },
  });

  const onAddPointsHandler = () => {
    setInitialValues(() => ({
      ...values,
      points: [...values.points, { point: "" }],
    }));
  };

  useEffect(() => {
    if (actionData?.status) {
      resetForm();
      Toast.fire({ icon: "success", title: actionData.message });
      return;
    }

    if (actionData?.status === false) {
      Toast.fire({ icon: "error", title: actionData.message });
      return;
    }
  }, [actionData, resetForm]);

  return (
    <form onSubmit={onFormSubmitHandler}>
      <Stack spacing={4}>
        <TextField
          sx={{
            width: "100%",
            ".MuiFormHelperText-root": {
              typography: "textSmReg",
              color: "#d3302f",
            },
          }}
          color="secondary"
          size="medium"
          name="title"
          variant="outlined"
          label="Title *"
          onBlur={handleBlur}
          error={errors.title && Boolean(errors.title) && touched.title}
          helperText={touched.title && errors.title}
          value={values.title}
          onChange={handleChange}
        />
        {/* Support Text */}
        <TextField
          sx={{
            width: "100%",
            ".MuiFormHelperText-root": {
              typography: "textSmReg",
              color: "#d3302f",
            },
          }}
          color="secondary"
          size="medium"
          name="subtitle"
          variant="outlined"
          label="Sub Title"
          onBlur={handleBlur}
          error={
            errors.subtitle && Boolean(errors.subtitle) && touched.subtitle
          }
          helperText={touched.subtitle && errors.subtitle}
          value={values.subtitle}
          onChange={handleChange}
        />

        <TextEditor
          name="overview"
          title="Overview"
          setFieldValue={setFieldValue}
          errors={errors.overview}
          touched={touched.overview}
          setFieldTouched={setFieldTouched}
          // isEdit={isEdit}
          // savedHtml={isEdit && industry.overview}
        />

        <Typography variant="h6">Points</Typography>
        <Stack display={"flex"} flexDirection={"row"}>
          <Button
            onClick={() => onAddPointsHandler()}
            sx={{
              bgcolor: "primary.primary600",
              color: "white",
              mr: 2,
              ":hover": { color: "black" },
            }}
          >
            ADD
          </Button>
        </Stack>

        {values.points.map((point, index) => (
          <TextField
            key={index}
            sx={{
              width: "100%",
              ".MuiFormHelperText-root": {
                typography: "textSmReg",
                color: "#d3302f",
              },
            }}
            color="secondary"
            size="medium"
            name={`points[${index}].point`}
            variant="outlined"
            label="Points"
            onBlur={handleBlur}
            value={values.points[index].point}
            onChange={handleChange}
          />
        ))}

        <Button variant="contained" color="primary" type="submit">
          Submit
        </Button>
      </Stack>
    </form>
  );
};

export default CreateIndustryForm;
