import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
} from "@mui/material";
import React from "react";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
};

const MultiSelect = ({ label, name, values, onChange, selectedValues }) => {
  return (
    <FormControl fullWidth sx={{ mt: 5, mx: 2 }}>
      <InputLabel id="demo-multiple-checkbox-label">{label}</InputLabel>
      <Select
        labelId="demo-multiple-checkbox-label"
        id="demo-multiple-checkbox"
        multiple
        value={selectedValues}
        onChange={(e) => {
          onChange(e, name.toLowerCase().replace(" ", "_"));
        }}
        input={<OutlinedInput label={name} />}
        name={name}
        renderValue={(selected) =>
          values
            .filter((value) => selected.includes(value.id))
            .map((item) => item.title)
            .join(",")
        }
        MenuProps={MenuProps}
        sx={{ display: "flex", flexDirection: "row", flexWrap: "nowrap" }}
      >
        {values.map((value) => (
          <MenuItem key={value.id} value={value.id}>
            <Checkbox checked={selectedValues.indexOf(value.id) > -1} />
            <ListItemText primary={value.title} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default MultiSelect;
