import { redirect } from "react-router-dom";

// ? ************************************ Get the token time duration ************************************ */
export const getTokenDuration = () => {
  const storedExpirationDate = localStorage.getItem("expiration");
  const expirationDate = new Date(storedExpirationDate);
  const now = new Date();
  const duration = expirationDate.getTime() - now.getTime();
  return duration;
};

// ? ************************************ Getting token and check time duration ************************************ */
export const getAuthToken = () => {
  const token = localStorage.getItem("token");

  const tokenDuration = getTokenDuration();

  if (tokenDuration < 0) {
    return "EXPIRED";
  }

  return token;
};

// ? ************************************ GEt the authenticated token ************************************ */
export const tokenLoader = () => {
  return getAuthToken();
};

// ? ************************************ Check if the user is authenticated ************************************ */
const CheckAuthLoader = () => {
  const token = getAuthToken();
  if (token === null || token === "EXPIRED") {
    localStorage.removeItem("token");
    localStorage.removeItem("expiration");
    localStorage.removeItem("userInfo");
    return redirect("/auth/login");
  }

  return token;
};

export default CheckAuthLoader;
