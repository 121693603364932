import React from "react";
import { Grid, Stack } from "@mui/material";
import { Link } from "react-router-dom";
import HeaderLayout from "../../components/layouts/HeaderLayout";
import caseStudyCards from "../../store/ehs/caseStudyCards";
import Dashboard from "../../components/cards/Dashboard";

const CaseStudies = (props) => {
  return (
    <>
      <HeaderLayout title="Case Studies" type="Manage Case Studies" />
      <Stack display={"flex"} bgcolor={"white"}>
        <Stack
          display={"flex"}
          justifyContent={"center"}
          spacing={5}
          alignItems={"center"}
        ></Stack>
        <Stack display={"flex"} my={10}>
          <Grid
            maxWidth={"xl"}
            container
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              columnGap: 5,
              rowGap: 5,
            }}
          >
            {caseStudyCards.map((card, index) => {
              return (
                <Grid item md={5} key={index}>
                  <Link
                    to={card.link}
                    style={{ textDecoration: "none", color: "black" }}
                    key={index}
                  >
                    <Dashboard
                      title={card.title}
                      icon={card.icon}
                      helperText={card.helperText}
                    />
                  </Link>
                </Grid>
              );
            })}
          </Grid>
        </Stack>
      </Stack>
    </>
  );
};

export default CaseStudies;
