import { Grid, Stack, Typography } from "@mui/material";
import React, { Suspense } from "react";
import { Await, Link, useLoaderData } from "react-router-dom";
import Dashboard from "../../components/cards/Dashboard";

function JobPost() {
  const user = JSON.parse(localStorage.getItem("user"));
  const { cards } = useLoaderData();

  return (
    <>
      <Stack my={2}>
        <Typography sx={{ typography: "displaySmSemi" }}>
          Welcome, {user?.first_name}
        </Typography>
        <Typography color={"grey.grey600"}>
          Seamlessly track and manage all the data in one place.
        </Typography>
      </Stack>
      <Stack
        display={"flex"}
        // justifyContent={"center"}
        // alignItems={"center"}
        bgcolor={"white"}
      >
        <Stack
          display={"flex"}
          justifyContent={"center"}
          spacing={5}
          alignItems={"center"}
        ></Stack>
        <Stack
          display={"flex"}
          my={10}
          // justifyContent={"center"}
          // alignItems={"center"}
        >
          <Grid
            maxWidth={"xl"}
            container
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              columnGap: 5,
              rowGap: 5,
            }}
          >
            <Suspense fallback={<p>Loading...</p>}>
              <Await resolve={cards}>
                {(loadedCards) =>
                  loadedCards.map((card, index) => (
                    <Grid item md={cards.length <= 1 ? 12 : 5} key={index}>
                      <Link
                        to={`/post-job/${card.link}`}
                        style={{
                          textDecoration: "none",
                          color: "black",
                          width: "max-content",
                        }}
                        key={index}
                      >
                        <Dashboard
                          title={card.name}
                          icon={card.icon}
                          helperText={card.description}
                        />
                      </Link>
                    </Grid>
                  ))
                }
              </Await>
            </Suspense>
          </Grid>
        </Stack>
      </Stack>
    </>
  );
}

export default JobPost;
