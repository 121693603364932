import {
  Button,
  Divider,
  FormControl,
  Grid,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import MessageIcon from "@mui/icons-material/Message";
import Toast from "../../../utils/Toast";
import useApi from "../../../hooks/use-api";
import { Form, redirect } from "react-router-dom";

function ContactInfo(props) {
  const [value, setValue] = useState(props.data.inquiry_id);
  const [contact, setContact] = useState({});
  const [comment, setComment] = useState("");
  const [department, setDepartment] = useState("");
  const [inquiries, setInquiries] = useState([]);
  const [subInquiries, setSubInquiries] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [subInquiry, setSubInquiry] = useState("");
  const [isInquiry, setIsInquiry] = useState(false);
  const [commentTemp, setCommentTemp] = useState("");
  const userRole = JSON.parse(localStorage.getItem("user")).role;

  // ? ****************************************************** Get the contact info ****************************************************** */
  const currentContactInfo = async (id) => {
    try {
      const contact = await useApi.getRequest(
        "ids",
        `contacts/${id}`,
        true,
        "REACT_APP_CONTACTS_API_KEY"
      );
      if (contact.status) {
        if (contact.data.contact_inquiries.length > 0) {
          setComment(contact.data.contact_inquiries?.[0].comment);
          setCommentTemp(contact.data.contact_inquiries?.[0].comment);
          setDepartment(contact.data.contact_inquiries?.[0].department_id);
          setSubInquiry(contact.data.contact_inquiries?.[0].sub_inquiry_id);
        }
        return setContact(contact.data);
      }

      throw new Error(contact.message);
    } catch (error) {
      console.log(error.message);
    }
  };

  // ? ****************************************************** Get the contact inquiries ****************************************************** */
  const contactInquiries = useCallback(async (id) => {
    try {
      const contactInquiry = await useApi.getRequest(
        "ids",
        `contact-inquiries/${id}/contact_us_id`,
        true,
        "REACT_APP_CONTACT_US_INQUIRY_TYPE_API_KEY"
      );

      if (contactInquiry.status) {
        setIsInquiry(true);
        setValue(contactInquiry.data[0].inquiry_id);
        allSubInquiries(contactInquiry.data[0].inquiry_id);
        setSubInquiry(contactInquiry.data[0].sub_inquiry_id);
        setComment(contactInquiry.data[0].comment);
        setCommentTemp(contactInquiry.data[0].comment);
        return setDepartment(contactInquiry.data[0].department_id);
      }

      throw new Error(contactInquiry.message);
    } catch (error) {
      console.log(error.message);
    }
  }, []);

  // ? ****************************************************** Get the inquiries ****************************************************** */
  const allInquiries = async () => {
    try {
      const allInquiries = await useApi.getRequest(
        "ids",
        "inquiries",
        true,
        "REACT_APP_INQUIRIES_API_KEY"
      );
      if (allInquiries.status) {
        return setInquiries(allInquiries.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  // ? ****************************************************** Get the Sub inquiries ****************************************************** */
  const allSubInquiries = async (id) => {
    try {
      const allInquiries = await useApi.getRequest(
        "ids",
        `sub-inquiries/${id}`,
        true,
        "REACT_APP_SUB_INQUIRY_API_KEY"
      );
      if (allInquiries.status) {
        return setSubInquiries(allInquiries.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  // ? ****************************************************** Get the Departments ****************************************************** */
  const allDepartments = async () => {
    try {
      const departments = await useApi.getRequest(
        "ids",
        "departments",
        true,
        "REACT_APP_DEPARTMENT_API_KEY"
      );
      if (departments.status) {
        return setDepartments(departments.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  // ? ****************************************************** Save the data ****************************************************** */
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const formData = {
        inquiry_id: value,
        contact_us_id: props.data.id,
        comment: comment,
        department_id: department,
        sub_inquiry_id: subInquiry,
      };
      const saveInquiryData = await useApi.postRequest(
        "ids",
        "contact-inquiries",
        true,
        formData,
        "REACT_APP_CONTACT_US_INQUIRY_TYPE_API_KEY"
      );
      if (saveInquiryData.status) {
        setComment("");
        currentContactInfo(contact.id);
        Toast.fire({
          icon: "success",
          title: saveInquiryData.message,
        });
        return redirect("/lists/contacts");
      }

      throw new Error(saveInquiryData.message);
    } catch (error) {
      Toast.fire({ icon: "error", title: error.message });
    }
  };

  useEffect(() => {
    currentContactInfo(props.data.id);
    allInquiries();
    allDepartments();
    contactInquiries(props.data.id);

    if (props.data.inquiry_id !== null) {
      setIsInquiry(true);
      allSubInquiries(props.data.inquiry_id);
    }
  }, [props, contactInquiries]);

  return (
    <>
      {props.data.message ? (
        <Stack px={10} py={2}>
          <Typography
            sx={{
              typography: "textLgSemi",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <MessageIcon sx={{ mr: 1 }} />
            Visitor Inquiry
          </Typography>
          <Typography
            sx={{
              typography: "textSmReg",
              display: "flex",
              flexDirection: "row",
              maxWidth: "md",
              px: 2,
            }}
          >
            “ {props.data.message} ”
          </Typography>
          <Divider sx={{ py: 3 }} />
        </Stack>
      ) : null}

      <Stack direction={"flex"} justifyContent={"center"} alignItems={"center"}>
        <Grid
          container
          md={12}
          p={2}
          px={10}
          gap={2}
          display={"flex"}
          // alignItems={"center"}
          flexDirection={"row"}
        >
          {Object.keys(contact)
            .filter((key, index) => contact[key])
            ?.map((key, index) => {
              return (
                <>
                  {key !== "inquiry_id" &&
                    key !== "country_id" &&
                    key !== "is_open" &&
                    key !== "job_id" &&
                    key !== "contact_inquiries" &&
                    key !== "stage_id" && (
                      <>
                        <Grid item md={3} key={index}>
                          <Typography
                            pr={2}
                            sx={{
                              color: "grey.grey900",
                              typography: "textMdReg",
                              display: "flex",
                              flexDirection: "row",
                              textTransform: "capitalize",
                            }}
                          >
                            {key.replace("_", " ")}{" "}
                          </Typography>
                        </Grid>
                        <Grid item md={6}>
                          {key !== "inquiry_id" &&
                            key !== "documents" &&
                            key !== "contact_inquiries" &&
                            key !== "certifications" && (
                              <TextField
                                multiline
                                fullWidth
                                InputProps={{ readOnly: true }}
                                size="small"
                                sx={{
                                  typography: "textMdReg",
                                  display: "flex",
                                  flexDirection: "row",
                                }}
                                value={
                                  key === "stage"
                                    ? contact[key].name
                                    : key === "job"
                                    ? contact[key].title
                                    : key === "inquiry"
                                    ? contact[key].name
                                    : contact[key]
                                }
                              ></TextField>
                            )}
                        </Grid>
                      </>
                    )}
                </>
              );
            })}
        </Grid>
      </Stack>

      {(userRole === "Admin" || userRole === "Sales") && (
        <Stack px={10} py={2}>
          <Divider sx={{ py: 3 }} />
          <Grid
            container
            md={12}
            py={6}
            gap={2}
            display={"flex"}
            alignItems={"center"}
            flexDirection={"row"}
          >
            <Grid item md={3}>
              <Typography
                pr={2}
                pb={6}
                sx={{
                  color: "grey.grey900",
                  typography: "textMdReg",
                  display: "flex",
                  flexDirection: "row",
                  textTransform: "capitalize",
                }}
              >
                Inquiry Type
              </Typography>
            </Grid>
            <Grid item md={6}>
              <Form action="" method="POST" onSubmit={handleSubmit}>
                <FormControl
                  component={"form"}
                  sx={[
                    {
                      my: 2,
                      minWidth: "100%",
                      "&.MuiPFormControl-root": {
                        backgroundColor: "primary.main",
                      },
                    },
                  ]}
                  elevation={0}
                >
                  <TextField
                    select
                    sx={{ my: 2 }}
                    size="small"
                    id="demo-simple-select"
                    value={value}
                    onChange={(event) => {
                      setValue(event.target.value);
                      setIsInquiry(event.target.value);
                      allSubInquiries(event.target.value);
                    }}
                  >
                    {inquiries.map((inquiry) => {
                      return (
                        <MenuItem id={inquiry.name} value={inquiry.id}>
                          {inquiry.name}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                  {isInquiry && (
                    <TextField
                      select
                      size="small"
                      id="demo-simple-select"
                      value={subInquiry}
                      onChange={(event) => {
                        setSubInquiry(event.target.value);
                      }}
                    >
                      {subInquiries.map((SubInquiry) => {
                        return (
                          <MenuItem id={SubInquiry.name} value={SubInquiry.id}>
                            {SubInquiry.name}
                          </MenuItem>
                        );
                      })}
                    </TextField>
                  )}

                  <TextField
                    select
                    sx={{ my: 2 }}
                    size="small"
                    id="demo-simple-select"
                    value={department}
                    onChange={(event) => {
                      setDepartment(event.target.value);
                    }}
                  >
                    {departments.map((department) => {
                      return (
                        <MenuItem id={department.name} value={department.id}>
                          {department.name}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                </FormControl>
                <TextField
                  multiline
                  fullWidth
                  value={comment}
                  onChange={(e) => {
                    setComment(e.target.value);
                  }}
                  rows={4}
                  size="small"
                  sx={{
                    typography: "textMdReg",
                    mt: 1,
                    display: "flex",
                    flexDirection: "row",
                  }}
                  label="Comments *"
                  placeholder="Please add comment here"
                />
                <Button
                  size="small"
                  disabled={
                    subInquiry && value && comment && commentTemp !== comment
                      ? false
                      : true
                  }
                  type="submit"
                  sx={[
                    {
                      my: 2,
                      color: "white",
                      bgcolor: "primary.primary600",
                      fontSize: "16px",
                      fontWeight: "600",
                      padding: "8px 16px",
                      lineHeight: "24px",
                      ":disabled": {
                        bgcolor: "grey.grey300",
                        border: "1px solid",
                        borderColor: "grey.grey400",
                      },
                    },

                    {
                      "&:hover": {
                        bgcolor: "primary.primary700",
                      },
                    },
                  ]}
                >
                  Submit
                </Button>
              </Form>
            </Grid>
          </Grid>
        </Stack>
      )}
    </>
  );
}

export default ContactInfo;
