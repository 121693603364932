import { useFormik } from "formik";
import React, { useEffect } from "react";
import { useActionData, useLoaderData, useSubmit } from "react-router-dom";
import { certificateSchema } from "../../schema";
import Toast from "../../utils/Toast";
import HeaderLayout from "../../components/layouts/HeaderLayout";
import { Button, Divider, MenuItem, Stack, TextField } from "@mui/material";
import useApi from "../../hooks/use-api";
// import UploadFile from "../../components/File-upload";

const EditCertificate = () => {
  const { certificate, certificateTemplates } = useLoaderData();
  const submit = useSubmit();
  const actionData = useActionData();
  const INITIAL_VALUES = {
    employee_name: certificate.employee_name,
    employee_code: certificate.employee_code,
    employee_email: certificate.employee_email,
    certificate_template_id: certificate.certificate_template_id,
    year: certificate.year,
    reason: certificate.reason,
  };

  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    // setFieldTouched,
    // setFieldValue,
    errors,
    touched,
    resetForm,
  } = useFormik({
    enableReinitialize: true,
    initialValues: INITIAL_VALUES,
    validationSchema: certificateSchema,
    onSubmit: (values) => {
      submit(values, { method: "POST" });
    },
  });

  useEffect(() => {
    if (actionData?.status) {
      resetForm();
      Toast.fire({ icon: "success", title: actionData?.message });
    }
  }, [actionData, resetForm]);

  return (
    <>
      <HeaderLayout title="Certificates" type="Edit Certificate" />
      <Divider />
      <Stack
        maxWidth={"sm"}
        my={5}
        mx={"auto"}
        sx={{ display: "flex", justifyContent: "center" }}
      >
        {/* <h1>Add Bulk Entry</h1>
        <UploadFile
          title="Employees Data"
          errorMessage={errors.file}
          accept=".xls, .csv"
          errorStatus={
            errors.file !== undefined && touched.file !== undefined
              ? true
              : false
          }
          name={"certificate"}
          folderPath="certificates/employees"
          setFieldValue={setFieldValue}
          handleBlur={handleBlur}
          setFieldTouched={setFieldTouched}
          values={{ file: values.file }}
        /> */}

        {/* <Divider sx={{ mt: 4 }} /> */}

        <h1>Edit the Certificate</h1>

        <form onSubmit={handleSubmit}>
          <Stack spacing={4} mb={4}>
            <TextField
              sx={{
                width: "100%",
                ".MuiFormHelperText-root": {
                  typography: "textSmReg",
                  color: "#d3302f",
                },
              }}
              color="secondary"
              size="medium"
              name="employee_name"
              variant="outlined"
              label="Employee Name *"
              onBlur={handleBlur}
              error={
                errors.employee_name &&
                Boolean(errors.employee_name) &&
                touched.employee_name
              }
              helperText={touched.employee_name && errors.employee_name}
              value={values.employee_name}
              onChange={handleChange}
            />
            <TextField
              sx={{
                width: "100%",
                ".MuiFormHelperText-root": {
                  typography: "textSmReg",
                  color: "#d3302f",
                },
              }}
              color="secondary"
              size="medium"
              name="employee_code"
              variant="outlined"
              label="Employee Code *"
              onBlur={handleBlur}
              error={
                errors.employee_code &&
                Boolean(errors.employee_code) &&
                touched.employee_code
              }
              helperText={touched.employee_code && errors.employee_code}
              value={values.employee_code}
              onChange={handleChange}
            />
            <TextField
              sx={{
                width: "100%",
                ".MuiFormHelperText-root": {
                  typography: "textSmReg",
                  color: "#d3302f",
                },
              }}
              color="secondary"
              size="medium"
              name="employee_email"
              variant="outlined"
              label="Employee Email *"
              onBlur={handleBlur}
              error={
                errors.employee_email &&
                Boolean(errors.employee_email) &&
                touched.employee_email
              }
              helperText={touched.employee_email && errors.employee_email}
              value={values.employee_email}
              onChange={handleChange}
            />
            <TextField
              select
              sx={{
                width: "100%",
                ".MuiFormHelperText-root": {
                  typography: "textSmReg",
                  color: "#d3302f",
                },
              }}
              color="secondary"
              size="medium"
              name="certificate_template_id"
              variant="outlined"
              label="Certificate Type *"
              onBlur={handleBlur}
              error={
                errors.certificate_template_id &&
                Boolean(errors.certificate_template_id) &&
                touched.certificate_template_id
              }
              helperText={
                touched.certificate_template_id &&
                errors.certificate_template_id
              }
              value={values.certificate_template_id}
              onChange={handleChange}
            >
              <MenuItem value="" disabled>
                Select type
              </MenuItem>
              {certificateTemplates.map((certificateTemplate, index) => (
                <MenuItem key={index} value={certificateTemplate.id}>
                  {certificateTemplate.name}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              sx={{
                width: "100%",
                ".MuiFormHelperText-root": {
                  typography: "textSmReg",
                  color: "#d3302f",
                },
              }}
              color="secondary"
              size="medium"
              name="year"
              variant="outlined"
              label="Year (2024) *"
              onBlur={handleBlur}
              error={errors.year && Boolean(errors.year) && touched.year}
              helperText={touched.year && errors.year}
              value={values.year}
              onChange={handleChange}
            />
            <TextField
              sx={{
                width: "100%",
                ".MuiFormHelperText-root": {
                  typography: "textSmReg",
                  color: "#d3302f",
                },
              }}
              color="secondary"
              size="medium"
              name="reason"
              variant="outlined"
              label="Comments *"
              onBlur={handleBlur}
              error={errors.reason && Boolean(errors.reason) && touched.reason}
              helperText={touched.reason && errors.reason}
              value={values.reason}
              onChange={handleChange}
            />
            <Button variant="contained" color="primary" type="submit">
              Submit
            </Button>
          </Stack>
        </form>
      </Stack>
    </>
  );
};

export default EditCertificate;

export const action = async ({ request, params }) => {
  try {
    const id = params.id;
    const data = await request.formData();
    const formData = Object.fromEntries(data);
    const result = await useApi.updateRequest(
      "ids",
      `certificates/${id}`,
      true,
      formData
    );

    if (result.status) {
      return result;
    }

    throw new Error(result.message);
  } catch (error) {
    return Toast.fire({ icon: "error", title: error.message });
  }
};
