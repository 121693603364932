const status = (stage) => {
  switch (stage) {
    case "New":
      return {
        ":hover": { borderColor: "#026AA2", border: "1px solid" },
        bgcolor: "#E0F2FE",
        color: "#026AA2",
      };
    case "Shortlisted":
      return {
        ":hover": { borderColor: "#DC6803", border: "1px solid" },
        bgcolor: "#FEF0C7",
        color: "#DC6803",
      };
    case "Offer Accepted":
      return {
        ":hover": { borderColor: "#12B76A", border: "1px solid" },
        bgcolor: "#12B76A",
        color: "#FFFFFF",
      };
    case "Test":
      return {
        ":hover": { borderColor: "#9F1AB1", border: "1px solid" },
        bgcolor: "#FBE8FF",
        color: "#9F1AB1",
      };
    case "Rejected":
      return {
        ":hover": { borderColor: "#F04438", border: "1px solid" },
        bgcolor: "#F04438",
        color: "#FFFFFF",
      };
    case "Test Result":
      return {
        ":hover": { borderColor: "#A15C07", border: "1px solid" },
        bgcolor: "#FEF7C3",
        color: "#A15C07",
      };
    case "Interview":
      return {
        ":hover": { borderColor: "#5925DC", border: "1px solid" },
        bgcolor: "#EBE9FE",
        color: "#5925DC",
      };
    case "Final Result":
      return {
        ":hover": { borderColor: "#B93815", border: "1px solid" },
        bgcolor: "#FFE6D5",
        color: "#B93815",
      };
    case "Offer Initiated":
      return {
        ":hover": { borderColor: "#107569", border: "1px solid" },
        bgcolor: "#CCFBEF",
        color: "#107569",
      };
    default:
      break;
  }
};

export default status;
