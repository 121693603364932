// ? ******************************************************* Job types ******************************************************* */
export const jobTypes = [
  "Full-Time",
  "Full-time (Rotational Shifts)",
  "Full-time (Night Shift Only)",
  "Part-Time",
  "Intern",
];

// ? ******************************************************* Work modes ******************************************************* */
export const workModes = [
  "On-site",
  "On-site (Mohali)",
  "On-site (Mohali and Noida)",
  "On-site (Mohali)/Hybrid/Remote",
  "On-site (Mohali and Noida) & Remote",
  "Remote",
  "Hybrid",
];

// ? ******************************************************* Experiences ******************************************************* */
export const experiences = [
  "0-06 Months",
  "0-01 Years",
  "01-03 Years",
  "03-05 Years",
  "05-08 Years",
  "08-10 Years",
  "10-15 Years",
  "15+Years",
  "20+Years",
];
