import {
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { jobCreateSchema } from "../../schema";
import useApi from "../../hooks/use-api";
import {
  useActionData,
  useLoaderData,
  useLocation,
  useNavigate,
  useSubmit,
} from "react-router-dom";
import Toast from "../../utils/Toast";
import { experiences, jobTypes, workModes } from "../../store/PostJob";
import TextEditor from "../../components/PostJob/TextEditor";
import { EditorState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import HeaderLayout from "../../components/layouts/HeaderLayout";

const initialValues = {
  job_id: "",
  title: "",
  type: "",
  experience: "",
  workMode: "",
  jobRawTextSummary: "",
  jobSummary: "",
  keyResponsibility: "",
  keyRawTextResponsibility: "",
  requirements: "",
  rawTextRequirements: "",
  preferredQualification: "",
  rawTextPreferredQualification: "",
  endNote: "",
  isReviewed: false,
};

// const initialEditorValue = {
//   editorState: EditorState.createEmpty(),
//   focus: false,
//   name: "",
// };

const initialEditorTestValue = {
  jobSummary: {
    editorState: EditorState.createEmpty(),
    focus: false,
  },
  keyResponsibility: {
    editorState: EditorState.createEmpty(),
    focus: false,
  },
  requirements: {
    editorState: EditorState.createEmpty(),
    focus: false,
  },
  preferredQualification: {
    editorState: EditorState.createEmpty(),
    focus: false,
  },
};

const CreateJob = () => {
  const userData = JSON.parse(localStorage.getItem("user"));
  const { jobs } = useLoaderData();
  const submit = useSubmit();
  const actionData = useActionData();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [editorState, setEditorState] = useState(initialEditorTestValue);

  const {
    values,
    errors,
    touched,
    setFieldValue,
    handleBlur,
    handleChange,
    handleSubmit,
    resetForm,
  } = useFormik({
    initialValues,
    validationSchema: jobCreateSchema,
    onSubmit: (values) => {
      return submit(values, { method: "POST" });
    },
  });

  const onEditorStateChange = (
    currentEditorState,
    rawTextName,
    htmlTextName
  ) => {
    setEditorState((prevState) => ({
      ...prevState,
      editorState: currentEditorState,
    }));
    const rawText = convertToRaw(editorState.editorState.getCurrentContent());
    // this.setFieldValue(this.name, rawText.blocks[0].text);
    const htmlText = draftToHtml(
      convertToRaw(editorState.editorState.getCurrentContent())
    );
    if (rawTextName === "jobRawTextSummary") {
      setFieldValue(
        "description",
        convertToRaw(editorState.editorState.getCurrentContent()).blocks[0].text
      );
    }
    setFieldValue(rawTextName, rawText.blocks[0].text);
    setFieldValue(htmlTextName, htmlText);
  };

  useEffect(() => {
    if (actionData?.status) {
      resetForm();
      setEditorState(initialEditorTestValue);
      Toast.fire({ icon: "success", title: actionData.message });
      return navigate(pathname);
    }
  }, [actionData, resetForm, navigate, pathname]);

  return (
    <>
      <HeaderLayout title="Post a Job" type="Create Job" />
      <Divider />
      <Stack
        maxWidth={"sm"}
        my={10}
        mx={"auto"}
        sx={{ display: "flex", justifyContent: "center" }}
      >
        <form onSubmit={handleSubmit}>
          <Stack spacing={4}>
            <TextField
              select
              sx={{
                width: "100%",
                ".MuiFormHelperText-root": {
                  typography: "textSmReg",
                  color: "#d3302f",
                },
              }}
              color="secondary"
              size="medium"
              name="job_id"
              variant="outlined"
              label="Job Category *"
              onBlur={handleBlur}
              error={errors.job_id && Boolean(errors.job_id) && touched.job_id}
              helperText={touched.job_id && errors.job_id}
              value={values.job_id}
              onChange={handleChange}
            >
              <MenuItem value="" disabled selected>
                Select category
              </MenuItem>
              {jobs.map((job) => (
                <MenuItem key={job.id} value={job.id}>
                  {job.title}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              sx={{
                width: "100%",
                ".MuiFormHelperText-root": {
                  typography: "textSmReg",
                  color: "#d3302f",
                },
              }}
              color="secondary"
              size="medium"
              name="title"
              variant="outlined"
              label="Job Title *"
              onBlur={handleBlur}
              error={errors.title && Boolean(errors.title) && touched.title}
              helperText={touched.title && errors.title}
              value={values.title}
              onChange={handleChange}
            />
            <TextField
              select
              sx={{
                width: "100%",
                ".MuiFormHelperText-root": {
                  typography: "textSmReg",
                  color: "#d3302f",
                },
              }}
              color="secondary"
              size="medium"
              name="type"
              variant="outlined"
              label="Job Type *"
              onBlur={handleBlur}
              error={errors.type && Boolean(errors.type) && touched.type}
              helperText={touched.type && errors.type}
              value={values.type}
              onChange={handleChange}
            >
              <MenuItem value="" disabled selected>
                Select type
              </MenuItem>
              {jobTypes.map((data, index) => (
                <MenuItem key={index} value={data}>
                  {data}
                </MenuItem>
              ))}
            </TextField>

            <TextField
              select
              sx={{
                width: "100%",
                ".MuiFormHelperText-root": {
                  typography: "textSmReg",
                  color: "#d3302f",
                },
              }}
              color="secondary"
              size="medium"
              name="experience"
              variant="outlined"
              label="Experience *"
              onBlur={handleBlur}
              error={
                errors.experience &&
                Boolean(errors.experience) &&
                touched.experience
              }
              helperText={touched.experience && errors.experience}
              value={values.experience}
              onChange={handleChange}
            >
              <MenuItem value="" disabled selected>
                Select mode
              </MenuItem>
              {experiences.map((data, index) => (
                <MenuItem key={index} value={data}>
                  {data}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              select
              sx={{
                width: "100%",
                ".MuiFormHelperText-root": {
                  typography: "textSmReg",
                  color: "#d3302f",
                },
              }}
              color="secondary"
              size="medium"
              name="workMode"
              variant="outlined"
              label="Work Mode *"
              onBlur={handleBlur}
              error={
                errors.workMode && Boolean(errors.workMode) && touched.workMode
              }
              helperText={touched.workMode && errors.workMode}
              value={values.workMode}
              onChange={handleChange}
            >
              <MenuItem value="" disabled selected>
                Select mode
              </MenuItem>
              {workModes.map((data, index) => (
                <MenuItem key={index} value={data}>
                  {data}
                </MenuItem>
              ))}
            </TextField>
            <Stack>
              <Typography
                sx={{ typography: "textMdReg", mb: 1, color: "grey.grey600" }}
              >
                Job Summary *
              </Typography>
              <TextEditor
                editorState={editorState}
                setEditorState={setEditorState}
                onEditorStateChange={onEditorStateChange}
                value={values.jobRawTextSummary}
                rawTextName="jobRawTextSummary"
                htmlTextName="jobSummary"
                handleBlur={handleBlur}
                handleChange={handleChange}
                setFieldValue={setFieldValue}
                error={errors.jobRawTextSummary}
                touched={touched.jobRawTextSummary}
              />
            </Stack>
            <Stack>
              <Typography
                sx={{ typography: "textMdReg", mb: 1, color: "grey.grey600" }}
              >
                Key Responsibilities *
              </Typography>
              <TextEditor
                editorState={editorState}
                setEditorState={setEditorState}
                value={values.keyRawTextResponsibility}
                rawTextName="keyRawTextResponsibility"
                htmlTextName="keyResponsibility"
                handleBlur={handleBlur}
                handleChange={handleChange}
                setFieldValue={setFieldValue}
                error={errors.keyRawTextResponsibility}
                touched={touched.keyRawTextResponsibility}
                onEditorStateChange={onEditorStateChange}
              />
            </Stack>
            <Stack>
              <Typography
                sx={{ typography: "textMdReg", mb: 1, color: "grey.grey600" }}
              >
                Requirements *
              </Typography>
              <TextEditor
                editorState={editorState}
                setEditorState={setEditorState}
                value={values.rawTextRequirements}
                rawTextName="rawTextRequirements"
                htmlTextName="requirements"
                handleBlur={handleBlur}
                handleChange={handleChange}
                setFieldValue={setFieldValue}
                error={errors.rawTextRequirements}
                touched={touched.rawTextRequirements}
              />
            </Stack>
            <Stack>
              <Typography
                sx={{ typography: "textMdReg", mb: 1, color: "grey.grey600" }}
              >
                Preferred Qualifications *
              </Typography>
              <TextEditor
                editorState={editorState}
                setEditorState={setEditorState}
                value={values.rawTextPreferredQualification}
                rawTextName="rawTextPreferredQualification"
                htmlTextName="preferredQualification"
                handleBlur={handleBlur}
                handleChange={handleChange}
                setFieldValue={setFieldValue}
                error={errors.rawTextPreferredQualification}
                touched={touched.rawTextPreferredQualification}
              />
            </Stack>
            <Stack>
              <Typography
                sx={{ typography: "textMdReg", mb: 1, color: "grey.grey600" }}
              >
                End Note
              </Typography>
              <TextField
                sx={{
                  width: "100%",
                  ".MuiFormHelperText-root": {
                    typography: "textSmReg",
                    color: "#d3302f",
                  },
                }}
                name="endNote"
                onBlur={handleBlur}
                error={
                  errors.endNote && Boolean(errors.endNote) && touched.endNote
                }
                helperText={touched.endNote && errors.endNote}
                value={values.endNote}
                onChange={handleChange}
                color="secondary"
                size="medium"
                multiline
              />
            </Stack>
          </Stack>
          {userData?.role === "Admin" && (
            <Stack>
              <Stack
                display={"flex"}
                direction="row"
                my={2}
                alignItems={"center"}
                spacing={0}
              >
                <FormControlLabel
                  width={"min-content"}
                  control={
                    <Checkbox
                      name="isReviewed"
                      value={values.isReviewed}
                      checked={values.isReviewed === true ? true : false}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      sx={{
                        width: "min-content",
                        mr: -2,
                        "&.Mui-checked": {
                          color: "primary.primary600",
                        },
                      }}
                    />
                  }
                />
                <Typography color="grey.grey700">Admin approved</Typography>
              </Stack>

              {errors.isReviewed && touched.isReviewed ? (
                <p className={"error_msg"}>{errors.isReviewed}</p>
              ) : null}
            </Stack>
          )}
          <Button
            type="submit"
            sx={{
              my: 4,
              bgcolor: "primary.primary600",
              color: "white",
              typography: "textLgSemi",
              px: 2,
              py: 1,
              "&:hover": {
                bgcolor: "primary.primary700",
              },
            }}
          >
            Submit
          </Button>
        </form>
      </Stack>
    </>
  );
};

export default CreateJob;

// ? ********************************************************************* Action ********************************************************************* */
export const action = async ({ request }) => {
  try {
    const data = await request.formData();
    const formData = {
      title: data.get("title"),
      type: data.get("type"),
      work_mode: data.get("workMode"),
      experience: data.get("experience"),
      description: data.get("description"),
      job_summary: data.get("jobSummary"),
      key_responsibilities: data.get("keyResponsibility"),
      requirements: data.get("requirements"),
      preferred_qualifications: data.get("preferredQualification"),
      note: data.get("endNote"),
      job_id: data.get("job_id"),
    };

    const result = await useApi.postRequest("ids", "openings", true, formData, {
      "is-authentication": "true",
    });
    if (result.status) {
      return result;
    }

    throw new Error(result.message);
  } catch (error) {
    return Toast.fire({ icon: "error", title: error.message });
  }
};
