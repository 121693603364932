import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { Button, MenuItem, Stack, TextField, Typography } from "@mui/material";
import {
  DatePicker,
  LocalizationProvider,
  TimePicker,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import TextEditor from "./TextEditor";
import { EditorState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import { eventSchema } from "../../schema";
import UploadFile from "../File-upload";
import HeaderLayout from "../layouts/HeaderLayout";
import { useActionData, useSubmit } from "react-router-dom";
import SpeakerCoordinatorForm from "./SpeakerCoordinatorForm";
import Toast from "../../utils/Toast";

const INITIAL_VALUES = {
  event: {
    title: "",
    subtitle: "",
    image: null,
    date: dayjs(),
    start_time: dayjs().add(30, "minutes"),
    end_time: dayjs().add(60, "minutes"),
    location: "",
    link: "",
    link2: "",
    overview: "",
    email_template: "",
  },
  speakers: [
    {
      name: "",
      designation: "",
      image: null,
      linkedin_profile: "",
      introduction: "",
    },
  ],
  coordinators: [
    {
      name: "",
      designation: "",
      image: null,
      linkedin_profile: "",
      introduction: "",
    },
  ],
};

const initialEditorValues = {
  description: {
    editorState: EditorState.createEmpty(),
    focus: false,
  },
  htmlspeakerIntroduction: {
    editorState: EditorState.createEmpty(),
    focus: false,
  },
  htmlcoordinatorIntroduction: {
    editorState: EditorState.createEmpty(),
    focus: false,
  },
};

function EventForm({ isEdit, data }) {
  const [editorState, setEditorState] = useState(initialEditorValues);
  const [initialValues, setInitialValues] = useState(INITIAL_VALUES);

  const submit = useSubmit();
  const actionData = useActionData();

  // ? *********************************************************** On editor state change *********************************************************** */
  const onEditorStateChange = (
    currentEditorState,
    rawTextName,
    htmlTextName
  ) => {
    setEditorState((prevState) => ({
      ...prevState,
      editorState: currentEditorState,
    }));
    const rawText = convertToRaw(editorState.editorState.getCurrentContent());

    const htmlText = draftToHtml(
      convertToRaw(editorState.editorState.getCurrentContent())
    );
    if (rawTextName === "jobRawTextSummary") {
      setFieldValue(
        "description",
        convertToRaw(editorState.editorState.getCurrentContent()).blocks[0].text
      );
    }
    setFieldValue(rawTextName, rawText.blocks[0].text);
    setFieldValue(htmlTextName, htmlText);
  };

  // const handleCancel = () => {
  //   setPreviewOpen(false);
  // };
  // ? *********************************************************** Add speaker and coordinator fields *********************************************************** */
  const onAddFieldsHandler = (type) => {
    setInitialValues(() => ({
      ...values,
      [type]: [
        ...values[type],
        {
          name: "",
          designation: "",
          image: "",
          linkedin_profile: "",
          introduction: "",
        },
      ],
    }));
  };

  // ? *********************************************************** Remove speaker and coordinator fields *********************************************************** */
  const onRemoveFieldsHandler = (type, fieldIndex) => {
    const restValues = values[type].filter(
      (value, index) => index !== fieldIndex
    );
    setInitialValues((prevValues) => ({
      ...prevValues,
      [type]: [...restValues],
    }));
  };

  // ? *********************************************************** Form Submit handler *********************************************************** */
  const onFormSubmitHandler = (e) => {
    e.preventDefault();
    handleSubmit(e);
  };

  // ? *********************************************************** Use of Formik *********************************************************** */
  const {
    values,
    errors,
    touched,
    setFieldValue,
    setFieldTouched,
    handleBlur,
    handleChange,
    handleSubmit,
    resetForm,
  } = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: eventSchema,
    onSubmit: (values) => {
      const formData = new FormData();
      formData.append("event", JSON.stringify(values.event));
      formData.append("speakers", JSON.stringify(values.speakers));
      formData.append("coordinators", JSON.stringify(values.coordinators));
      submit(formData, { method: "POST" });
    },
  });

  useEffect(() => {
    if (isEdit) {
      setInitialValues({
        event: {
          title: data.title,
          subtitle: data.subtitle,
          image: data.image,
          date: dayjs(data.date),
          start_time: dayjs(data.start_time),
          end_time: dayjs(data.end_time),
          location: data.location,
          link: data.link,
          link2: data.link2,
          overview: data.overview,
          email_template: data.email_template,
        },
        speakers: data.speakers.map((speaker) => ({
          name: speaker.name,
          designation: speaker.designation,
          image: speaker.image,
          linkedin_profile: speaker.linkedin_profile,
          introduction: speaker.introduction,
        })),
        coordinators: data.coordinators.map((coordinator) => ({
          name: coordinator.name,
          designation: coordinator.designation,
          image: coordinator.image,
          linkedin_profile: coordinator.linkedin_profile,
          introduction: coordinator.introduction,
        })),
      });
    }

    if (actionData?.status) {
      resetForm();
      Toast.fire({ icon: "success", title: actionData.message });
    }

    if (actionData?.status === false) {
      Toast.fire({ icon: "error", title: actionData.message });
    }
  }, [actionData, resetForm, isEdit, data]);

  // ? *********************************************************** Render the page *********************************************************** */
  return (
    <>
      <HeaderLayout title="Event" type="Create Event" />
      <Stack mx={2} display={"flex"} alignItems={"center"}>
        <Stack my={2}>
          <form onSubmit={onFormSubmitHandler}>
            <Stack spacing={4}>
              {/* Banner Image */}
              <UploadFile
                isPreview={true}
                title={"Banner Image"}
                errorMessage={errors.event?.image}
                errorStatus={
                  errors.event?.image !== undefined &&
                  touched.event?.image !== undefined
                    ? true
                    : false
                }
                name={"event.image"}
                handleSubmit={onFormSubmitHandler}
                folderPath="images/events/banners"
                setFieldValue={setFieldValue}
                handleBlur={handleBlur}
                setFieldTouched={setFieldTouched}
                values={{ image: values.event.image }}
              />
              {/* {values.event.image && (
                <>
                  <PreviewBanner onClose={handleCancel} open={previewOpen} />
                  <Button
                    sx={{
                      bgcolor: "primary.primary600",
                      color: "white",
                      mr: 2,
                      ":hover": { color: "black" },
                    }}
                    onClick={() => {
                      setPreviewOpen(true);
                    }}
                  >
                    Preview
                  </Button>
                </>
              )} */}

              {/* Title */}
              <TextField
                sx={{
                  width: "100%",
                  ".MuiFormHelperText-root": {
                    typography: "textSmReg",
                    color: "#d3302f",
                  },
                }}
                color="secondary"
                size="medium"
                name="event.title"
                variant="outlined"
                label="Title *"
                onBlur={handleBlur}
                error={
                  errors.event?.title &&
                  Boolean(errors.event?.title) &&
                  touched.event?.title
                }
                helperText={touched.event?.title && errors.event?.title}
                value={values.event.title}
                onChange={handleChange}
              />
              {/* Support Text */}
              <TextField
                sx={{
                  width: "100%",
                  ".MuiFormHelperText-root": {
                    typography: "textSmReg",
                    color: "#d3302f",
                  },
                }}
                color="secondary"
                size="medium"
                name="event.subtitle"
                variant="outlined"
                label="Sub Title *"
                onBlur={handleBlur}
                error={
                  errors.event?.subtitle &&
                  Boolean(errors.event?.subtitle) &&
                  touched.event?.subtitle
                }
                helperText={touched.event?.subtitle && errors.event?.subtitle}
                value={values.event?.subtitle}
                onChange={handleChange}
                multiline
                rows={3}
              />

              {/* <Stack display={"flex"} flexDirection={"row"}> */}
              {/* Date */}
              <Stack display="flex" direction="row">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Date"
                    name="event.date"
                    onChange={(value) =>
                      setFieldValue("event.date", value, true)
                    }
                    value={values.event.date}
                    sx={{
                      mr: 1,
                      width: "100%",
                    }}
                    slotProps={{
                      textField: {
                        onBlur: handleBlur,
                        helperText: touched.event?.date && errors.event?.date,
                        error: Boolean(
                          touched.event?.date && errors.event?.date
                        ),
                        fullWidth: true,
                      },
                      openPickerButton: {
                        color:
                          touched.event?.date && errors.event?.date && "error",
                      },
                    }}
                    disablePast
                  />
                </LocalizationProvider>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <TimePicker
                    label="Start Time"
                    name="event.start_time"
                    onChange={(value) =>
                      setFieldValue("event.start_time", value, true)
                    }
                    value={values.event.start_time}
                    sx={{ mr: 1, width: "100%" }}
                    slotProps={{
                      textField: {
                        onBlur: handleBlur,
                        helperText:
                          touched.event?.start_time && errors.event?.start_time,
                        error: Boolean(
                          touched.event?.start_time && errors.event?.start_time
                        ),
                        fullWidth: true,
                      },
                      openPickerButton: {
                        color:
                          touched.event?.start_time &&
                          errors.event?.start_time &&
                          "error",
                      },
                    }}
                  />
                </LocalizationProvider>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <TimePicker
                    label="End Time"
                    name="event.end_time"
                    onChange={(value) =>
                      setFieldValue("event.end_time", value, true)
                    }
                    value={values.event.end_time}
                    sx={{
                      width: "100%",
                    }}
                    slotProps={{
                      textField: {
                        onBlur: handleBlur,
                        helperText:
                          touched.event?.end_time && errors.event?.end_time,
                        error: Boolean(
                          touched.event?.end_time && errors.event?.end_time
                        ),
                        fullWidth: true,
                      },
                      openPickerButton: {
                        color:
                          touched.event?.end_time &&
                          errors.event?.end_time &&
                          "error",
                      },
                    }}
                  />
                </LocalizationProvider>
              </Stack>

              {/* </Stack> */}
              <Stack display={"flex"} flexDirection={"row"}>
                {/* Event Type */}
                <TextField
                  select
                  sx={{
                    width: "30%",
                    mr: 1,
                    ".MuiFormHelperText-root": {
                      typography: "textSmReg",
                      color: "#d3302f",
                    },
                  }}
                  color="secondary"
                  size="medium"
                  name="event.location"
                  variant="outlined"
                  label="Event Type"
                  onBlur={handleBlur}
                  error={
                    errors.event?.location &&
                    Boolean(errors.event?.location) &&
                    touched.event?.location
                  }
                  helperText={touched.event?.location && errors.event?.location}
                  value={values.event.location}
                  onChange={handleChange}
                >
                  <MenuItem value={"In-person"}>In-person</MenuItem>
                  <MenuItem value={"Virtual"}>Virtual</MenuItem>
                  <MenuItem value={"Hybrid"}>Hybrid</MenuItem>
                </TextField>

                {/* Location Link */}
                <TextField
                  sx={{
                    width: "70%",
                    ".MuiFormHelperText-root": {
                      typography: "textSmReg",
                      color: "#d3302f",
                    },
                  }}
                  type="url"
                  color="secondary"
                  size="medium"
                  name="event.link"
                  variant="outlined"
                  label=" Event location/link *"
                  value={values.event.link}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  error={
                    errors.event?.link &&
                    // Boolean(errors.event?.link) &&
                    touched.event?.link
                  }
                  helperText={touched.event?.link && errors.event?.link}
                />
                {values.event.location === "Hybrid" && (
                  <TextField
                    sx={{
                      ml: 1,
                      width: "70%",
                      ".MuiFormHelperText-root": {
                        typography: "textSmReg",
                        color: "#d3302f",
                      },
                    }}
                    type="url"
                    color="secondary"
                    size="medium"
                    name="event.link2"
                    variant="outlined"
                    label=" Event virtual/link *"
                    onBlur={handleBlur}
                    error={
                      errors.event?.link2 &&
                      Boolean(errors.event?.link2) &&
                      touched.event?.link2
                    }
                    helperText={touched.event?.link2 && errors.event?.link2}
                    value={values.event.link2}
                    onChange={handleChange}
                  />
                )}
              </Stack>
              {/* Event description */}
              <Stack>
                <Typography
                  sx={{ typography: "textMdReg", mb: 1, color: "grey.grey600" }}
                >
                  Event Description *
                </Typography>
                <TextEditor
                  editorState={editorState}
                  setEditorState={setEditorState}
                  value={values.event.overview}
                  rawTextName="event.overview"
                  htmlTextName="description"
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  setFieldValue={setFieldValue}
                  error={errors.event?.overview}
                  touched={touched.event?.overview}
                  onEditorStateChange={onEditorStateChange}
                />
              </Stack>
              {/* Email Template */}
              <TextField
                sx={{
                  width: "100%",
                  ".MuiFormHelperText-root": {
                    typography: "textSmReg",
                    color: "#d3302f",
                  },
                }}
                color="secondary"
                size="medium"
                name="event.email_template"
                variant="outlined"
                label="Email Template *"
                placeholder="I hearby thankyou for registering........"
                onBlur={handleBlur}
                error={
                  errors.event?.email_template &&
                  Boolean(errors.event?.email_template) &&
                  touched.event?.email_template
                }
                helperText={
                  touched.event?.email_template && errors.event?.email_template
                }
                value={values.event.email_template}
                onChange={handleChange}
                multiline
                rows={5}
              />

              {/* Speaker Data */}
              <Typography variant="h4">Speaker Data</Typography>
              <Stack display={"flex"} flexDirection={"row"}>
                <Button
                  onClick={() => onAddFieldsHandler("speakers")}
                  sx={{
                    bgcolor: "primary.primary600",
                    color: "white",
                    mr: 2,
                    ":hover": { color: "black" },
                  }}
                >
                  ADD
                </Button>
              </Stack>

              {values.speakers.map((speaker, index) => (
                <SpeakerCoordinatorForm
                  index={index}
                  key={index}
                  type="Speaker"
                  valuesLength={values.speakers.length}
                  values={values.speakers[index]}
                  errors={errors.speakers?.[index]}
                  touched={touched.speakers?.[index]}
                  handleBlur={handleBlur}
                  setFieldValue={setFieldValue}
                  setFieldTouched={setFieldTouched}
                  handleChange={handleChange}
                  editorState={editorState}
                  setEditorState={setEditorState}
                  onEditorStateChange={onEditorStateChange}
                  onRemoveFieldsHandler={onRemoveFieldsHandler}
                />
              ))}

              {/* Coordinator Data */}
              <Typography variant="h4">Coordinator Data</Typography>
              <Stack display={"flex"} flexDirection={"row"}>
                <Button
                  onClick={() => onAddFieldsHandler("coordinators")}
                  sx={{
                    bgcolor: "primary.primary600",
                    color: "white",
                    mr: 2,
                    ":hover": { color: "black" },
                  }}
                >
                  ADD
                </Button>
              </Stack>
              {values.coordinators.map((coordinator, index) => (
                <SpeakerCoordinatorForm
                  index={index}
                  key={index}
                  type="Coordinator"
                  valuesLength={values.coordinators.length}
                  values={values.coordinators[index]}
                  errors={errors.coordinators?.[index]}
                  touched={touched.coordinators?.[index]}
                  handleBlur={handleBlur}
                  setFieldValue={setFieldValue}
                  setFieldTouched={setFieldTouched}
                  handleChange={handleChange}
                  editorState={editorState}
                  setEditorState={setEditorState}
                  onEditorStateChange={onEditorStateChange}
                  onRemoveFieldsHandler={onRemoveFieldsHandler}
                />
              ))}
              <Button variant="contained" color="primary" type="submit">
                Submit
              </Button>
            </Stack>
          </form>
        </Stack>
      </Stack>
    </>
  );
}

export default EventForm;

EventForm.propTypes = {
  isEdit: false,
  data: null,
};
