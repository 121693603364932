import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import classes from "./DragAndDrop.module.css";
import DragAndDrop from "./DragAndDrop";
import { Typography } from "@mui/material";
import Uploading from "./Uploading";
import Uploaded from "./Uploaded";
import Preview from "./Preview";

const UploadFile = (props) => {
  const inputRef = useRef(null);
  const [previewOpen, setPreviewOpen] = React.useState(false);
  const [isActiveDrag, setIsActiveDrag] = useState(false);
  const [files, setFiles] = useState({
    [props.name]: {
      isUploading: false,
      isUploaded: true,
      data: [],
    },
  });
  const [isError, setIsError] = useState({
    status: props.errorStatus,
    message: props.errorMessage,
  });
  const [percentage, setPercentage] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);

  // ? ************************************************ On drag the file ************************************************ */
  const onDragFileHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      return setIsActiveDrag(true);
    }

    if (e.type === "dragleave") {
      setIsActiveDrag(false);
    }
  };

  // ? ************************************************* On drop the file ************************************************ */
  const onDropFileHandler = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setIsActiveDrag(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      onFileChangeHandler(e.dataTransfer.files);
    }
  };

  // ? ****************************** Size of the uploaded file **************************** */
  const formatSizeUnits = (bytes) => {
    if (bytes >= 1073741824) {
      bytes = (bytes / 1073741824).toFixed(2) + " GB";
    } else if (bytes >= 1048576) {
      bytes = (bytes / 1048576).toFixed(2) + " MB";
    } else if (bytes >= 1024) {
      bytes = (bytes / 1024).toFixed(2) + " KB";
    } else if (bytes > 1) {
      bytes = bytes + " bytes";
    } else if (bytes === 1) {
      bytes = bytes + " byte";
    } else {
      bytes = "0 bytes";
    }
    return bytes;
  };

  // ? ************************************************* On change or upload the file ************************************************ */
  const onFileChangeHandler = async (files) => {
    try {
      setIsError({ status: false, message: "" });
      if (files.length > props.numberOfFiles) {
        inputRef.current.value = "";
        throw new Error(
          `You Cannot upload more than ${props.numberOfFiles} files`
        );
      }
      const formData = new FormData();
      for (let i = 0; i < files.length; i++) {
        formData.append(props.name, files[i]);
      }
      setFiles((prevFiles) => ({
        ...prevFiles,
        [props.name]: {
          isUploading: true,
          isUploaded: false,
          data: [...prevFiles[props.name].data],
        },
      }));
      const options = {
        onUploadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent;
          setPercentage(Math.floor((loaded * 100) / total));
        },
        headers: {
          "x-api-key": props.apiKey,
        },
      };

      const uploadFilesToServer = await axios.post(
        `${props.url}files?folderPath=${props.folderPath}&name=${props.name}&isMultiple=${props.isMultiple}&length=${props.numberOfFiles}`,
        formData,
        options
      );
      inputRef.current.value = "";
      if (!uploadFilesToServer.data.status) {
        throw new Error(uploadFilesToServer.data.message);
      }

      const uploadedAllFiles = uploadFilesToServer.data.data.map(
        (uploadedFiles, index) => {
          return {
            name: uploadedFiles.name,
            file: uploadedFiles.file,
            size: formatSizeUnits(uploadedFiles.size),
          };
        }
      );
      setFiles((prevFiles) => ({
        ...prevFiles,
        [props.name]: {
          isUploading: false,
          isUploaded: true,
          data: prevFiles[props.name].data.concat(uploadedAllFiles),
        },
      }));
      if (props.isExcelUploads) {
        console.log("herer");
        props.setExcelEmployees(uploadFilesToServer.data.data[0].data);
      }
      return props.setFieldValue(
        props.name,
        uploadFilesToServer.data.data[0].file
      );
      // return props.values[props.name].concat(
      //   uploadFilesToServer.data.data.map((uploadedFile) => uploadedFile.file)
      // );
    } catch (error) {
      setIsError({
        status: true,
        message: error.message,
      });
    }
  };

  // ? ************************************************* On delete the uploaded file ************************************************ */
  const onDeleteFileHandler = async (fileName) => {
    try {
      setIsDeleting(true);
      const response = await fetch(
        `${props.url}files/${fileName}?folderPath=${props.folderPath}`,
        {
          method: "DELETE",
          headers: {
            "content-type": "application/json",
            "Strict-Transport-Security": "max-age=31536000; includeSubDomains",
            "Content-Security-Policy": "directive 'source';",
            "X-Frame-Options": "SAMEORIGIN",
            "x-api-key": props.apiKey,
            Authorization: `${
              props.isToken && `Bearer ${localStorage.getItem("token")}`
            }`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Something went wrong. Try again!");
      }

      const deleteFile = await response.json();
      setIsDeleting(false);
      if (!deleteFile.status) {
        throw new Error(deleteFile.message);
      }
      const restFiles = files[props.name].data.filter(
        (deletingFile) => deletingFile.file !== fileName
      );

      setFiles((prevFiles) => ({
        ...prevFiles,
        [props.name]: {
          isUploading: false,
          isUploaded: files[props.name].data.length === 0 ? false : true,
          isError: false,
          errorMsg: "",
          data: restFiles,
        },
      }));
      props.values[props.name] = restFiles;
      return props.setFieldValue(props.name, restFiles);
    } catch (error) {
      setIsError({ status: true, message: error.message });
    }
  };

  useEffect(() => {
    if (props.errorStatus === true) {
      setIsError({ status: true, message: props.errorMessage });
    }
  }, [props.errorMessage, props.errorStatus]);

  return (
    <>
      {files[props.name]?.data.length <= props.numberOfFiles - 1 && (
        <DragAndDrop
          setFieldTouched={props.setFieldTouched}
          setFieldValue={props.setFieldValue}
          title={props.title}
          name={props.name}
          handleBlur={props.handleBlur}
          inputRef={inputRef}
          isActiveDrag={isActiveDrag}
          isError={isError}
          percentage={percentage}
          onDragFileHandler={onDragFileHandler}
          onDropFileHandler={onDropFileHandler}
          onFileChangeHandler={onFileChangeHandler}
          accept={props.accept}
          uploadingFileTypes={props.uploadingFileTypes}
        />
      )}
      {isError.status && (
        <Typography
          sx={{
            color: "#d32f2f",
            fontFamily: "Inter",
            fontStyle: " normal",
            fontWeight: 400,
            fontSize: "14px",
            mx: "14px",
            mt: "3px",
            lineHeight: "20px",
          }}
          className={classes.helperText}
          textAlign={"left"}
        >
          {isError.message}
        </Typography>
      )}
      {files[props.name].data.length > 0 &&
        files[props.name].isUploaded &&
        [...files[props.name].data]
          .reverse()
          .map((file, index) => (
            <Uploaded
              key={index}
              file={file}
              deleteFileName="documents"
              percentage={percentage}
              onDeleteFileHandler={onDeleteFileHandler}
              isDeleting={isDeleting}
            />
          ))}
      {files[props.name].isUploading && <Uploading progress={percentage} />}
      {files[props.name].data.length > 0 && props.isPreview && (
        <Preview
          title={props.title}
          open={previewOpen}
          setPreviewOpen={setPreviewOpen}
          fileSrc={`${props.backendUrl}${props.folderPath}/${
            files[props.name].data[0].file
          }`}
        />
      )}
    </>
  );
};

export default UploadFile;

UploadFile.defaultProps = {
  title: "Title",
  name: "documents",
  errorStatus: false,
  errorMessage: "",
  isToken: true,
  numberOfFiles: 1,
  accept: ".png, .jpg, .jpeg, .webp",
  isMultiple: false,
  isExcelUploads: false,
  values: {
    documents: "",
  },
  isPreview: false,
  uploadingFileTypes: "Picture",
  folderPath: "",
  url: process.env.REACT_APP_IDS_API_URL,
  backendUrl: process.env.REACT_APP_IDS_BACKEND_URL,
  apiKey: process.env.REACT_APP_FILES_IDS_API_KEY,
};
