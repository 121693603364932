import { Box } from "@mui/material";
import React from "react";

const ColumnLinks = (props) => {
  return (
    <>
      <Box
        component={"a"}
        sx={{
          color: "#0086C9",
          textDecoration: "underline",
          display: "block",
          typography: "textSmSemi",
        }}
        href={`${process.env.REACT_APP_IDS_BACKEND_URL}applications/${props.name}/${props.value}`}
        download={true}
        key={props.index}
        target="_blank"
      >
        {props.value}
      </Box>
      {/* <Box component={"div"} sx={{ mx: 0.3 }}>
        {props.index === props.valueLength - 1 ? "" : " , "}
      </Box> */}
    </>
  );
};

export default ColumnLinks;
