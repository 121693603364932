import React from "react";
import Button from "../../components/ui/FormButton";
import AuthStyle from "../../assets/css/Auth.module.css";
import { useFormik } from "formik";
import Divider from "@mui/material/Divider";
import { Stack, Typography } from "@mui/material";
import TextFiled from "../../components/ui/TextField";
import PasswordField from "../../components/ui/PasswordField";
import { redirect, useSubmit } from "react-router-dom";
import { Link } from "react-router-dom";
import { loginSchema } from "../../schema";
import useApi from "../../hooks/use-api";
import Toast from "../../utils/Toast";

const Login = () => {
  const submit = useSubmit();
  const onSubmitApplicationHandler = (event) => {
    event.preventDefault();
    handleSubmit();
  };
  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: {
        email: "",
        password: "",
      },
      validationSchema: loginSchema,
      onSubmit: async (values) => {
        submit(values, { method: "post" });
      },
    });
  return (
    <>
      <Stack className={AuthStyle.form_content}>
        <Stack sx={{ width: { xs: "100%", sm: "400px", md: "380px" } }}>
          <Stack my={2}>
            <Typography
              typography={"displayMdSemi"}
              sx={{ textTransform: "capitalize" }}
            >
              {window.location.pathname.split("/")[1]} Login
            </Typography>
            <Stack py={2}>
              <Typography typography={"textMdSemi"}>Almost there!</Typography>
              <Typography typography={"textLgReg"}>
                Enter your account details to begin
              </Typography>
            </Stack>
            <Divider sx={{ mb: "20px" }} />
          </Stack>
          <form onSubmit={onSubmitApplicationHandler}>
            <Stack>
              <TextFiled
                isError={touched.email && errors.email ? true : false}
                label="Email"
                id="email"
                type="text"
                name="email"
                className={AuthStyle.default}
                isColor={`${touched.email && !errors.email ? "success" : ""}`}
                // isFocused={`${values.email.length > 0 ? true : false}`}
                value={values.email}
                handleBlur={handleBlur}
                handleChange={handleChange}
              />
              <Stack py={"0.5rem"}>
                {errors.email && touched.email ? (
                  <Typography sx={{ color: "red", typography: "textXsReg" }}>
                    {errors.email}
                  </Typography>
                ) : null}
              </Stack>
              <PasswordField
                label="Password"
                isError={touched.password && errors.password ? true : false}
                id="password"
                type="password"
                name="password"
                className={AuthStyle.default}
                isColor={`${
                  touched.password && !errors.password ? "success" : ""
                }`}
                value={values.password}
                handleBlur={handleBlur}
                handleChange={handleChange}
              />
              <Stack py={"0.5rem"}>
                {errors.password && touched.password ? (
                  <Typography sx={{ color: "red", typography: "textXsReg" }}>
                    {errors.password}
                  </Typography>
                ) : null}
              </Stack>
              <Link
                to="/auth/forgot-password"
                sx={{ p: "0px", width: "10px", textDecoration: "none" }}
                style={{ textDecoration: "none", cursor: "pointer" }}
              >
                <Typography sx={{ color: "black", pb: "2rem", cursor: "" }}>
                  Forgot password?
                </Typography>
              </Link>
              <Button title="Login" BtnVariant="contained" type="Submit" />
            </Stack>
          </form>
        </Stack>
      </Stack>
    </>
  );
};
export default Login;
export const action = async ({ request }) => {
  try {
    const data = await request.formData();
    const formData = {
      email: data.get("email"),
      password: data.get("password"),
    };
    const result = await useApi.postRequest(
      "ids",
      "auth/login",
      false,
      formData
    );
    if (!result.status) {
      throw new Error(result.message);
    }
    const token = result.data.token;
    const user = result.data.allUserData.user;
    const expiration = new Date();
    expiration.setHours(expiration.getHours() + 24);
    localStorage.setItem("expiration", expiration.toISOString());
    localStorage.setItem("token", token);
    localStorage.setItem("user", JSON.stringify(user));
    if (user.type === "MEDICAL_SCRIBE" || user.type === "HR") {
      return redirect("/lists/applications");
    }
    return redirect("/");
  } catch (error) {
    return Toast.fire({ icon: "error", title: error.message });
  }
};
