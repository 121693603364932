import React, { Suspense } from "react";
import {
  Await,
  defer,
  useLoaderData,
  useRouteLoaderData,
} from "react-router-dom";
import Toast from "../../utils/Toast";
import useApi from "../../hooks/use-api";
import Form from "./Form";
import { Skeleton } from "@mui/material";

const EditUser = () => {
  const { user } = useLoaderData();
  const { roles, departments, permissionTypes } =
    useRouteLoaderData("team-management");

  return (
    <Suspense fallback={<Skeleton variant="rounded" height={500} />}>
      <Await
        resolve={Promise.all([user, roles, departments, permissionTypes]).then(
          (values) => values
        )}
      >
        {(values) => {
          const [user, roles, departments, permissionTypes] = values;
          return (
            <Form
              user={user}
              roles={roles}
              departments={departments}
              permissionTypes={permissionTypes}
              edit={true}
              buttonName="Edit User"
              method="PATCH"
            />
          );
        }}
      </Await>
    </Suspense>
  );
};

export default EditUser;

// ? ****************************************************** load user data ****************************************************** */
const loadUser = async (id) => {
  try {
    const result = await useApi.getRequest("ids", `users/${id}`, true);
    if (result.status) {
      return {
        first_name: result.data.first_name,
        last_name: result.data.last_name,
        email: result.data.email,
        role_id: result.data.role_id,
        department_id: result.data.department_id,
        permission_id: result.data.permission_id.map(String),
        password: "",
      };
    }

    throw new Error(result.message);
  } catch (error) {
    Toast.fire({ icon: "error", title: error.message });
  }
};

export const loader = ({ params }) => {
  const id = params.id;
  return defer({
    user: loadUser(id),
  });
};

// ? ****************************************************** Edit the user data ****************************************************** */
export const action = async ({ request, params }) => {
  try {
    const id = params.id;
    const data = await request.formData();
    const formData = {
      first_name: data.get("first_name"),
      last_name: data.get("last_name"),
      email: data.get("email"),
      role_id: data.get("role_id"),
      department_id: data.get("department_id"),
      password: data.get("password") === undefined ? "" : data.get("password"),
      permission_id: data.get("permission_id"),
    };

    const result = await useApi.updateRequest(
      "ids",
      `users/${id}`,
      true,
      formData
    );

    if (result.status) {
      return result;
    }

    throw new Error(result.message);
  } catch (error) {
    Toast.fire({ icon: "error", title: error.message });
  }
};
