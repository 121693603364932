import { Divider, Skeleton, Stack, Typography } from "@mui/material";
import React, { Suspense, useEffect } from "react";
import {
  Await,
  Outlet,
  useActionData,
  useRouteLoaderData,
} from "react-router-dom";
import UserTable from "../../components/Table/UserTable";
import Toast from "../../utils/Toast";
import useApi from "../../hooks/use-api";

function UserList() {
  const { users } = useRouteLoaderData("team-management");
  const actionData = useActionData();

  useEffect(() => {
    if (actionData?.status) {
      Toast.fire({ icon: "success", title: actionData.message });
    }
  });

  return (
    <>
      <Divider />
      <Stack display={"flex"} my={4} flexDirection={"row"}>
        <Stack flex={0.5} mr={4}>
          <Typography sx={{ typography: "textSmSemi" }}>
            Invite team members
          </Typography>
          <Typography sx={{ typography: "textSmReg" }}>
            Get your projects up and running faster by inviting your team to
            collaborate.
          </Typography>
        </Stack>
        <Suspense fallback={<Skeleton variant="rounded" height={500} />}>
          <Await resolve={users}>
            {(loadedUsers) => (
              <Stack flex={1.5} spacing={2}>
                <UserTable users={loadedUsers.data} />
              </Stack>
            )}
          </Await>
        </Suspense>
      </Stack>
      <Outlet />
    </>
  );
}

export default UserList;

// ? *************************************************************** Delete the user *************************************************************** */
export const action = async ({ request }) => {
  try {
    const formData = await request.formData();
    const isDelete = await useApi.deleteRequest(
      `users/${formData.get("id")}`,
      true,
      "REACT_APP_USERS_API_KEY"
    );
    if (isDelete.status) {
      return isDelete;
    }

    throw new Error(isDelete.message);
  } catch (error) {
    Toast.fire({ icon: "error", title: error.message });
  }
};
