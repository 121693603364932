import { ListItem, Stack } from "@mui/material";
import React from "react";
import * as MuiIcon from "@mui/icons-material";
import classes from "../../assets/css/Navbar.module.css";

const SidebarItem = (props) => {
  const Icon = MuiIcon[props.item.icon];

  return (
    <ListItem
      sx={{
        textDecoration: "none",
        justifyContent: props.isOpen ? "initial" : "center",
        display: "flex",
        direction: "column",
        my: 2,
        color: "primary.white",
        paddingX: 1,
        paddingY: 1,
        cursor: "pointer",
        borderRadius: 1,
        ":hover": {
          bgcolor: "primary.primary600",
        },
      }}
      onClick={() => {
        props.handleActiveSidebar(props.item.id);
      }}
      className={props.item.isActive ? classes.active : classes.inactive}
      key={props.item.name}
    >
      <Stack
        display={"flex"}
        direction={"row"}
        alignItems={"center"}
        justifyContent={props.isOpen ? "initial" : "center"}
        columnGap={1}
      >
        {/* <Stack>{props.item.icon}</Stack> */}
        <Stack>
          <Icon />
        </Stack>
        {props.isOpen ? <Stack> {props.item.name} </Stack> : null}
      </Stack>
    </ListItem>
  );
};

export default SidebarItem;
