import React from "react";
import { Stack, Typography } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import "../../assets/css/style.css";
import * as MuiIcon from "@mui/icons-material";

const Dashboard = (props) => {
  const Icon = MuiIcon[props.icon];

  return (
    <Stack
      // maxWidth={480}
      display={"flex"}
      flexDirection={"row"}
      className="dashboardCard"
      alignItems={"center"}
      sx={{
        borderRadius: 2,
        border: "1px solid var(--gray-200, #EAECF0)",
        p: 2,
        cursor: "pointer",
        boxShadow:
          "0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10)",
      }}
      columnGap={3}
    >
      <Icon sx={{ height: 50, width: 50, color: "primary.primary600" }} />
      <Stack>
        <Typography
          sx={{ typography: "textMdSemi" }}
          display={"flex"}
          justifyContent={"space-between"}
        >
          {props.title}
        </Typography>
        <Typography
          sx={{ typography: "textSmReg" }}
          className="dashboardCard_Content"
          display={"flex"}
          justifyContent={"space-between"}
        >
          {props.helperText}
        </Typography>
      </Stack>
      <ArrowForwardIcon />
    </Stack>
  );
};

export default Dashboard;
