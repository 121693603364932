import React, { useEffect, useState } from "react";
import HeaderLayout from "../layouts/HeaderLayout";
import { Button, MenuItem, Stack, TextField } from "@mui/material";
import { useFormik } from "formik";
import { caseStudySchema } from "../../schema";
import { useActionData, useLoaderData, useSubmit } from "react-router-dom";
import TextEditor from "../TextEditor";
import Toast from "../../utils/Toast";

const INITIAL_VALUES = {
  title: "",
  subtitle: "",
  about: "",
  description: "",
  challenges: "",
  solutions: "",
  value_creation: "",
  position: "",
  sub_service_id: "",
};

const CaseStudyForm = ({ caseStudy = {}, isEdit = false }) => {
  const { caseStudies, subServices } = useLoaderData();
  const [initialValues, setInitialValues] = useState(INITIAL_VALUES);
  const submit = useSubmit();
  const actionData = useActionData();

  // ? *********************************************************** Form Submit handler *********************************************************** */
  const onFormSubmitHandler = (e) => {
    e.preventDefault();
    handleSubmit(e);
  };

  // ? *********************************************************** Use of Formik *********************************************************** */
  const {
    values,
    errors,
    touched,
    setFieldValue,
    setFieldTouched,
    handleBlur,
    handleChange,
    handleSubmit,
    resetForm,
  } = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: caseStudySchema,
    onSubmit: (values) => {
      submit(values, { method: "POST" });
    },
  });

  useEffect(() => {
    if (isEdit) {
      setInitialValues({
        title: caseStudy.title,
        subtitle: caseStudy.subtitle,
        about: caseStudy.about,
        description: caseStudy.description,
        challenges: caseStudy.challenges,
        solutions: caseStudy.solutions,
        value_creation: caseStudy.value_creation,
        position: caseStudy.position,
        sub_service_id: caseStudy.sub_service_id,
      });
    }

    if (actionData?.status) {
      resetForm();
      Toast.fire({ icon: "success", title: actionData.message });
    }

    if (actionData?.status === false) {
      Toast.fire({ icon: "error", title: actionData.message });
    }
  }, [actionData, resetForm, caseStudy, isEdit]);

  // ? *********************************************************** Render the component *********************************************************** */

  return (
    <>
      <HeaderLayout title="Case Study" type="Create a Case Study" />
      <Stack mx={2} display={"flex"}>
        <Stack my={2}>
          <form onSubmit={onFormSubmitHandler}>
            <Stack spacing={4}>
              {/* Title */}
              <TextField
                sx={{
                  width: "100%",
                  ".MuiFormHelperText-root": {
                    typography: "textSmReg",
                    color: "#d3302f",
                  },
                }}
                color="secondary"
                size="medium"
                name="title"
                variant="outlined"
                label="Title *"
                onBlur={handleBlur}
                error={errors.title && Boolean(errors.title) && touched.title}
                helperText={touched.title && errors.title}
                value={values.title}
                onChange={handleChange}
              />
              {/* Support Text */}
              <TextField
                sx={{
                  width: "100%",
                  ".MuiFormHelperText-root": {
                    typography: "textSmReg",
                    color: "#d3302f",
                  },
                }}
                color="secondary"
                size="medium"
                name="subtitle"
                variant="outlined"
                label="Sub Title"
                onBlur={handleBlur}
                error={
                  errors.subtitle &&
                  Boolean(errors.subtitle) &&
                  touched.subtitle
                }
                helperText={touched.subtitle && errors.subtitle}
                value={values.subtitle}
                onChange={handleChange}
              />

              <TextField
                sx={{
                  width: "100%",
                  ".MuiFormHelperText-root": {
                    typography: "textSmReg",
                    color: "#d3302f",
                  },
                }}
                color="secondary"
                size="medium"
                name="about"
                variant="outlined"
                label="About *"
                onBlur={handleBlur}
                error={errors.about && Boolean(errors.about) && touched.about}
                helperText={touched.about && errors.about}
                value={values.about}
                onChange={handleChange}
                multiline
                rows={3}
              />

              <TextEditor
                name="description"
                title="Description"
                setFieldValue={setFieldValue}
                errors={errors.description}
                touched={touched.description}
                setFieldTouched={setFieldTouched}
                isEdit={isEdit}
                savedHtml={isEdit && caseStudy.description}
              />

              <TextEditor
                name="challenges"
                title="Client Requirements"
                setFieldValue={setFieldValue}
                errors={errors.challenges}
                touched={touched.challenges}
                setFieldTouched={setFieldTouched}
                isEdit={isEdit}
                savedHtml={isEdit && caseStudy.challenges}
              />

              <TextEditor
                name="solutions"
                title="Our Solution"
                setFieldValue={setFieldValue}
                errors={errors.solutions}
                touched={touched.solutions}
                setFieldTouched={setFieldTouched}
                isEdit={isEdit}
                savedHtml={isEdit && caseStudy.solutions}
              />

              <TextEditor
                name="value_creation"
                title="Value Creation for Customer"
                setFieldValue={setFieldValue}
                errors={errors.value_creation}
                touched={touched.value_creation}
                setFieldTouched={setFieldTouched}
                isMandatory={false}
                isEdit={isEdit}
                savedHtml={isEdit && caseStudy.value_creation}
              />

              <TextField
                fullWidth
                select
                size="small"
                placeholder="Position of the Case Study"
                name="position"
                sx={{
                  width: "100%",
                  ".MuiFormHelperText-root": {
                    typography: "textSmReg",
                    color: "#d3302f",
                  },
                }}
                value={values.position}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.position && touched.position}
                helperText={touched.position && errors.position}
                label="Select Position after"
              >
                <MenuItem value="" disabled selected>
                  Select position
                </MenuItem>
                <MenuItem value="first">At the beginning</MenuItem>
                {caseStudies.map((caseStudy) => (
                  <MenuItem key={caseStudy.id} value={caseStudy.position}>
                    {caseStudy.title}
                  </MenuItem>
                ))}
              </TextField>

              <TextField
                fullWidth
                select
                size="small"
                placeholder="Select Service"
                name="position"
                sx={{
                  width: "100%",
                  ".MuiFormHelperText-root": {
                    typography: "textSmReg",
                    color: "#d3302f",
                  },
                }}
                value={values.sub_service_id}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.sub_service_id && touched.sub_service_id}
                helperText={touched.sub_service_id && errors.sub_service_id}
                label="Select Sub Service"
              >
                <MenuItem value="" disabled selected>
                  Select Service
                </MenuItem>
                {subServices.map((subService) => (
                  <MenuItem key={subService.id} value={subService.id}>
                    {subService.title}
                  </MenuItem>
                ))}
              </TextField>
              <Button variant="contained" color="primary" type="submit">
                Submit
              </Button>
            </Stack>
          </form>
        </Stack>
      </Stack>
    </>
  );
};

export default CaseStudyForm;
