import { Button, Stack, TextField, Typography } from "@mui/material";
import React from "react";
import UploadFile from "../File-upload";
import TextEditor from "./TextEditor";

const SpeakerCoordinatorForm = ({
  index,
  type,
  valuesLength,
  values,
  handleBlur,
  handleChange,
  touched,
  errors,
  setFieldTouched,
  editorState,
  setEditorState,
  onEditorStateChange,
  setFieldValue,
  onRemoveFieldsHandler,
  ...restProps
}) => {
  return (
    <Stack spacing={4}>
      {valuesLength > 1 && (
        <Button
          sx={{
            bgcolor: "primary.primary600",
            color: "white",
            ":hover": { color: "black" },
          }}
          onClick={() => onRemoveFieldsHandler(`${type.toLowerCase()}s`, index)}
        >
          REMOVE
        </Button>
      )}

      <UploadFile
        title={`${type} Image`}
        errorMessage={errors?.image}
        errorStatus={
          errors?.image !== undefined && touched?.image !== undefined
            ? true
            : false
        }
        name={`${type.toLowerCase()}s[${index}].image`}
        folderPath="images/events/speakers"
        handleBlur={handleBlur}
        setFieldValue={setFieldValue}
        setFieldTouched={setFieldTouched}
        values={{ [values.image]: values.image }}
      />
      <TextField
        sx={{
          width: "100%",
          ".MuiFormHelperText-root": {
            typography: "textSmReg",
            color: "#d3302f",
          },
        }}
        color="secondary"
        size="medium"
        name={`${type.toLowerCase()}s[${index}].name`}
        variant="outlined"
        label={`${type} Name *`}
        onBlur={handleBlur}
        error={errors?.name && Boolean(errors?.name) && touched?.name}
        helperText={touched?.name && errors?.name}
        value={values.name}
        onChange={handleChange}
      />
      <TextField
        sx={{
          width: "100%",
          ".MuiFormHelperText-root": {
            typography: "textSmReg",
            color: "#d3302f",
          },
        }}
        color="secondary"
        size="medium"
        name={`${type.toLowerCase()}s[${index}].designation`}
        variant="outlined"
        label={`${type} designation *`}
        onBlur={handleBlur}
        error={
          errors?.designation &&
          Boolean(errors?.designation) &&
          touched?.designation
        }
        helperText={touched?.designation && errors?.designation}
        value={values.designation}
        onChange={handleChange}
      />
      <TextField
        sx={{
          width: "100%",
          ".MuiFormHelperText-root": {
            typography: "textSmReg",
            color: "#d3302f",
          },
        }}
        color="secondary"
        size="medium"
        type="url"
        name={`${type.toLowerCase()}s[${index}].linkedin_profile`}
        variant="outlined"
        label={`${type} LinkedIn URL *`}
        onBlur={handleBlur}
        error={
          errors?.linkedin_profile &&
          Boolean(errors?.linkedin_profile) &&
          touched?.linkedin_profile
        }
        helperText={touched?.linkedin_profile && errors?.linkedin_profile}
        value={values.linkedin_profile}
        onChange={handleChange}
      />
      <Stack>
        <Typography
          sx={{ typography: "textMdReg", mb: 1, color: "grey.grey600" }}
        >
          About {type}
        </Typography>
        <TextEditor
          editorState={editorState}
          setEditorState={setEditorState}
          value={values.introduction}
          rawTextName={`${type.toLowerCase()}s[${index}].introduction`}
          htmlTextName={`html${type.toLowerCase()}Introduction`}
          handleBlur={handleBlur}
          handleChange={handleChange}
          setFieldValue={setFieldValue}
          error={errors?.introduction}
          touched={touched?.introduction}
          onEditorStateChange={onEditorStateChange}
        />
      </Stack>
    </Stack>
  );
};

export default SpeakerCoordinatorForm;
