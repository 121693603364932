import React, { useEffect } from "react";
import Button from "../../components/ui/FormButton";
import OtpInput from "react-otp-input";
import AuthStyle from "../../assets/css/Auth.module.css";
import Divider from "@mui/material/Divider";
import { Stack, Typography } from "@mui/material";
import {
  Form,
  useActionData,
  useLocation,
  useNavigate,
  useSubmit,
} from "react-router-dom";
import { useFormik } from "formik";
import { OtpSchema } from "../../schema/index";
import useApi from "../../hooks/use-api";
import Toast from "../../utils/Toast";

const initialValues = {
  otp: "",
  email: "",
  checkField: "",
};

const VerifyOtp = () => {
  const location = useLocation();
  const submit = useSubmit();
  const actionData = useActionData();
  const navigate = useNavigate();

  const onSubmitApplicationHandler = (event) => {
    event.preventDefault();
    handleSubmit();
  };

  const {
    values,
    errors,
    touched,
    setFieldValue,
    handleBlur,
    // handleChange,
    handleSubmit,
  } = useFormik({
    initialValues,
    validationSchema: OtpSchema,
    onSubmit: async (values) => {
      submit(values, { method: "POST" });
    },
  });

  useEffect(() => {
    if (actionData?.status) {
      navigate("/auth/change-password", { state: { email: values.email } });
    }
  }, [actionData, navigate, values]);

  return (
    <>
      <Stack className={AuthStyle.form_content}>
        <Stack sx={{ width: { xs: "100%", sm: "400px", md: "380px" } }}>
          <Stack my={2}>
            <Typography typography={"displayMdSemi"}>
              Change Password
            </Typography>
            <Stack py={2}>
              <Typography typography={"textMdSemi"}>Almost there!</Typography>
              <Typography typography={"textLgReg"}>
                Enter your account details to begin
              </Typography>
            </Stack>

            <Divider sx={{ mb: "20px" }} />
          </Stack>
          <Stack
            mb={"2.5rem"}
            display={"flex"}
            direction={"row"}
            justifyContent={"center"}
          >
            <Form method="POST" onSubmit={onSubmitApplicationHandler}>
              <OtpInput
                value={values.otp}
                onChange={(otp) => {
                  setFieldValue("otp", otp);
                  setFieldValue("email", location.state.email);
                }}
                inputStyle={`${AuthStyle.otpInput} ${
                  touched.otp && errors.otp
                    ? AuthStyle.otpInputError
                    : !errors.otp && values.otp.length > 0
                    ? AuthStyle.otpInputSuccess
                    : ""
                }`}
                numInputs={5}
                // inputType="InputNum"
                className={AuthStyle.form_content}
                inputType="number"
                renderSeparator={<span>&nbsp;&nbsp;&nbsp;&nbsp;</span>}
                renderInput={(props) => (
                  <input {...props} onBlur={handleBlur} name="otp" />
                )}
              />
              <Stack py={"0.5rem"}>
                {errors.otp && touched.otp ? (
                  <Typography sx={{ color: "red" }}>{errors.otp}</Typography>
                ) : null}
              </Stack>
              {/* <Typography>hello</Typography> */}
              <Button
                title="Email Verify"
                BtnVariant="contained"
                type="submit"
              />
            </Form>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};

export default VerifyOtp;

export const action = async ({ request }) => {
  try {
    const data = await request.formData();
    const formData = {
      email: data.get("email"),
      otp: data.get("otp"),
      checkField: data.get("checkField"),
    };
    const result = await useApi.postRequest(
      "ids",
      "auth/verify-otp",
      false,
      formData
    );
    if (result.status) {
      return result;
    }

    throw new Error(result.message);
  } catch (error) {
    return Toast.fire({ icon: "error", title: error.message });
  }
};
