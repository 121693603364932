import { Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import { LoadingButton } from "@mui/lab";
import { useActionData, useNavigation, useSubmit } from "react-router-dom";
import Toast from "../../utils/Toast";

const Status = ({ id }) => {
  const { state } = useNavigation();
  const actionData = useActionData();
  const submit = useSubmit();
  const [currentSubmit, setCurrentSubmit] = useState({
    status: "",
    id: "",
  });

  // ? ********************************************* Update the status ********************************************* */
  const onUpdateStatusHandler = async (e) => {
    e.preventDefault();
    submit(currentSubmit, { method: "POST" });
  };

  useEffect(() => {
    if (actionData?.status) {
      setTimeout(() => {
        setCurrentSubmit({ status: "", id: "" });
      }, 2000);
      Toast.fire({ icon: "success", title: actionData.message });
    }
  }, [actionData]);

  return (
    <Stack
      sx={{
        display: "flex",
        flexDirection: "row",
      }}
    >
      <form onSubmit={(e) => onUpdateStatusHandler(e)}>
        <LoadingButton
          sx={{ mr: 1 }}
          variant="contained"
          color="error"
          type="submit"
          onClick={() => setCurrentSubmit({ status: "rejected", id: id })}
          loading={
            state === "submitting" &&
            currentSubmit.status === "rejected" &&
            currentSubmit.id === id
          }
        >
          Reject
        </LoadingButton>
        <LoadingButton
          variant="contained"
          color="primary"
          type="submit"
          onClick={() => setCurrentSubmit({ status: "approved", id: id })}
          loading={
            state === "submitting" &&
            currentSubmit.status === "approved" &&
            currentSubmit.id === id
          }
          //   loading={isLoading.approved}
        >
          Approve
        </LoadingButton>
      </form>
    </Stack>
  );
};

export default Status;
