import React, { Suspense } from "react";
import useApi from "../../hooks/use-api";
import {
  Await,
  Link,
  defer,
  useLoaderData,
  useLocation,
} from "react-router-dom";
import Listing from "../../components/Listing";
import ListSkeleton from "../../components/ListSkeleton";
import csrRegistrationsCards from "../../store/csr-registrations/CsrRegistrationsCard";
import Dashboard from "../../components/cards/Dashboard";
import { Grid, IconButton, Stack, Typography } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";

const Lists = () => {
  const {
    lists,
    type,
    inquiries,
    subInquiries,
    contactInquiries,
    allDepartments,
    jobs,
  } = useLoaderData();
  const { search } = useLocation();
  const cardType = new URLSearchParams(search).get("cardType");

  return (
    <>
      <Link style={{ all: "unset", maxWidth: "max-content" }} to={-1}>
        <IconButton
          sx={{
            cursor: "pointer",
            my: 1,
            maxWidth: "fit-content",
            color: "primary.primary100",
            bgcolor: "primary.primary800",
            "&:hover": {
              scale: "1.1",
              transition: "0.5s ease-in-out",
              color: "primary.primary100",
              bgcolor: "primary.primary800",
            },
          }}
        >
          <ArrowBack />
        </IconButton>
      </Link>
      <Stack my={2}>
        <Typography sx={{ typography: "displaySmSemi" }}>{cardType}</Typography>
      </Stack>
      {type !== "csr-registrations" && (
        <Suspense fallback={<ListSkeleton />}>
          <Await
            resolve={Promise.all([
              lists,
              inquiries,
              subInquiries,
              contactInquiries,
              allDepartments,
              jobs,
            ]).then((values) => values)}
          >
            {(values) => {
              const [
                lists,
                inquiries,
                subInquiries,
                contactInquiries,
                allDepartments,
                jobs,
              ] = values;
              return (
                <Listing
                  lists={lists}
                  inquiries={inquiries}
                  subInquiries={subInquiries}
                  contactInquiries={contactInquiries}
                  allDepartments={allDepartments}
                  jobs={jobs}
                  type={type}
                />
              );
            }}
          </Await>
        </Suspense>
      )}
      {type === "csr-registrations" && (
        <Stack display={"flex"}>
          <Grid
            maxWidth={"xl"}
            container
            // columnSpacing={20}
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              columnGap: 5,
              rowGap: 5,
            }}
          >
            {csrRegistrationsCards.map((card) => (
              <Grid
                item
                md={csrRegistrationsCards.length <= 1 ? 12 : 5}
                key={card.id}
              >
                <Link
                  to={`/lists/${card.link}?cardType=${card.title}`}
                  style={{ textDecoration: "none", color: "black" }}
                  key={card.id}
                >
                  <Dashboard
                    title={card.title}
                    icon={card.icon}
                    helperText={card.helperText}
                  />
                </Link>
              </Grid>
            ))}
          </Grid>
        </Stack>
      )}
    </>
  );
};

export default Lists;

// ? ******************************************************** load the lists according to the type ******************************************************** */
const loadList = async (type) => {
  try {
    const result = await useApi.getRequest("ids", type, true);
    if (!result.status) {
      throw new Error(result.message);
    }

    return result.data;
  } catch (error) {
    return console.log(error.message);
  }
};

// ? ******************************************************** load the inquiries ******************************************************** */
const loadInquiries = async () => {
  try {
    const result = await useApi.getRequest("ids", "inquiries", true);
    if (result.status) {
      return result.data;
    }

    throw new Error(result.message);
  } catch (error) {
    console.log(error.message);
  }
};

// ? ******************************************************** load the sub inquiries ******************************************************** */
const loadSubInquiries = async () => {
  try {
    const result = await useApi.getRequest("ids", "sub-inquiries", true);
    if (result.status) {
      return result.data;
    }

    throw new Error(result.message);
  } catch (error) {
    console.log(error.message);
  }
};

// ? ******************************************************** load the contact inquiries ******************************************************** */
const loadContactInquiries = async () => {
  try {
    const result = await useApi.getRequest("ids", "contact-inquiries", true);
    if (result.status) {
      return result.data;
    }

    throw new Error(result.message);
  } catch (error) {
    console.log(error.message);
  }
};

// ? ******************************************************** load the departments ******************************************************** */
const loadDepartments = async () => {
  try {
    const result = await useApi.getRequest("ids", "departments", true);
    if (result.status) {
      return result.data;
    }

    throw new Error(result.message);
  } catch (error) {
    console.log(error.message);
  }
};

// ? ******************************************************** load the jobs ******************************************************** */
const loadJobs = async () => {
  try {
    const result = await useApi.getRequest("ids", "jobs", true);
    if (result.status) {
      return result.data;
    }

    throw new Error(result.message);
  } catch (error) {
    console.log(error.message);
  }
};

export const loader = ({ request, params }) => {
  const listType = params.type;
  return defer({
    lists: loadList(listType),
    inquiries: loadInquiries(),
    subInquiries: loadSubInquiries(),
    contactInquiries: loadContactInquiries(),
    allDepartments: loadDepartments(),
    jobs: loadJobs(),
    type: params.type.toLowerCase(),
  });
};
