import {
  Box,
  Divider,
  Grid,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { Recommend } from "@mui/icons-material";

const ReferralInfo = (props) => {
  const { data } = props;

  return (
    <>
      <Stack direction={"flex"} justifyContent={"center"} alignItems={"center"}>
        <Grid
          container
          md={12}
          p={2}
          px={10}
          gap={2}
          display={"flex"}
          alignItems={"center"}
          flexDirection={"row"}
        >
          {Object.keys(data)
            .filter((key, index) => data[key])
            ?.map((key, index) => {
              return (
                <>
                  {key !== "country_id" &&
                    key !== "job_id" &&
                    key !== "all_documents" &&
                    key !== "all_certificates" &&
                    key !== "all_awards" &&
                    key !== "stage_id" && (
                      <>
                        <Grid item md={3} key={index}>
                          <Typography
                            pr={2}
                            sx={{
                              color: "grey.grey900",
                              typography: "textMdReg",
                              display: "flex",
                              flexDirection: "row",
                              textTransform: "capitalize",
                            }}
                          >
                            {key.replace("_", " ")}{" "}
                          </Typography>
                        </Grid>
                        <Grid item md={6}>
                          {key !== "documents" &&
                            key !== "certificates" &&
                            key !== "awards" &&
                            key !== "all_certificates" &&
                            key !== "all_awards" &&
                            key !== "all_documents" && (
                              <TextField
                                multiline
                                fullWidth
                                InputProps={{ readOnly: true }}
                                size="small"
                                sx={{
                                  typography: "textMdReg",
                                  display: "flex",
                                  flexDirection: "row",
                                }}
                                value={
                                  key === "stage"
                                    ? data[key].name
                                    : key === "country"
                                    ? data[key].name
                                    : key === "job"
                                    ? data[key].title
                                    : key === "referred_by"
                                    ? data[key].first_name
                                    : key === "created_at"
                                    ? new Date(data[key]).toLocaleString()
                                    : key === "updated_at"
                                    ? new Date(data[key]).toLocaleString()
                                    : data[key]
                                }
                              ></TextField>
                            )}
                          {(key === "documents" || key === "certificates") &&
                            data[key].split(",").map((docs, i) => {
                              return (
                                <Box
                                  component={"a"}
                                  sx={{
                                    typography: "textMdReg",
                                    display: "block",
                                    gap: 3,
                                    px: 2,
                                    bgcolor: "grey.grey100",
                                    py: 1,
                                    borderRadius: 1,
                                    color: "grey.grey600",
                                    "&:hover": { color: "grey.grey800" },
                                    maxWidth: "max-content",
                                  }}
                                  href={`${process.env.REACT_APP_IDS_BACKEND_URL}applications/${key}/${docs}`}
                                  key={i}
                                  download={true}
                                  target="_blank"
                                >
                                  {docs}

                                  <IconButton
                                    sx={{
                                      ml: 1,
                                      "&:hover": {
                                        bgcolor: "grey.grey300",
                                      },
                                    }}
                                    download={`${process.env.REACT_APP_IDS_BACKEND_URL}applications/${key}/${docs}`}
                                  >
                                    {" "}
                                    <FileDownloadIcon />{" "}
                                  </IconButton>
                                </Box>
                              );
                            })}
                        </Grid>
                      </>
                    )}
                </>
              );
            })}
        </Grid>
      </Stack>
      {data.referred_by && (
        <Stack px={10}>
          <Divider sx={{ py: 1 }} />
          <Typography
            sx={{
              mt: 2,
              mb: 1,
              typography: "textXlSemi",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Recommend sx={{ mr: 1 }} />
            Referred by details
          </Typography>
          {Object.keys(data.referred_by)
            .filter((key) => data?.referred_by[key])
            ?.map((key, index) => {
              return (
                <>
                  {" "}
                  <Grid
                    container
                    md={12}
                    p={2}
                    gap={2}
                    display={"flex"}
                    alignItems={"center"}
                    flexDirection={"row"}
                  >
                    <Grid item md={3} key={index}>
                      <Typography
                        pr={2}
                        sx={{
                          color: "grey.grey900",
                          typography: "textMdReg",
                          display: "flex",
                          flexDirection: "row",
                          textTransform: "capitalize",
                        }}
                      >
                        {key.replace("_", " ")}{" "}
                      </Typography>
                    </Grid>
                    <Grid item md={6}>
                      <TextField
                        multiline
                        fullWidth
                        InputProps={{ readOnly: true }}
                        size="small"
                        sx={{
                          typography: "textMdReg",
                          display: "flex",
                          flexDirection: "row",
                        }}
                        value={
                          key === "created_at"
                            ? new Date(data?.referred_by[key]).toLocaleString()
                            : key === "updated_at"
                            ? new Date(data?.referred_by[key]).toLocaleString()
                            : data?.referred_by[key]
                        }
                      ></TextField>
                    </Grid>
                  </Grid>{" "}
                </>
              );
            })}
        </Stack>
      )}
    </>
  );
};

export default ReferralInfo;
