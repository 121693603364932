import React from "react";
import HeaderLayout from "../../../components/layouts/HeaderLayout";
import { Stack } from "@mui/material";
import CreateIndustryForm from "../../../components/ssb/industries/CreateIndustryForm";
import useApi from "../../../hooks/use-api";
import Toast from "../../../utils/Toast";

const CreateIndustry = () => {
  return (
    <>
      <HeaderLayout title="Industry" type="Create a Industry" />
      <Stack mx={2} display={"flex"}>
        <Stack my={2}>
          <CreateIndustryForm />
        </Stack>
      </Stack>
    </>
  );
};

export default CreateIndustry;

export const action = async ({ request }) => {
  try {
    const data = await request.formData();
    const formData = {
      title: data.get("title"),
      subtitle: data.get("subtitle"),
      overview: data.get("overview"),
      points: data.get("points"),
      icon: data.get("icon"),
      image: data.get("image"),
    };

    const result = useApi.postRequest("ssb", "industries", true, formData);
    return result;
  } catch (error) {
    Toast.fire({ icon: "error", title: error.message });
  }
};
