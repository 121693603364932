import React, { useEffect } from "react";
import AuthStyle from "../../assets/css/Auth.module.css";
import { Outlet, useNavigate } from "react-router-dom";
import { Stack, Typography } from "@mui/material";

const Layout = () => {
  const token = localStorage.getItem("token");
  const user = localStorage.getItem("user");
  const navigate = useNavigate();

  useEffect(() => {
    if (token !== null) {
      return navigate("/");
    }
  }, [token, navigate, user]);

  return (
    <div>
      <Stack mx={"auto"} maxWidth={"xl"}>
        <Stack>
          <Stack className={AuthStyle.login} sx={{ position: "relative" }}>
            <Stack
              width={"6%"}
              sx={{ position: "absolute", top: "2rem", left: "4rem" }}
            >
              <img
                alt=""
                src="https://cert.idsil.com/assets/images/logo.png"
                width="100%"
                height="100%"
              />
            </Stack>
            <Stack sx={{ position: "absolute", top: "14rem", left: "4rem" }}>
              <Stack sx={{ width: "500px" }}>
                <Typography
                  sx={{ color: "grey.grey100", typography: "textLgReg" }}
                >
                  This platform aims to elevate your recruitment process with
                  streamlined efficiency, giving you more time to focus on
                  finding the best talent for our team.
                </Typography>
                <Typography
                  py={"1.6rem"}
                  sx={{ color: "grey.grey100", typography: "textLgReg" }}
                >
                  Effortlessly manage applications – edit, view, and track them,
                  all at one place. The intuitive dashboard lets you seamlessly
                  navigate end-to-end interview management. But that's not all –
                  stay tuned for a multitude of upcoming features that are
                  poised to further enhance your HR recruitment experience.
                </Typography>
              </Stack>
            </Stack>
            <Stack className={AuthStyle.form}>
              <Stack
                display={"direction"}
                direction={"column"}
                alignItems={"center"}
                justifyContent={"center"}
                heigh={"100%"}
              >
                <Outlet />
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </div>
  );
};
export default Layout;
