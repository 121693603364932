import { ArrowBack } from "@mui/icons-material";
import { IconButton, Stack, Typography } from "@mui/material";
import React from "react";
import { Link, Outlet } from "react-router-dom";

const HeaderLayout = ({ title, type, description }) => {
  return (
    <>
      <Stack my={2}>
        <Link style={{ all: "unset", maxWidth: "max-content" }} to={-1}>
          <IconButton
            sx={{
              cursor: "pointer",
              my: 1,
              maxWidth: "fit-content",
              color: "primary.primary100",
              bgcolor: "primary.primary800",
              "&:hover": {
                scale: "1.1",
                transition: "0.5s ease-in-out",
                color: "primary.primary100",
                bgcolor: "primary.primary800",
              },
            }}
          >
            <ArrowBack />
          </IconButton>
        </Link>
        <Typography
          color={"primary.primary700"}
          sx={{ typography: "displayXsSemi" }}
        >
          {title}
        </Typography>
        <Typography sx={{ typography: "displaySmSemi" }}>{type}</Typography>
        <Typography color={"grey.grey600"} mb={2}>
          {description}
        </Typography>
      </Stack>
      <Outlet />
    </>
  );
};

export default HeaderLayout;

HeaderLayout.defaultProps = {
  title: "",
  type: "",
  description: "Manage your team members and their account permissions here.",
};
