import * as Yup from "yup";

export const loginSchema = Yup.object({
  email: Yup.string().email().required("Please enter your email "),
  password: Yup.string().min(8).required("Please enter your password"),
});

export const ForgetPass = Yup.object({
  email: Yup.string().email().required("Please enter your email "),
});

export const OtpSchema = Yup.object({
  otp: Yup.number().min(4).required("Please enter your OTP"),
});

export const ChangePass = Yup.object({
  password: Yup.string().min(8).required("Please enter your password"),
  confirm_password: Yup.string()
    .oneOf([Yup.ref("password"), null], 'Must match "password" field value')
    .required("please enter your confirm Password"),
});

export const CreateUserSchema = Yup.object({
  first_name: Yup.string().required("Enter first name"),
  email: Yup.string().email().required("Enter email address"),
  role_id: Yup.string().required("Please select role"),
  department_id: Yup.string().required("Please select department"),
  password: Yup.string().min(8).required("Please enter your password"),
});

export const EditUserSchema = Yup.object({
  first_name: Yup.string().required("Enter first name"),
  email: Yup.string().email().required("Enter email address"),
  role_id: Yup.string().required("Please select role"),
  department_id: Yup.string().required("Please select department"),
  password: Yup.string().min(8, "Password must be at least 8 characters"),
});

// ? ********************************************************** Post a job ********************************************************** */
export const jobCreateSchema = Yup.object().shape({
  job_id: Yup.string().required("Please select a job"),
  title: Yup.string().required("Please select a job title"),
  type: Yup.string().required("Please select a job type"),
  experience: Yup.mixed().required("Please select experience"),
  workMode: Yup.string().required("Please select a working mode"),
  jobRawTextSummary: Yup.string().required("Job summary is required"),
  keyRawTextResponsibility: Yup.string().required(
    "Please mention key responsibilities"
  ),
  rawTextRequirements: Yup.string().required("Please mention job requirements"),
  rawTextPreferredQualification: Yup.string().required(
    "Please mention preferred qualification"
  ),
  endNote: Yup.string(),
  isReviewed: Yup.bool().oneOf([true], "To post, it has to be admin reviewed"),
});

// ? ********************************************************** Create event ********************************************************** */
export const eventSchema = Yup.object().shape({
  event: Yup.object().shape({
    title: Yup.string().required("Please enter title!"),
    subtitle: Yup.string().required("Please enter subtitle!"),
    image: Yup.mixed().required("Please upload a banner image"),
    date: Yup.date().required("Date is required!"),
    start_time: Yup.string().required("Please select start time"),
    end_time: Yup.string().required("Please select end time"),
    location: Yup.string().required("Please select Event type!"),
    link: Yup.string().url("Link must be an url").required("Please enter link"),
    link2: Yup.string().when("location", {
      is: (location) => location === "Hybrid",
      then: () => Yup.string().required("Please enter the virtual link"),
      otherwise: () => Yup.string(),
    }),
    // link2: Yup.string().url().required("Please enter link"),
    overview: Yup.string().required(
      "Please enter the description of the event!"
    ),
    email_template: Yup.string().required("Please enter email body!"),
  }),
  speakers: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().required("Please enter speaker name!"),
      designation: Yup.string().required("Please enter speaker designation!"),
      image: Yup.mixed().required("Upload the speaker image first!"),
      linkedin_profile: Yup.string()
        .url("Linkedin must be an URL")
        .required("Please enter linkedin profile link!"),
      introduction: Yup.string().required("Please write introduction!"),
    })
  ),
  coordinators: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().required("Please enter coordinator name!"),
      designation: Yup.string().required(
        "Please enter coordinator designation"
      ),
      image: Yup.mixed().required("Upload the coordinator image first!"),
      linkedin_profile: Yup.string()
        .url("Linkedin must be an URL")
        .required("Please enter linkedin profile link"),
      introduction: Yup.string().required("Please write introduction"),
    })
  ),
});

// ? ********************************************************** Case study schema ********************************************************** */
export const caseStudySchema = Yup.object().shape({
  title: Yup.string().required("Please enter title!"),
  about: Yup.string().required("Please enter about!"),
  description: Yup.string().required("Please enter description!"),
  challenges: Yup.string().required("Please enter challenges!"),
  solutions: Yup.string().required("Please enter solutions!"),
  // position: Yup.string().required("Please enter position!"),
  // sub_service_id: Yup.string().required("Please enter title!"),
});

// ? ********************************************************** EHS service schema ********************************************************** */
export const ehsServiceSchema = Yup.object().shape({
  title: Yup.string().required("Please enter title!"),
  about: Yup.string().required("Please enter about!"),
  description: Yup.string().required("Please enter description!"),
  image: Yup.string().required("Please upload the image!"),
  mobile_image: Yup.string().required("Please upload the mobile image!"),
  case_study_caption: Yup.string().required(
    "Please enter the case study caption!"
  ),
  position: Yup.string().required("Please select the position!"),
  category_id: Yup.string().required("Please select the category!"),
  // position: Yup.string().required("Please enter position!"),
  // sub_service_id: Yup.string().required("Please enter title!"),
});

// ? ********************************************************** Industry schema ********************************************************** */
export const industrySchema = Yup.object().shape({
  title: Yup.string().required("Please enter title!"),
  subtitle: Yup.string().required("Please enter subtitle!"),
  overview: Yup.string().required("Please enter overview!"),
  icon: Yup.string().required("Please upload icon!"),
  image: Yup.string().required("Please upload image!"),
  // position: Yup.string().required("Please enter position!"),
  // sub_service_id: Yup.string().required("Please enter title!"),
});

// ? ********************************************************** SSB case study schema ********************************************************** */
export const ssbCaseStudySchema = Yup.object().shape({
  title: Yup.string().required("Please enter title!"),
  description: Yup.string().required("Please enter description!"),
  app_type: Yup.string().required("Please enter the App type!"),
  tech_stack: Yup.string().required("Please enter the Tech stack!"),
  business_problem: Yup.string().required("Please enter the business problem!"),
  solution: Yup.string().required("Please enter the solution!"),
  business_impact: Yup.string().required("Please enter the business impact!"),
  industry_id: Yup.string().required("Please select the industry!"),
  service_id: Yup.string().required("Please select the service!"),
  // position: Yup.string().required("Please enter position!"),
  // sub_service_id: Yup.string().required("Please enter title!"),
});

// ? ********************************************************** Certificate Template schema ********************************************************** */
export const certificateTemplateSchema = Yup.object().shape({
  name: Yup.string().required("Please enter certificate name!"),
  alias: Yup.string(),
  image: Yup.string().required("Please upload certificate!"),
});

// ? ********************************************************** Certificate schema ********************************************************** */
export const certificateSchema = Yup.object().shape({
  employee_name: Yup.string().required("Please enter employee name!"),
  employee_code: Yup.string().required("Please enter employee code!"),
  employee_email: Yup.string().required("Please enter employee email!"),
  certificate_template_id: Yup.string().required(
    "Please select certificate type!"
  ),
  year: Yup.string().required("Please enter the year!"),
  reason: Yup.string().required("Please enter the comments!"),
});
