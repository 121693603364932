import {
  Box,
  Divider,
  Grid,
  // IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import MessageIcon from "@mui/icons-material/Message";
// import FileDownloadIcon from "@mui/icons-material/FileDownload";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import DownloadButton from "../../layouts/DownloadButton";

function PersonalInfo(props) {
  const { data } = props;

  return (
    <>
      {data.message ? (
        <Stack px={10} py={2}>
          <Typography
            sx={{
              typography: "textLgSemi",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <MessageIcon sx={{ mr: 1 }} />
            Visitor Inquiry
          </Typography>
          <Typography
            sx={{
              typography: "textSmReg",
              display: "flex",
              flexDirection: "row",
              maxWidth: "md",
              px: 2,
            }}
          >
            “ {data.message} ”
          </Typography>
          <Divider sx={{ py: 3 }} />
        </Stack>
      ) : null}

      <Stack direction={"flex"} justifyContent={"center"} alignItems={"center"}>
        <Grid
          container
          md={12}
          p={2}
          px={10}
          gap={2}
          display={"flex"}
          alignItems={"center"}
          flexDirection={"row"}
        >
          {Object.keys(data)
            .filter((key, index) => data[key])
            ?.map((key, index) => {
              return (
                <>
                  {key !== "country_id" &&
                    key !== "job_id" &&
                    key !== "all_documents" &&
                    key !== "all_certificates" &&
                    key !== "all_awards" &&
                    key !== "stage_id" && (
                      <>
                        <Grid item md={3} key={index}>
                          <Typography
                            pr={2}
                            sx={{
                              color: "grey.grey900",
                              typography: "textMdReg",
                              display: "flex",
                              flexDirection: "row",
                              textTransform: "capitalize",
                            }}
                          >
                            {key.replace("_", " ")}{" "}
                          </Typography>
                        </Grid>
                        <Grid item md={6}>
                          {key !== "documents" &&
                            key !== "certificates" &&
                            key !== "awards" &&
                            key !== "all_certificates" &&
                            key !== "all_awards" &&
                            key !== "opening" &&
                            key !== "all_documents" && (
                              <TextField
                                multiline
                                fullWidth
                                InputProps={{ readOnly: true }}
                                size="small"
                                sx={{
                                  typography: "textMdReg",
                                  display: "flex",
                                  flexDirection: "row",
                                }}
                                value={
                                  key === "stage"
                                    ? data[key].name
                                    : key === "country"
                                    ? data[key].name
                                    : key === "job"
                                    ? data[key].title
                                    : key === "referred_by"
                                    ? data[key].first_name
                                    : key === "created_at"
                                    ? new Date(data[key]).toLocaleString()
                                    : key === "updated_at"
                                    ? new Date(data[key]).toLocaleString()
                                    : data[key]
                                }
                              ></TextField>
                            )}
                          {key === "opening" && (
                            <Box
                              component="a"
                              href={`https://careers.idsil.com/applications/${
                                data[key].id
                              }?opening=${data[key].title
                                .toLowerCase()
                                .replace(/\s/g, "-")}&job=${data["job"].title
                                .replace(/\s/g, "-")
                                .replace(/,+/g, "")
                                .replace(/-+/g, "-")}`}
                              target="__blank"
                              rel="noreferrer noopener"
                              sx={{
                                color: "#0086C9",
                                textDecoration: "underline",
                                display: "flex",
                                flexDirection: "row",
                                typography: "textSmSemi",
                                alignItems: "center",
                                px: 2,
                                bgcolor: "grey.grey100",
                                py: 1,
                                borderRadius: 1,
                                // color: "grey.grey600",
                                "&:hover": { color: "grey.grey800" },
                                maxWidth: "max-content",
                              }}
                            >
                              {data[key].title}{" "}
                              <ArrowOutwardIcon sx={{ width: 12, ml: 0.3 }} />
                            </Box>
                          )}
                          {(key === "documents" || key === "certificates") &&
                            data[key].split(",").map((docs, i) => {
                              return (
                                <DownloadButton
                                  key={i}
                                  fileUrl={`${process.env.REACT_APP_IDS_BACKEND_URL}applications/${key}/${docs}`}
                                  fileName={docs}
                                />
                              );
                            })}
                        </Grid>
                      </>
                    )}
                </>
              );
            })}
        </Grid>
      </Stack>
    </>
  );
}

export default PersonalInfo;
