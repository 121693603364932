import { Button, Divider, Stack, Typography } from "@mui/material";
import React from "react";

function Filters(props) {
  return (
    <>
      {props.isSubFilter && (
        <>
          <Typography sx={{ mt: 2, mx: 1, typography: "textMdSemi" }}>
            And
            <Divider sx={{ my: 0.5 }} />
          </Typography>
        </>
      )}
      <Stack display={"flex"} flexWrap={"wrap"} flexDirection={"row"}>
        {props.filterLists.map((filterList, index) => (
          <Button
            key={index}
            onClick={() =>
              props.setActiveFilterHandler(filterList.id, filterList.name)
            }
            sx={{
              mx: 1,
              my: 1,
              typography: "textSmSemi",
              color: filterList.isActive
                ? "primary.primary700"
                : "grey.grey700",
              bgcolor: filterList.isActive
                ? "primary.primary50"
                : "grey.grey100",
              border: "1px solid",
              borderColor: filterList.isActive
                ? "primary.primary700"
                : "transparent",
              "&:hover": {
                bgcolor: "primary.primary50",
                color: "primary.primary700",
              },
            }}
          >
            {filterList.name}
          </Button>
        ))}
      </Stack>
    </>
  );
}

export default Filters;
