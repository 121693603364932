import { Skeleton, Stack } from "@mui/material";
import React from "react";

function ListSkeleton() {
  return (
    <>
      {/* <Skeleton variant="circular" width={50} height={50} /> */}
      <Stack my={2}>
        <Skeleton variant="text" sx={{ fontSize: "3rem", width: 200 }} />
        <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
        <Skeleton variant="text" sx={{ fontSize: "2rem" }} />
      </Stack>
      <Skeleton sx={{ mt: 10 }} variant="rounded" height={450} />
    </>
  );
}

export default ListSkeleton;
