import { createTheme } from "@mui/material";
const colors = {
  grey25: "#FCFCFD",
  grey50: "#F9FAFB",
  grey77: "#BFC0C1",
  grey100: "#F2F4F7",
  grey200: "#EAECF0",
  grey300: "#D0D5DD",
  grey400: "#98A2B3",
  grey500: "#667085",
  grey600: "#475467",
  grey700: "#344054",
  grey800: "#1D2939",
  grey900: "#101828",
  blue25: "#F5FBFF",
  blue50: "#F0F9FF",
  blue100: "#E0F2FE",
  blue200: "#B9E6FE",
  blue300: "#7CD4FD",
  blue400: "#36BFFA",
  blue500: "#0BA5EC",
  blue600: "#0086C9",
  blue700: "#026AA2",
  blue800: "#065986",
  blue900: "#0B4A6F",
  sky50: "#f0f9ff",
  red25: "##FFFBFA",
  red50: "#FEF3F2",
  red100: "#FEE4E2",
  red200: "#FECDCA",
  red300: "#FDA29B",
  red400: "#F97066",
  red500: "##F04438",
  red600: "#D92D20",
  red700: "#B42318",
  red800: "#912018",
  red900: "#7A271A",
  transparent: "#ffffff",
};
const lightTheme = createTheme({
  palette: {
    // ...(mode === "light" ? "" : ""),
    background: {
      default: "#fff",
      paper: "#fff",
      defaultChannel: "255 255 255",
    },
    primary: {
      main: colors.blue600,
      primary100: colors.blue100,
      primary200: colors.blue200,
      primary300: colors.blue300,
      primary600: colors.blue600,
      primary700: colors.blue700,
      primary800: colors.blue800,
      primary900: colors.blue900,
      primary50: colors.sky50,
      white: "#ffffff",
      black: "#000000",
      primary70: colors.sky50,
      transparent: colors.transparent,
      //common-black and white,
    },
    secondary: {
      main: colors.blue600,
      secondary100: colors.blue100,
      secondary200: colors.blue200,
      secondary300: colors.blue300,
      secondary600: colors.blue600,
    },
    grey: {
      main: colors.grey600,
      grey25: colors.grey25,
      grey50: colors.grey50,
      grey77: colors.grey77,
      grey100: colors.grey100,
      grey200: colors.grey200,
      grey300: colors.grey300,
      grey400: colors.grey400,
      grey500: colors.grey500,
      grey600: colors.grey600,
      grey700: colors.grey700,
      grey800: colors.grey800,
      grey900: colors.grey900,
    },
    error: {
      main: colors.red600,
      error25: colors.red25,
      error50: colors.red50,
      error100: colors.red100,
      error200: colors.red200,
      error300: colors.red300,
      error400: colors.red400,
      error500: colors.red500,
      error600: colors.red600,
      error700: colors.red700,
      error800: colors.red800,
      error900: colors.red900,
    },
  },
  shape: {
    borderRadius: 8,
  },
  typography: {
    button: {
      textTransform: "none",
    },
    displayXlBold: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "3.8rem",
      lineHeight: "3.75rem",
      letterSpacing: "-0.02em",
      // color: colors.grey900,
    },
    displayXlSemi: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "3.8rem",
      lineHeight: "3.75rem",
      letterSpacing: "-0.02em",
      // color: colors.grey900,
    },
    displaySmSemi: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "30px",
      lineHeight: "38px",
      // letterSpacing: "-0.02em",
      // color: colors.grey900,
    },
    displayLgSemi: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "48px",
      lineHeight: "60px",
      letterSpacing: "-0.02em",
      // color: colors.grey900,
    },
    displayXsMedItalics: {
      fontFamily: "Inter",
      fontStyle: "italic",
      fontWeight: 500,
      fontSize: "24px",
      lineHeight: "36px",
      // letterSpacing: "-0.02em",
      // color: colors.grey900,
    },
    displayMdSemi: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "36px",
      lineHeight: "44px",
      letterSpacing: "-0.02em",
      // color: colors.grey900,
    },
    displayMdMed: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "20px",
      lineHeight: "30px",
      letterSpacing: "-0.02em",
      //color: colors.grey900,
    },
    displayXsSemi: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "20px",
      lineHeight: "32px",
      letterSpacing: "-0.02em",
      //color: colors.grey900,
    },
    displayXsBold: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "28px",
      lineHeight: "38px",
      letterSpacing: "-0.02em",
      //color: colors.grey900,
    },
    textSmBold: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 900,
      fontSize: "14px",
      lineHeight: "20px",
    },
    textSmSemi: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "14px",
      lineHeight: "20px",
    },
    textXlSemi: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "20px",
      lineHeight: "30px",
    },
    textLgSemi: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "18px",
      lineHeight: "28px",
    },
    textMdSemi: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "16px",
      lineHeight: "24px",
    },
    textSmMedium: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "14px",
      lineHeight: "20px",
    },
    textLgReg: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "20px",
      lineHeight: "28px",
      // color: colors.grey600,
    },
    textMdReg: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "20px",
      lineHeight: "28px",
      // color: colors.grey600,
    },
    textXlReg: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "1.25rem",
      lineHeight: "1.875rem",
      // color: colors.grey600,
    },
    textXsMed: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "12px",
      lineHeight: "18px",
      // color: colors.grey600,
    },
    textSmReg: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "14px",
      lineHeight: "20px",
      // color: colors.grey600,
    },
    textXsReg: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "12px",
      lineHeight: "18px",
      // color: colors.grey600,
    },
  },
});
const darkTheme = createTheme({
  palette: {
    // ...(mode === "light" ? "" : ""),
    background: {
      default: "#121212",
      paper: "#121212",
      defaultChannel: "18 18 18",
    },
    primary: {
      main: "#121212",
      blue200: colors.blue200,
      blue600: colors.blue600,
      blue700: colors.blue700,
      blue800: colors.blue800,
      grey100: colors.grey100,
      grey300: colors.grey300,
      grey600: colors.grey600,
      grey77: colors.grey77,
      grey700: colors.grey700,
      grey800: colors.grey800,
      grey900: colors.grey900,
      white: "#ffffff",
      black: "#000000",
      //common-black and white,
    },
  },
  shape: {
    borderRadius: "8px",
  },
  typography: {
    button: {
      textTransform: "none",
    },
    displayLgSemi: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "48px",
      lineHeight: "60px",
      letterSpacing: "-0.02em",
      // color: colors.grey900,
    },
    displayMdSemi: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "36px",
      lineHeight: "44px",
      letterSpacing: "-0.02em",
      // color: colors.grey900,
    },
    displayXsSemi: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "24px",
      lineHeight: "32px",
      letterSpacing: "-0.02em",
      //   color: colors.grey900,
    },
    displayMdMed: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "56px",
      lineHeight: "44px",
      letterSpacing: "-0.02em",
      //   color: colors.grey900,
    },
    textSmSemi: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "14px",
      lineHeight: "20px",
    },
    textXlSemi: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "20px",
      lineHeight: "30px",
    },
    textMdSemi: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "16px",
      lineHeight: "24px",
    },
    textLgReg: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "18px",
      lineHeight: "28px",
      // color: colors.grey600,
    },
    textXlReg: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "20px",
      lineHeight: "30px",
      // color: colors.grey600,
    },
    textSmReg: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 450,
      fontSize: "14px",
      lineHeight: "20px",
      // color: colors.grey600,
    },
  },
});
export { lightTheme, darkTheme };
