import { Button, Stack, TextField } from "@mui/material";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useActionData, useLoaderData, useSubmit } from "react-router-dom";
import { ssbCaseStudySchema } from "../../../schema";
import TextEditor from "../../TextEditor";
import MultiSelect from "../../MultiSelect";
import Toast from "../../../utils/Toast";

const INITIAL_VALUES = {
  title: "",
  subtitle: "",
  description: "",
  app_type: "",
  tech_stack: "",
  business_problem: "",
  solution: "",
  business_impact: "",
  link: "",
  industry_id: "",
  service_id: "",
  sub_service_id: "",
};

const CreateCaseStudyForm = () => {
  const { industries, services, subServices } = useLoaderData();
  const submit = useSubmit();
  const [selectedValues, setSelectedValues] = useState({
    industry_id: [],
    service_id: [],
    sub_service_id: [],
  });
  const actionData = useActionData();

  const onChangeSelectedValues = async (e, name) => {
    const {
      target: { value },
    } = e;

    const newSelectedValues = {
      ...selectedValues,
      [name]: typeof value === "string" ? value.split(",") : value,
    };

    values[name] = newSelectedValues[name].join(",");
    setSelectedValues(newSelectedValues);
  };

  // ? *********************************************************** Form Submit handler *********************************************************** */
  const onFormSubmitHandler = (e) => {
    e.preventDefault();
    handleSubmit(e);
  };

  // ? *********************************************************** Use of Formik *********************************************************** */
  const {
    values,
    errors,
    touched,
    setFieldValue,
    setFieldTouched,
    handleBlur,
    handleChange,
    handleSubmit,
    resetForm,
  } = useFormik({
    enableReinitialize: true,
    initialValues: INITIAL_VALUES,
    validationSchema: ssbCaseStudySchema,
    onSubmit: (values) => {
      submit(values, { method: "POST" });
    },
  });

  useEffect(() => {
    if (actionData?.status) {
      resetForm();
      Toast.fire({ icon: "success", title: actionData.message });
    }
  }, [actionData, resetForm]);

  return (
    <form onSubmit={onFormSubmitHandler}>
      <Stack spacing={4}>
        <TextField
          sx={{
            width: "100%",
            ".MuiFormHelperText-root": {
              typography: "textSmReg",
              color: "#d3302f",
            },
          }}
          color="secondary"
          size="medium"
          name="title"
          variant="outlined"
          label="Title *"
          onBlur={handleBlur}
          error={errors.title && Boolean(errors.title) && touched.title}
          helperText={touched.title && errors.title}
          value={values.title}
          onChange={handleChange}
        />
        {/* Support Text */}
        <TextField
          sx={{
            width: "100%",
            ".MuiFormHelperText-root": {
              typography: "textSmReg",
              color: "#d3302f",
            },
          }}
          color="secondary"
          size="medium"
          name="subtitle"
          variant="outlined"
          label="Sub Title"
          onBlur={handleBlur}
          error={
            errors.subtitle && Boolean(errors.subtitle) && touched.subtitle
          }
          helperText={touched.subtitle && errors.subtitle}
          value={values.subtitle}
          onChange={handleChange}
        />

        <TextField
          sx={{
            width: "100%",
            ".MuiFormHelperText-root": {
              typography: "textSmReg",
              color: "#d3302f",
            },
          }}
          color="secondary"
          size="medium"
          name="description"
          variant="outlined"
          label="Description *"
          onBlur={handleBlur}
          error={
            errors.description &&
            Boolean(errors.description) &&
            touched.description
          }
          helperText={touched.description && errors.description}
          value={values.description}
          onChange={handleChange}
        />

        <TextField
          sx={{
            width: "100%",
            ".MuiFormHelperText-root": {
              typography: "textSmReg",
              color: "#d3302f",
            },
          }}
          color="secondary"
          size="medium"
          name="app_type"
          variant="outlined"
          label="App Type *"
          onBlur={handleBlur}
          error={
            errors.app_type && Boolean(errors.app_type) && touched.app_type
          }
          helperText={touched.app_type && errors.app_type}
          value={values.app_type}
          onChange={handleChange}
        />

        <TextField
          sx={{
            width: "100%",
            ".MuiFormHelperText-root": {
              typography: "textSmReg",
              color: "#d3302f",
            },
          }}
          color="secondary"
          size="medium"
          name="tech_stack"
          variant="outlined"
          label="Tech Stack *"
          onBlur={handleBlur}
          error={
            errors.tech_stack &&
            Boolean(errors.tech_stack) &&
            touched.tech_stack
          }
          helperText={touched.tech_stack && errors.tech_stack}
          value={values.tech_stack}
          onChange={handleChange}
        />

        <TextEditor
          name="business_problem"
          title="Business Problem"
          setFieldValue={setFieldValue}
          errors={errors.business_problem}
          touched={touched.business_problem}
          setFieldTouched={setFieldTouched}
          // isEdit={isEdit}
          // savedHtml={isEdit && industry.overview}
        />
        <TextEditor
          name="solution"
          title="Solution"
          setFieldValue={setFieldValue}
          errors={errors.solution}
          touched={touched.solution}
          setFieldTouched={setFieldTouched}
          // isEdit={isEdit}
          // savedHtml={isEdit && industry.overview}
        />

        <TextEditor
          name="business_impact"
          title="Business Impact"
          setFieldValue={setFieldValue}
          errors={errors.business_impact}
          touched={touched.business_impact}
          setFieldTouched={setFieldTouched}
          // isEdit={isEdit}
          // savedHtml={isEdit && industry.overview}
        />

        <MultiSelect
          label="Industry"
          name="industry_id"
          values={industries}
          selectedValues={selectedValues.industry_id}
          onChange={onChangeSelectedValues}
        />

        <MultiSelect
          label="Service"
          name="service_id"
          values={services}
          selectedValues={selectedValues.service_id}
          onChange={onChangeSelectedValues}
        />

        <MultiSelect
          label="Sub Service"
          name="sub_service_id"
          values={subServices}
          selectedValues={selectedValues.sub_service_id}
          onChange={onChangeSelectedValues}
        />

        <Button variant="contained" color="primary" type="submit">
          Submit
        </Button>
      </Stack>
    </form>
  );
};

export default CreateCaseStudyForm;
