import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { Form, Link, useMatches } from "react-router-dom";
import { CssBaseline, Divider, Stack, Typography } from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
import logo from "../../assets/images/logo.webp";
import logoMark from "../../assets/svg/Content.svg";
import SidebarItem from "./SidebarItem";
import SidebarContext from "../../store/sidebar-context";

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  // height: "auto",
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
  border: "none",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  // height: "auto",
  overflowX: "hidden",
  border: "none",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  minHeight: "auto",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function MiniDrawer(props) {
  const context = React.useContext(SidebarContext);
  const user = JSON.parse(localStorage.getItem("user"));
  const matches = useMatches();

  React.useEffect(() => {
    context.sidebarMenus(matches);
  }, []);

  return (
    <Box sx={{ display: "flex" }} bgcolor={"primary.primary900"}>
      <CssBaseline />
      <Drawer
        variant="permanent"
        sx={{
          bgcolor: "primary.primary900",
        }}
        open={context.isOpen}
      >
        <DrawerHeader
          sx={{
            bgcolor: "primary.primary900",
            pt: 3,
            pb: 2,
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "row",
          }}
        >
          {context.isOpen && (
            <>
              <Box component={"img"} mx={2} src={logo} width={100} />
              <IconButton
                sx={{
                  color: "primary.primary100",
                  ":hover": { bgcolor: "primary.primary600", color: "white" },
                }}
                onClick={context.handleDrawer}
              >
                <ChevronLeftIcon color="primary.primary100" />
              </IconButton>{" "}
            </>
          )}
          {!context.isOpen && (
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={context.handleDrawer}
              sx={{
                ":hover": { bgcolor: "primary.primary600", color: "white" },
                //   marginRight: 5,
                ...(context.isOpen && { display: "none" }),
                color: "primary.primary100",
              }}
            >
              <img src={logoMark} alt="logo" />
            </IconButton>
          )}
        </DrawerHeader>
        <List
          sx={{
            gap: 4,
            bgcolor: "primary.primary900",
            height: "100vh",
            px: context.setIsOpen ? 2 : 1,
          }}
          setOpen={context.handleDrawer}
        >
          {context.sidebarItems.map((item) => (
            <Link
              key={item.id}
              to={item.link}
              style={{ textDecoration: "none" }}
            >
              <SidebarItem
                item={item}
                isOpen={context.isOpen}
                handleActiveSidebar={context.handleActiveSidebar}
              />
            </Link>
          ))}
        </List>
        <Stack
          sx={{
            textDecoration: "none",
            justifyContent: context.isOpen ? "initial" : "center",
            // mr: open ? 3 : "auto",
            display: "flex",
            bgcolor: "primary.primary900",
          }}
        >
          <Divider
            sx={{ backgroundColor: "primary.primary600", my: 2 }}
            variant="middle"
          />
          <Form method="POST" action="/logout">
            <Stack
              display={"flex"}
              mb={2}
              mx={2}
              justifyContent={"space-between"}
              flexDirection={"row"}
            >
              {context.isOpen ? (
                <Stack>
                  <Stack
                    display={"flex"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    flexDirection={"row"}
                  >
                    <Typography
                      color={"primary.primary200"}
                      sx={{ typography: "textSmReg" }}
                    >
                      {user?.full_name}
                    </Typography>
                    <IconButton
                      type="submit"
                      sx={{
                        color: "primary.primary100",
                        ":hover": {
                          bgcolor: "error.error800",
                          color: "white",
                        },
                      }}
                    >
                      <Stack>
                        <LogoutIcon />{" "}
                      </Stack>
                    </IconButton>{" "}
                  </Stack>

                  <Typography color={"white"} sx={{ typography: "textLgBold" }}>
                    {user?.email}
                  </Typography>
                </Stack>
              ) : (
                <IconButton
                  type="submit"
                  sx={{
                    color: "primary.primary100",
                    ":hover": {
                      bgcolor: "error.error800",
                      color: "white",
                    },
                  }}
                >
                  <Stack>
                    <LogoutIcon />{" "}
                  </Stack>
                </IconButton>
              )}
            </Stack>
          </Form>
        </Stack>
      </Drawer>
      <Box
        component="main"
        bgcolor={"primary.primary900"}
        sx={{
          flexGrow: 1,
          pt: 2,
        }}
      >
        <Stack
          sx={{
            borderRadius: "0px 0px 0px -40px",
            position: "sticky",
            top: 0,
            left: 0,
            height: "100vh",
            overflow: "hidden",
            borderTop: 20,
            borderColor: "primary.primary900",
            zIndex: 10,
          }}
          bgcolor={"primary.primary900"}
        >
          {""}
          <Stack
            sx={{
              borderRadius: "40px 0px 0px 0px",
              minHeight: "99vh",
              position: "static",
              p: 4,
              overflow: "scroll",
            }}
            bgcolor={"white"}
          >
            {React.cloneElement(props.children, { sidebarMenuId: 1 })}
          </Stack>
        </Stack>
      </Box>
    </Box>
  );
}
