import React, { Suspense } from "react";
import { Grid, Skeleton, Stack, Typography } from "@mui/material";
import { Await, Link, defer, useLoaderData } from "react-router-dom";
import Dashboard from "../../components/cards/Dashboard";
import useApi from "../../hooks/use-api";

function Home() {
  const user = JSON.parse(localStorage.getItem("user"));
  const { cards } = useLoaderData();

  return (
    <>
      <Stack my={2}>
        <Typography sx={{ typography: "displaySmSemi" }}>
          Welcome, {user?.first_name}
        </Typography>
        <Typography color={"grey.grey600"}>
          Seamlessly track and manage all the data in one place.
        </Typography>
      </Stack>
      <Stack
        display={"flex"}
        // justifyContent={cards.length <= 1 ? "initial" : "center"}
        // alignItems={cards.length <= 1 ? "initial" : "center"}
        // my={cards.length <= 1 ? 2 : 10}
        my={10}
        bgcolor={"white"}
      >
        <Stack
          display={"flex"}
          justifyContent={"center"}
          spacing={5}
          alignItems={"center"}
        ></Stack>
        <Stack display={"flex"}>
          <Grid
            maxWidth={"xl"}
            container
            // columnSpacing={20}
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              columnGap: 5,
              rowGap: 5,
            }}
          >
            <Suspense fallback={<Skeleton variant="rounded" height={500} />}>
              <Await resolve={cards}>
                {(loadedCards) =>
                  loadedCards.map((card) => {
                    return (
                      <Grid item md={cards.length <= 1 ? 12 : 5} key={card.id}>
                        <Link
                          to={`/lists/${card.link}?cardType=${card.name}`}
                          style={{ textDecoration: "none", color: "black" }}
                          key={card.id}
                        >
                          <Dashboard
                            title={card.name}
                            icon={card.icon}
                            helperText={card.description}
                          />
                        </Link>
                      </Grid>
                    );
                  })
                }
              </Await>
            </Suspense>
          </Grid>
        </Stack>
      </Stack>
    </>
  );
}

export default Home;

// ? ************************************************************ Load the cards which have the permissions to the logged in user  ************************************************************ */
const loadCardPermissions = async () => {
  try {
    const result = await useApi.getRequest("ids", "permissions/cards/1", true);
    if (result.status) {
      return result.data;
    }

    throw new Error(result.message);
  } catch (error) {
    return console.log(error.message);
  }
};

// ? ************************************************************ Loader  ************************************************************ */
export const loader = () => {
  return defer({
    cards: loadCardPermissions(),
  });
};
