import { Stack, Typography } from "@mui/material";
import React from "react";

const Welcome = () => {
  const user = JSON.parse(localStorage.getItem("user"));

  return (
    <Stack my={2}>
      <Typography sx={{ typography: "displaySmSemi" }}>
        Welcome, {user?.first_name}
      </Typography>
      <Typography color={"grey.grey600"}>
        Seamlessly track and manage all the data in one place.
      </Typography>
    </Stack>
  );
};

export default Welcome;
