import { Divider, Stack } from "@mui/material";
import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
} from "@mui/x-data-grid";
import React, { Suspense } from "react";
import { Await, useLoaderData } from "react-router-dom";
import HeaderLayout from "../../components/layouts/HeaderLayout";
import Columns from "../../store/Columns";

function CustomToolbar() {
  const exportOptions = {
    sx: {
      color: "white",
      fontWeight: "bold",
      py: 0.7,
      px: 1,
      my: 0.5,
      bgcolor: "#0086C9",
      borderRadius: 4,
      borderColor: "#E0E0E0",
      border: "1px solid",
      ":hover": { color: "#0086C9", bgcolor: "#E0F2FE" },
    },
  };
  const otherOptions = {
    sx: {
      color: "#475467",
      fontWeight: "bold",
      py: 0.7,
      px: 1,
      my: 0.5,
      mx: 0.5,
      bgcolor: "#F9FAFB",
      borderRadius: 1,

      ":hover": { color: "#344054", bgcolor: "#EAECF0" },
    },
  };

  return (
    <GridToolbarContainer
      sx={{
        borderBottom: 1,
        borderColor: "#E0E0E0",
        textAlign: "end",
        display: "flex",
        mx: 1,

        justifyContent: "space-between",
        color: "black",
      }}
    >
      <Stack display={"flex"} flexDirection={"row"}>
        <GridToolbarColumnsButton {...otherOptions} />
        <GridToolbarDensitySelector {...otherOptions} />
      </Stack>
      <GridToolbarExport {...exportOptions} />
    </GridToolbarContainer>
  );
}

function HealthCareExitIntent() {
  const { guides } = useLoaderData();
  return (
    <>
      <HeaderLayout title="Exit intent" type="Healthcare Exit intent" />
      <Divider sx={{ mb: 4 }} />

      <Suspense fallback={<p>Loading...</p>}>
        <Await resolve={guides}>
          {(loadedGuides) => (
            <Stack my={5} mx={2}>
              <div style={{ width: "100%", height: 400, overflowX: "auto" }}>
                <DataGrid
                  autoHeight
                  slots={{
                    toolbar: CustomToolbar,
                  }}
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: 5,
                      },
                    },
                  }}
                  pageSizeOptions={[5]}
                  checkboxSelection
                  disableRowSelectionOnClick
                  rows={loadedGuides}
                  columns={Columns["healthcareGuides"]}
                  className="css-u1bn0g-MuiDataGrid-root MuiDataGrid-cell MuiDataGrid-menuIcon"
                  sx={{
                    cursor: "pointer",
                    "& .MuiDataGrid-columnHeaders": {
                      backgroundColor: "#F2F4F7",
                      color: "black",
                    },
                  }}
                />
              </div>
            </Stack>
          )}
        </Await>
      </Suspense>
    </>
  );
}

export default HealthCareExitIntent;
