import React from "react";
import MiniDrawer from "../ui/MiniDrawer";
import { Outlet } from "react-router-dom";

const Layout = () => {
  return (
    <>
      <MiniDrawer>
        <main>
          <Outlet />
        </main>
      </MiniDrawer>
    </>
  );
};

export default Layout;
