import { Button, Divider, MenuItem, Stack, TextField } from "@mui/material";
import HeaderLayout from "../../components/layouts/HeaderLayout";
import UploadFile from "../../components/File-upload";
import { useFormik } from "formik";
import { certificateSchema } from "../../schema";
import { useActionData, useLoaderData, useSubmit } from "react-router-dom";
import Toast from "../../utils/Toast";
import useApi from "../../hooks/use-api";
import { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import Columns from "../../store/Columns";

const INITIAL_VALUES = {
  file: "",
  employee_name: "",
  employee_code: "",
  employee_email: "",
  certificate_template_id: "",
  year: "",
  reason: "",
};

const CreateCertificate = () => {
  const [excelEmployees, setExcelEmployees] = useState([]);
  const { certificateTemplates } = useLoaderData();
  const submit = useSubmit();
  const actionData = useActionData();

  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldTouched,
    setFieldValue,
    errors,
    touched,
    resetForm,
  } = useFormik({
    enableReinitialize: true,
    initialValues: INITIAL_VALUES,
    validationSchema: certificateSchema,
    onSubmit: (values) => {
      submit({ values: JSON.stringify([values]) }, { method: "POST" });
    },
  });

  const onSubmitExcelSheetDataHandler = (e) => {
    e.preventDefault();
    const employeeValues = excelEmployees.map(({ id, ...rest }) => rest);
    submit({ values: JSON.stringify(employeeValues) }, { method: "POST" });
  };

  useEffect(() => {
    if (actionData?.status) {
      resetForm();
      Toast.fire({ icon: "success", title: actionData?.message });
    }
  }, [actionData, resetForm]);

  return (
    <>
      <HeaderLayout title="Certificates" type="Create Certificate" />
      <Divider />
      <Stack
        maxWidth={"sm"}
        my={5}
        mx={"auto"}
        sx={{ display: "flex", justifyContent: "center" }}
      >
        <h1>Add Bulk Entry</h1>
        <UploadFile
          title="Employees Data"
          errorMessage={errors.file}
          accept=".xlsx, .xls"
          errorStatus={
            errors.file !== undefined && touched.file !== undefined
              ? true
              : false
          }
          name={"file"}
          folderPath="certificates/employees"
          setFieldValue={setFieldValue}
          handleBlur={handleBlur}
          setFieldTouched={setFieldTouched}
          values={{ file: values.file }}
          isMultiple={true}
          isExcelUploads={true}
          setExcelEmployees={setExcelEmployees}
          uploadingFileTypes="Excel (.xlsx only)"
        />

        <Divider sx={{ mt: 4 }} />

        {values.file.length === 0 && (
          <>
            <h1>Add Individual Entry</h1>

            <form onSubmit={handleSubmit}>
              <Stack spacing={4} mb={4}>
                <TextField
                  sx={{
                    width: "100%",
                    ".MuiFormHelperText-root": {
                      typography: "textSmReg",
                      color: "#d3302f",
                    },
                  }}
                  color="secondary"
                  size="medium"
                  name="employee_name"
                  variant="outlined"
                  label="Employee Name *"
                  onBlur={handleBlur}
                  error={
                    errors.employee_name &&
                    Boolean(errors.employee_name) &&
                    touched.employee_name
                  }
                  helperText={touched.employee_name && errors.employee_name}
                  value={values.employee_name}
                  onChange={handleChange}
                />
                <TextField
                  sx={{
                    width: "100%",
                    ".MuiFormHelperText-root": {
                      typography: "textSmReg",
                      color: "#d3302f",
                    },
                  }}
                  color="secondary"
                  size="medium"
                  name="employee_code"
                  variant="outlined"
                  label="Employee Code *"
                  onBlur={handleBlur}
                  error={
                    errors.employee_code &&
                    Boolean(errors.employee_code) &&
                    touched.employee_code
                  }
                  helperText={touched.employee_code && errors.employee_code}
                  value={values.employee_code}
                  onChange={handleChange}
                />
                <TextField
                  sx={{
                    width: "100%",
                    ".MuiFormHelperText-root": {
                      typography: "textSmReg",
                      color: "#d3302f",
                    },
                  }}
                  color="secondary"
                  size="medium"
                  name="employee_email"
                  variant="outlined"
                  label="Employee Email *"
                  onBlur={handleBlur}
                  error={
                    errors.employee_email &&
                    Boolean(errors.employee_email) &&
                    touched.employee_email
                  }
                  helperText={touched.employee_email && errors.employee_email}
                  value={values.employee_email}
                  onChange={handleChange}
                />
                <TextField
                  select
                  sx={{
                    width: "100%",
                    ".MuiFormHelperText-root": {
                      typography: "textSmReg",
                      color: "#d3302f",
                    },
                  }}
                  color="secondary"
                  size="medium"
                  name="certificate_template_id"
                  variant="outlined"
                  label="Certificate Type *"
                  onBlur={handleBlur}
                  error={
                    errors.certificate_template_id &&
                    Boolean(errors.certificate_template_id) &&
                    touched.certificate_template_id
                  }
                  helperText={
                    touched.certificate_template_id &&
                    errors.certificate_template_id
                  }
                  value={values.certificate_template_id}
                  onChange={handleChange}
                >
                  <MenuItem value="" disabled>
                    Select type
                  </MenuItem>
                  {certificateTemplates.map((certificateTemplate, index) => (
                    <MenuItem key={index} value={certificateTemplate.id}>
                      {certificateTemplate.name}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  sx={{
                    width: "100%",
                    ".MuiFormHelperText-root": {
                      typography: "textSmReg",
                      color: "#d3302f",
                    },
                  }}
                  color="secondary"
                  size="medium"
                  name="year"
                  variant="outlined"
                  label="Year (2024) *"
                  onBlur={handleBlur}
                  error={errors.year && Boolean(errors.year) && touched.year}
                  helperText={touched.year && errors.year}
                  value={values.year}
                  onChange={handleChange}
                />
                <TextField
                  sx={{
                    width: "100%",
                    ".MuiFormHelperText-root": {
                      typography: "textSmReg",
                      color: "#d3302f",
                    },
                  }}
                  color="secondary"
                  size="medium"
                  name="reason"
                  variant="outlined"
                  label="Comments *"
                  onBlur={handleBlur}
                  error={
                    errors.reason && Boolean(errors.reason) && touched.reason
                  }
                  helperText={touched.reason && errors.reason}
                  value={values.reason}
                  onChange={handleChange}
                />
                <Button variant="contained" color="primary" type="submit">
                  Submit
                </Button>
              </Stack>
            </form>
          </>
        )}
      </Stack>
      {values.file.length > 0 && excelEmployees.length > 0 && (
        <Stack my={5} mx={2}>
          <div style={{ width: "100%", overflowX: "auto" }}>
            <DataGrid
              autoHeight
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 5,
                  },
                },
              }}
              pageSizeOptions={[5]}
              checkboxSelection
              disableRowSelectionOnClick
              rows={excelEmployees}
              columns={Columns["certificateToEmployees"]}
              className="css-u1bn0g-MuiDataGrid-root MuiDataGrid-cell MuiDataGrid-menuIcon"
              sx={{
                cursor: "pointer",
                "& .MuiDataGrid-columnHeaders": {
                  backgroundColor: "#F2F4F7",
                  color: "black",
                },
              }}
            />
          </div>
          <Stack my={2} display={"flex"} alignItems={"end"}>
            <Button
              variant="contained"
              color="primary"
              sx={{ maxWidth: "max-content", fontWeight: "bold" }}
              onClick={onSubmitExcelSheetDataHandler}
            >
              Submit
            </Button>
          </Stack>
        </Stack>
      )}
    </>
  );
};

export default CreateCertificate;

export const action = async ({ request }) => {
  try {
    const data = await request.formData();
    const formData = JSON.parse(data.get("values"));
    const result = await useApi.postRequest(
      "ids",
      "certificates",
      true,
      formData
    );

    if (result.status) {
      return result;
    }

    throw new Error(result.message);
  } catch (error) {
    return Toast.fire({ icon: "error", title: error.message });
  }
};
