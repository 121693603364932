import React, { Suspense } from "react";
import HeaderLayout from "../../components/layouts/HeaderLayout";
import { Divider, Stack } from "@mui/material";
import { Await, useLoaderData } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import Columns from "../../store/Columns";
import useApi from "../../hooks/use-api";
import Toast from "../../utils/Toast";

const CertificateList = () => {
  const { certificates } = useLoaderData();
  const userType = JSON.parse(localStorage.getItem("user"));

  const columnVisibilityModel = React.useMemo(() => {
    if (userType?.role === "Admin") {
      return {
        action: true,
      };
    }
    return {
      action: false,
    };
  }, [userType]);

  return (
    <>
      <HeaderLayout title="Certificates" type="Certificates List" />
      <Divider sx={{ mb: 4 }} />

      <Suspense fallback={<p>Loading...</p>}>
        <Await resolve={certificates}>
          {(loadedCertificates) => (
            <Stack my={6} mx={3}>
              <div style={{ width: "100%", height: 500, overflowX: "auto" }}>
                <DataGrid
                  autoHeight
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: 20,
                      },
                    },
                  }}
                  pageSizeOptions={[5]}
                  checkboxSelection
                  disableRowSelectionOnClick
                  rows={loadedCertificates}
                  columns={Columns["certificates"]}
                  className="css-u1bn0g-MuiDataGrid-root MuiDataGrid-cell MuiDataGrid-menuIcon"
                  sx={{
                    cursor: "pointer",
                    "& .MuiDataGrid-columnHeaders": {
                      backgroundColor: "#F2F4F7",
                      color: "black",
                    },
                  }}
                  columnVisibilityModel={columnVisibilityModel}
                />
              </div>
            </Stack>
          )}
        </Await>
      </Suspense>
    </>
  );
};

export default CertificateList;

export const action = async ({ request }) => {
  try {
    const data = await request.formData();
    const formData = Object.fromEntries(data);
    const result = await useApi.updateRequest(
      "ids",
      `certificates/send/${formData.id}`,
      true,
      formData
    );

    if (result.status) {
      return result;
    }

    throw new Error(result.message);
  } catch (error) {
    return Toast.fire({ icon: "error", title: error.message });
  }
};
