import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Avatar, Button, Stack, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { ArrowOutward } from "@mui/icons-material";
import ConfirmationDialog from "../../pages/team-management/AlertBox";

const avtColors = [
  "#DC6803",
  "#12B76A",
  "#9F1AB1",
  "#F04438",
  "#026AA2",
  "#5925DC",
  "#107569",
  "#A15C07",
  "#B93815",
];
export default function BasicTable(props) {
  const [confirmOpen, setConfirmOpen] = React.useState(false);

  const handleDelete = () => {
    setConfirmOpen(true);
  };

  const handleConfirm = async (id) => {
    setConfirmOpen(false);
  };

  const handleCancel = () => {
    setConfirmOpen(false);
  };
  return (
    <TableContainer
      sx={{ border: 1, borderColor: "grey.grey300", borderRadius: 2 }}
    >
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow sx={{ bgcolor: "grey.grey50" }}>
            <TableCell>Name</TableCell>
            <TableCell align="left">Role</TableCell>
            <TableCell align="left">Created At</TableCell>
            <TableCell align="left">Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.users.length === 0 && (
            <TableRow
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell
                component="th"
                scope="data"
                colSpan={3}
                sx={{ textAlign: "center" }}
              >
                No user found
              </TableCell>
            </TableRow>
          )}
          {props.users.length > 0 &&
            props.users.map((user) => (
              <TableRow
                key={user.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="data">
                  <Stack display={"flex"} flexDirection={"row"}>
                    <Avatar
                      sx={{
                        mr: 1,
                        bgcolor:
                          avtColors[
                            Math.floor(Math.random() * avtColors.length)
                          ],
                      }}
                    >
                      {user.full_name.charAt(0)}
                    </Avatar>
                    <Stack>
                      <Typography sx={{ typography: "textSmSemi" }}>
                        {user.full_name}
                      </Typography>
                      <Typography
                        sx={{ typography: "textSmReg", color: "grey.grey800" }}
                      >
                        {user.email}
                      </Typography>
                    </Stack>
                  </Stack>
                </TableCell>
                <TableCell align="left" sx={{ textTransform: "capitalize" }}>
                  <Typography
                    sx={{ typography: "textSmSemi", color: "grey.grey600" }}
                  >
                    {user.role?.type}
                  </Typography>
                </TableCell>
                <TableCell align="left" sx={{ textTransform: "capitalize" }}>
                  <Typography
                    sx={{ typography: "textSmSemi", color: "grey.grey600" }}
                  >
                    {user.created_at}
                  </Typography>
                </TableCell>
                <TableCell align="left">
                  <Stack display={"flex"} flexDirection={"row"}>
                    <Button
                      onClick={handleDelete}
                      color={"grey"}
                      sx={{
                        fontWeight: "bold",
                        ":hover": { bgcolor: "grey.grey200" },
                      }}
                    >
                      Delete
                    </Button>
                    <ConfirmationDialog
                      open={confirmOpen}
                      onClose={handleCancel}
                      onConfirm={() => handleConfirm(user.id)}
                      message="Are you sure you want to delete this item?"
                      userId={user.id}
                    />
                    <Link to={`${user.id}/edit`} style={{ all: "unset" }}>
                      <Button
                        sx={{
                          fontWeight: "bold",
                          ":hover": {
                            color: "primary.primary700",
                            bgcolor: "grey.grey200",
                          },
                        }}
                      >
                        Edit <ArrowOutward sx={{ width: 12, ml: 0.3 }} />
                      </Button>
                    </Link>
                  </Stack>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
