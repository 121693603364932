const events = [
  {
    title: "Create Event",
    link: "create",
    icon: "PersonAdd",
    helperText: "Some Helper text here. Lorem Ipsem is best. The best I say.",
  },

  {
    title: "View Events",
    link: "list",
    icon: "Group",
    helperText: "Some Helper text here. Lorem Ipsem is best. The best I say.",
  },
];

export default events;
